<template>
  <div class="invoice"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

<v-card
    flat
    class="py-12"
  >
    <v-card-text >
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
          v-model="toggle_exclusive"
          mandatory
        >
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <router-link to="/invoice-add">
                <v-btn                  
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </router-link>
              </template>
              <span>Agregar Nueva Factura</span>
            </v-tooltip>
        </template>                  

        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <router-link to="/customer-add">
                <v-btn                  
                  color="darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>group_add</v-icon>
                </v-btn>
              </router-link>
              </template>
              <span>Agregar Nuevo Cliente</span>
            </v-tooltip>
        </template>  
        
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
        </template>
          
          
        </v-btn-toggle>
      </v-row>
  </v-card-text>
</v-card>


   <div>
    <table class="table table-striped">
     <thead class="thead-dark">
      <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
     </thead>
     <tr v-for="(row, index ) of invoices" :key="row.id">          
      <td>
         <!-- 
         <button v-on:click="deleteFriend(row.id, i)">x</button>
         -->
        <v-btn icon v-on:click="editRow = row.id">
          <v-icon>edit</v-icon>
        </v-btn>
        <a v-bind:href="url(row.id)">
          <v-icon>visibility</v-icon>
        </a>       
        <v-btn v-on:click="sendMail(row.id)" icon>
             <v-icon>mail</v-icon>
        </v-btn>         
                                             
        
      </td>      
      <td>
        <div v-if="editRow === row.id">
          <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.branch_id" /> -->
          <v-autocomplete
            v-model="row.customer_id"
            :hint="`${customer_id.id} - ${customer_id.razon_social}`"
            :items="customers"
            item-text="razon_social"
            item-value="id"
            label="Selecciona un Cliente"  
            outlined
          ></v-autocomplete>
          <v-text-field
                      v-model="row.title"
                      v-on:keyup.13="updateRow(row)"
                      label="Título"
          />
          <v-switch
            v-model="row.iva"
            label="IVA"
          ></v-switch>           
          <v-text-field
                      v-model="row.discount"
                      v-on:keyup.13="updateRow(row)"
                      label="Descuento %"
          />                    
          <!-- <ejs-combobox 
            v-on:keyup.13="updateRow(row)" 
            v-model="row.status" 
            :dataSource='dataIStatus' 
            :fields='dataFStatus' 
            placeholder='Seleccione un Status' 
            popupWidth="250px">
          </ejs-combobox> -->
          <v-text-field
                      v-model="row.date_pay"
                      v-on:keyup.13="updateRow(row)"
                      label="Fecha de Cotización"
          />          
          <v-text-field
                      v-model="row.notes"
                      v-on:keyup.13="updateRow(row)"
                      label="Notas"
          />                
          <v-combobox
            v-model="row.status" 
            :items="itemsStatus"
            label="Selecciona un Status"
          ></v-combobox>    
          <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
        </div>
        <div v-else>        
          <h6>{{row.title}}</h6>
          Index: {{index+1}} <br>
          <b>Folio:</b> {{row.id}} <br>
          {{row.razon_social}} <br>
          <small>{{row.branchname}}</small> <br>
          <b>Factura: </b>{{row.date_pay}} <br>
          <b>Registro: </b>{{row.created_at}}<br>
          <b>Actualización: </b>{{row.updated_at}}
        </div>
      </td>            
      <td>{{row.subtotal_invoices | currency }} </td>
      <td>{{row.discount_invoices }}% <br><small style="color:red;">{{row.discount_moneda | currency }}</small></td>
      <td>{{row.subtotal_invoices_discount | currency }} </td>
      <td>{{row.iva_invoices | currency }} </td>
      <td><span style="font-weight:bold; font-size:1.3rem">{{row.total_invoices | currency }}</span> </td>
      <td>
        <div v-if="row.notes != null && row.notes != ''">   
        <div class="text-center">
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red lighten-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  ver nota
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text>
                  {{row.notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          </div>
        </td>        
      <td>
        <div v-if="row.status == 'Pagado'">        
           <v-chip
             class="ma-2"
             color="green"
             label
             text-color="white"
           >
             {{row.status}}
           </v-chip>
          </div>
          <div v-else-if="row.status == 'Cancelada'">        
           <v-chip
             class="ma-2"
             color="red"
             label
             text-color="white"
           >
               {{row.status}}
           </v-chip>
          </div>
          <div v-else-if="row.status == 'Contratado'">        
           <v-chip
             class="ma-2"
             color="yellow"
             label
             text-color="black"
           >
               {{row.status}}
           </v-chip>
          </div>                 
          <div v-else>
           <v-chip
           class="ma-2"
           color="default"
           label
           text-color="black"
           >
             {{row.status}}
           </v-chip>
        </div>                                                                       
      </td>
     </tr>
   </table>          
   </div>               
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->
  </div>
</template>


<script>
import{ mapMutations, mapState } from "vuex";

//import FieldSearch from '@/components/FieldSearch.vue'
import baseApi from '@/services/baseApi';

export default {
  name: 'invoice',  
  computed:{
      ...mapState(['invoices','customers','currentUser'])    
  },
  components: {    
      //FieldSearch,
  },  
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]), 
    sendMail(id){            
      try{                
        this.showLoading({titulo:"Accediendo información",color:"warning"})    
        let branch_id=this.currentUser.branch_id;
        if(confirm("Desea enviar el correo de la cotización: "+ id+"?")){            
          baseApi().get(`https://www.cloudbits.org.mx/fw/lumen/check/email/detallefactura/${id}?branch_id=`+branch_id).then((response)=>{
              if(!response.data.error){                            
                if(response.data.error==false)                            
                  this.$swal("Genial!",response.data.message, "success");
                else
                  this.$swal("Error!",response.data.message, "error");  
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("Factura: "+response.data.message);
          });         
        }
      }catch(error){
        console.log(error);        
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    },
    url: function (href){
        return 'detallefactura?invoiceid='+ href
    }, 
    async getBreadcrumps(sword){      
      try{                
        await this.$store.dispatch('loadInvoices',sword);
        this.showLoading({titulo:"Accediendo información",color:"primary"})
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 

      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadCustomers',this.attribute);
      }catch(error){
        //console.log(error);
      } finally{
        this.hiddenLoading()
      } 
    },    
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})          
          let datos = baseApi().post(`/facturas/update/${row.id}`,JSON.stringify(row));                                                                                                    
          console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
  },
  data(){
     return{
      //dialog:true, //bug se cicla
      stories: [
        {
          title: 'Strategy 101',
          description: 'Create a draft of business plan',
          time: '3 days',
          done: false
        },
        {
          title: 'Strategy 102',
          description: 'Finalize the plan',
          time: '4 days',
          done: false
        },
        {
          title: 'Tech diagram',
          description: 'Draw the tech data',
          time: '4 days',
          done: false
        },
        {
          title: 'Place Holder',
          description: 'Data Science Team',
          time: '5 days',
          done: false
        }
      ],
      dropGroups: [
        {
          name: 'Business Team',
          children: []
        },
        {
          name: 'Tech Dept',
          children: []
        },
        {
          name: 'Marketing Dept',
          children: []
        }
      ],
      attribute: {sword: '',limit: 500},
      msg:0,
      sword:"",
      // customers:[],
      customer_id:77,
      editRow:null,
      itemsStatus: [
          'Proceso',
          'Cancelada',
          'Entregada'
      ],            
      theader: [
        { text: 'Acción', value: 'canasta_basica' },        
        {
          text: 'Descripción',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Subtotal', value: 'subtotal_quote' },
        { text: 'Descuento', value: 'subtotal_quote' },
        { text: 'Subtotal c/Desc', value: 'subtotal_quote' },
        { text: 'IVA', value: 'iva_quote' },
        { text: 'Total', value: 'total_quote' },
        { text: 'Notas', value: 'notes' },
        { text: 'Status', value: 'status' },        
      ],
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Factura",
        title:"Lista de Facturas",
        subtitle:"primary",
        description:"En esta lista encontrará todos las facturas dadas de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  },
}
</script>