<template>
<div id="CustomerForm">  
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
  <!-- @submit.prevent="$emit('submit-form', form)" -->
            <v-row>  
              <v-col cols="12" xs="12" sm="6" md="6" > 
                <v-combobox
                  v-if="hasCustomer"
                  v-model="customerInfo.tipo"
                  :items="itemsTipo"
                  label="Selecciona un Tipo*"
                  :rules="[
                      required('Tipo')
                    ]"
                ></v-combobox>            
              </v-col>          
              <v-col cols="12" xs="12" sm="6" md="6" > 
                <v-text-field
                    v-model="customerInfo.razon_social"
                    v-if="hasCustomer || hasSupplier"
                    label="Razon Social*"
                    :rules="[
                      required('razon social')
                    ]" 
                ></v-text-field>     
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                    v-model="customerInfo.title"
                    v-if="hasCustomer"
                    label="Titulo"      
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                v-if="hasCustomer || hasEmployee"
                    v-model="customerInfo.firstname"
                    label="Nombre"   
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                v-if="hasCustomer || hasEmployee"
                    v-model="customerInfo.lastname"
                    label="Apellido"      
                  ></v-text-field>
              </v-col>        
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                v-if="hasCustomer || hasSupplier || hasEmployee"
                    v-model="customerInfo.address"
                    label="Domicilio"   
                    :rules="[
                      () => !!customerInfo.address || 'Se necesita el domicilio',
                      () => !!customerInfo.address && customerInfo.address.length <= 175 || 'Domicilio debe ser mayor a 1 y menor 175 caracteres'              
                    ]"   
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                v-if="hasCustomer || hasSupplier || hasEmployee"
                    v-model="customerInfo.num_ext"
                    label="Num. Ext"
                    :rules="[
                      required('num. ext')
                    ]"    
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                    v-if="hasCustomer || hasSupplier || hasEmployee" 
                    v-model="customerInfo.colonia"
                    label="Colonia"      
                  ></v-text-field>
              </v-col>
              </v-row>
              <v-row>
              <v-col cols="12" sm="2" md="4">
                <v-text-field
                v-if="hasCustomer || hasSupplier || hasEmployee"
                    v-model="customerInfo.zip"
                    label="C.P."  
                    :rules="[
                      onlyNumeric('codigo postal'),
                      NoComas('codigo postal')
                    ]"     
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                v-if="hasCustomer || hasSupplier || hasEmployee"
                    v-model="customerInfo.rfc"
                    label="RFC"      
                  ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                v-if="hasCustomer || hasSupplier || hasEmployee"
                    v-model="customerInfo.email"
                    label="Email"     
                    :rules="[
                      // required('email'),
                      //invalidEmail('email')
                    ]" 
                  ></v-text-field>
              </v-col>   
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                v-if="hasCustomer || hasSupplier || hasEmployee"
                    v-model="customerInfo.phone"
                    label="Telefono"      
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                v-if="hasCustomer"
                    v-model="customerInfo.office"
                    label="Oficina"      
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                v-if="hasCustomer || hasSupplier || hasEmployee"
                    v-model="customerInfo.mobile"
                    label="Celular"      
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="6"  sm="6" md="6">
                  <v-combobox
                    v-model="customerInfo.status"
                    v-if="hasCustomer"
                    :items="itemsStatus"
                    label="Selecciona un Status"
                    :rules="[
                      required('Status')
                    ]"
                  ></v-combobox>               
                </v-col>           
                <v-col cols="6"  sm="6" md="6">
                  <v-combobox
                  v-if="hasCustomer"
                    v-model="customerInfo.fuente"
                    :items="itemsFuente"
                    label="Selecciona una Fuente"
                    :rules="[
                      required('Fuente')
                    ]"
                  ></v-combobox>                                             
                </v-col>
              </v-row> 
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-combobox
                  v-if="hasEmployee"
                    v-model="customerInfo.tipo"
                    :items="itemsType"
                    label="Selecciona un Tipo"
                  ></v-combobox>
                  <!-- <ejs-combobox v-model="tipo" :dataSource='dataItem' :fields='dataFields' placeholder='Seleccione un tipo' popupWidth="250px"> </ejs-combobox>  -->
                </v-col>      
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-combobox
                  v-if="hasEmployee"
                    v-model="customerInfo.puesto"
                    :items="itemsPuesto"
                    label="Selecciona un Puesto"
                  ></v-combobox>
                  <!-- <ejs-combobox v-model="puesto" :dataSource='dataPuesto' :fields='dataFPuesto' placeholder='Seleccione un puesto' popupWidth="250px"> </ejs-combobox>  -->
                </v-col>
              </v-row>
              <v-row>
                <v-textarea
                      v-model="customerInfo.notes"
                      v-if="hasCustomer || hasSupplier || hasEmployee"
                      filled
                      auto-grow
                      label="Escribe comentarios"
                      rows="4"
                      row-height="30"
                      shaped
                  ></v-textarea>   
              </v-row>

    <v-row>
      <v-col cols="12" >
        <v-btn
            v-if="hasReset"
            color="error"
            class="mr-4"
            @click="reset"
        >
          <v-icon>cleaning_services</v-icon> Borrar Formulario
        </v-btn> 
        <v-btn
            color="primary"
            class="mr-4"
            @click="submitForm(customerInfo)" 
            :disabled="!valid"
        >
          <v-icon>save</v-icon> {{buttonText}}
        </v-btn>  
        <!--
          Se debe realizar un prop para que pase el tru de dialog2
        <v-btn
              class="headline"
              color="blue darken-1"
              text
              @click="dialog2 = false"
        >
              <v-icon>close</v-icon>
        </v-btn>     
      --> 
      </v-col>
    </v-row>
  </v-form>
</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import validations from '@/utils/validation';
// import{ DataManager,Query, WebApiAdaptor } from "@syncfusion/ej2-data";

export default {
  name: 'CustomerForm',  
  components: {
  },  
  computed:{
    // 'products',
    ...mapState(['currencies'])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    reset () {
        this.$refs.form.reset()
    }
  },
  props: {
    user: Object,
    submitForm:String,
    buttonText:String,
    hasCustomer:String,
    hasSupplier:String,
    hasEmployee:String,
    hasReset:String
  },
  data(){
     return{
        //valid: false,
        ...validations,
        attribute: {sword: 'All',limit: 27},
        customerInfo:{   
          id:this.user ? this.user.id : "",
          tipo:this.user ? this.user.tipo : "",
          puesto:this.user ? this.user.puesto : "",
          razon_social:this.user ? this.user.razon_social : "",
          title:this.user ? this.user.title : "Ing.",
          firstname:this.user ? this.user.firstname : "",
          lastname:this.user ? this.user.lastname : "",
          address: this.user ? this.user.address : "",
          num_ext: this.user ? this.user.num_ext : 0,
          colonia: this.user ? this.user.colonia : "",
          zip: this.user ? this.user.zip : "",
          rfc: this.user ? this.user.rfc : "",
          email: this.user ? this.user.email : "",
          phone: this.user ? this.user.phone : "",
          office: this.user ? this.user.office : "",
          mobile: this.user ? this.user.mobile : "",
          status: this.user ? this.user.status : 'Lead',
          fuente: this.user ? this.user.fuente : "Website",
          //date_buy: new Date().toISOString().substr(0,10),   
          notes: this.user ? this.user.notes : ""
        },
        itemsType: [
          'Bolsa de Trabajo',
          'Contratado',
       ],          
       itemsPuesto: [
            'TI',
            'Marketing Digital',
            'Electricista',
            'Vendedor',
            'Contador',
            'Mecatrónica',
            'Recursos Humanos',
       ],      
      itemsStatus: [
          'Customer',
          'Distribuidor',
          'Lead',
      ],
      itemsTipo: [
          'Moral',
          'Fisica',
      ],
      itemsFuente: [
          'Email',
          'Facebook',
          'Google',
          'Instagram',
          'Mercado Libre',
          'Website',
      ]      
        // dataItem2:new DataManager({
        //     url: "https://www.cloudbits.org.mx/fw/lumen/check/productos/getall",
        //     adaptor: new WebApiAdaptor,
        //     crossDomain:false,
        //     //dataType: 'json',
        //     //method: 'GET',
        //     headers: [
        //       {'Access-Control-Allow-Origin': '*'},
        //       {'Access-Control-Allow-Headers': '*'},
        //       {"Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr'},
        //     ]
        //   }).executeQuery(new Query()).then((e) => {
        //     console.log(e.data);
        //   }),                  
        //   dataFields2:{value:'id',text:'sku'},
        //   //dataFields2:{value:'EmployeeID',text:'FirstName'},
        // };  
     }
  },
  created(){
  }  
}
</script>