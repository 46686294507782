<template>
<div id="MessageForm">  
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  > 
    <v-row>  
        <v-col cols="12" sm="12"  md="12" >                                                                                
        </v-col>
        <v-col cols="12" sm="12"  md="12" >    
          <v-text-field
                  v-model="messageInfo.title"
                  label="Titulo*"
                  :rules="[
                    required('titulo')
                  ]"       
                ></v-text-field>                                                                                                                                        
        </v-col>
        <v-col cols="12" sm="12"  md="12" >                                                                                
                <v-text-field
                  v-model="messageInfo.name"
                  label="Descripción*"
                  :rules="[
                    required('descripción')
                  ]"       
                ></v-text-field>                
        </v-col>            
        <v-col cols="12" sm="12"  md="12" >                                                                                
          <v-combobox
                    v-model="messageInfo.tipo" 
                    :items="itemsTipo"
                    label="Seleccione un Tipo"                           
          ></v-combobox>
        </v-col>
              
        <v-col                cols="12"                sm="6"        >
                <v-text-field
                  v-if="hasDateRange"
                  v-model="messageInfo.fecha_inicio"
                  label="Fecha Inicio*"
                  :rules="[
                    required('Fecha Inicio')
                  ]"       
                />                
        </v-col>
        <v-col                cols="12"                sm="6"                md="6"              >
                <v-text-field
                    v-if="hasDateRange"
                    v-model="messageInfo.fecha_fin"
                    label="Fecha Fin*"
                    :rules="[
                      required('Fecha Fin')
                    ]"       
                />
              </v-col>
      
  </v-row>        

  <v-btn
          color="primary"
          class="mr-4"
          @click="submitForm(messageInfo)" 
          :disabled="!valid"
  >
        <v-icon>save</v-icon> {{buttonText}}
  </v-btn>        
  <v-btn
          color="error"
          class="mr-4"
          @click="reset"
        >
          <v-icon>cleaning_services</v-icon> Reset Form
        </v-btn>        
  </v-form>
</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import validations from '@/utils/validation';

export default {
  name: 'MessageForm',  
  components: {
  },  
  computed:{
    ...mapState([''])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async getPrice2(){      
      try{       
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        // this.$store.dispatch('loadPricelists',"All"); 
        //this.attribute.sword="All";
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      }
    },
    reset () {
        this.$refs.form.reset()
    }
  },
  props:["submitForm","buttonText","hasDateRange"],
  data(){
     return{
        valid: false,
        ...validations,
        attribute: {sword: '',limit: 27},
        // pricelists:[],
        itemsTipo:[        
          'System',
          'Telegram',
        ],
        messageInfo:{ 
          title:"",    
          name:"",    
          tipo:"System",    
          fecha_inicio:new Date().toISOString().substr(0,10)+" 00:00:00",
          fecha_fin:new Date().toISOString().substr(0,10)+" 00:00:00",
          notes:''
        }
     }
  },
  created(){
    this.getPrice2()    
  }  
}
</script>          
