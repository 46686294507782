<template>
  <div class="stock"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row>
            <v-col>
              <h5>Buscar:</h5>
              <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

  <div>
      <table>
        <tr><td style="text-align:left; font-size:1.1rem;"><b>Sucursal: </b> {{ branchname }}</td></tr>
        <tr><td style="text-align:left; font-size:1.1rem;"><b>Cliente: </b> {{ customerfullname }}</td></tr>
      </table>
  </div>
  <br><br>

    <!-- Menu -->
    <template>
                      <!-- FIN Dialog confirmación -->
                      <v-card flat  class="py-1" >
                            <v-card-text >
                              <v-row class="text-right" justify="center" >        
                                <v-btn-toggle
                                  v-model="toggle_exclusive"
                                  mandatory
                                >
                                <template>  
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">              
                                        <v-btn
                                          @click="downloadExcel"
                                          color="green"
                                          v-bind="attrs"
                                          v-on="on"
                                        >                
                                          <v-icon>file_present</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Descargar Lista de Precios con Stock</span>
                                    </v-tooltip>
                                </template>          
                                          
                                <template>  
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">              
                                        <v-btn
                                          @click="$router.back()" 
                                          color="primary darken-2"               
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                        <v-icon>arrow_back</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Regresar a la Página Anterior</span>
                                    </v-tooltip>
                                </template>
                                  
                                  
                                </v-btn-toggle>
                              </v-row>
                            </v-card-text>
                          </v-card>
                          
    </template>
    <!-- FIN Menu -->

  <v-row>
    <v-col cols="12" sm="12" md="12" lg="12"    
        v-if="rows.length > 0"
    >
        <span class="right"><b>{{ rows.length }}</b> registro(s) encontrados <br></span>
  
    <table class="table table-striped">
     <thead class="thead-dark">
      <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
     </thead>
     <tr v-for="row in rows" :key="row.id">          
      <td>                  
         <a v-bind:href="url(row.id)" target="_blank">
           <v-icon>visibility</v-icon>
         </a>                
      </td>             
      <td>{{row.id}}    </td>            
      <td>{{row.subtotal_salesorders | currency }} </td>
      <td>
        {{row.discount_moneda | currency }} <br>
        <small>( {{row.discount_salesorders}}% )</small>

      </td>
      <td>{{row.subtotal_salesorders_discount | currency }} </td>
      <td>{{row.iva_salesorders | currency }} </td>
      <td><span style="font-weight:bold; font-size:1.3rem">  {{row.total_salesorders | currency}} </span></td>
      <td>
        <div v-if="row.notes != null && row.notes != ''">   
          <v-btn
                  color="red lighten-2"
                  dark
                  @click="getNote(row.notes)" 
                >
                  ver nota
                </v-btn>
        </div>
      </td>        
      <td>{{row.date_salesorder}}</td>
      <td>{{row.created_at}}</td>
      <td>{{row.updated_at}}</td>
     </tr>
    </table>          
   
    <table>
        <tr>
          <td style="text-align:left; font-size:1.1rem;"></td> 
          <td style="text-align:right; font-size:1.1rem;"><b>Subtotal:</b> {{ subtotal | currency }}</td> 
        </tr>
        <tr>
          <td style="text-align:left; font-size:1.1rem;"></td> 
          <td style="text-align:right; font-size:1.1rem;"><b>Desc:</b> {{ desc | currency }}</td>  
        </tr>
        <tr>
          <td style="text-align:left; font-size:1.1rem;"></td> 
          <td style="text-align:right; font-size:1.1rem;"><b>Subtotal c/Desc:</b> {{ subtotaldesc | currency }}</td>  
        </tr>
        <tr>
          <td style="text-align:left; font-size:1.1rem;"></td> 
          <td style="text-align:right; font-size:1.1rem;"><b>IVA:</b> {{ iva | currency }}</td>  
        </tr>
        <tr>
          <td style="text-align:left; font-size:1.1rem;"></td> 
          <td style="text-align:right; font-size:1.1rem;"><b>Total:</b> {{ total | currency }}</td> 
        </tr>
    </table>
    
  </v-col>
  <v-col cols="12" sm="12" md="12" lg="12" v-else  >
          <v-alert
            dense
            outlined
            type="error"
          >
            No hay registros encontrados con esta busqueda
          </v-alert>
  </v-col>
</v-row>     
    <br><br><br>   

    <!-- DIALOG NOTAS -->
   <div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->

  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import baseApi from '@/services/baseApi';
import exportFromJSON from "export-from-json";
import router from '@/router';


export default {
  name: 'stock',
  components: {
      //FieldSearch,
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]), 
    downloadExcel() {              
        try{

           for (var i = 0; i < this.rows.length; i+=1) {              
              // console.log("Pricelist: "+this.rows[i].pricelist_id)
             
              this.investorsList[i]={
                  'venta id': this.rows[i].id,                  
                  'nombre del cliente': this.rows[i].razon_social,
                  titulo: this.rows[i].title,
                  // priceid: this.rows[i].pricelist_id,
                  'articulo': this.rows[i].pricelist_descripcion,
                  tipo: this.rows[i].type_article,                  
                  // familia: this.rows[i].famcategoryname,
                  subtotal: this.rows[i].subtotal_salesorders,                  
                  descuento: parseFloat(this.rows[i].discount_moneda)+'('+this.rows[i].discount+'%)',
                  'subtotal c/descuento': this.rows[i].subtotal_salesorders_discount,                  
                  iva: this.rows[i].iva_salesorders,
                  total: this.rows[i].total_salesorders,
                  'fecha de pago': this.rows[i].date_salesorder,
              //     preciopublico: parseFloat(this.rows[i].price_publico),
              //     stock: this.rows[i].stock,
              //     minstock: this.rows[i].min_stock,
              //     maxstock: this.rows[i].max_stock,
              //     camino: this.rows[i].camino,
              };                 
            }
            this.$swal("Genial!",'Se exporto la informacion correctamente', "success");
            
  
          const data = this.investorsList;
          const fileName = "clientes_articulo_icheck";
          const exportType = exportFromJSON.types.csv;
    
          if (data) exportFromJSON({ data, fileName, exportType });

        }catch(error){
          if (error.message=='Request failed with status code 500') {
            this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
          }else if(error.message=='Request failed with status code 401'){
            this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
            router.push('/login');
          }else{
            this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
          } 
        }finally{
          this.hiddenLoading()
        } 

    },
    url: function (href){
        return 'detalleventas?salesorderid='+ href
        //return 'detalleventascliente?customerid='+ href
    },          
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    }, 
    async getBreadcrumps(sword){
      this.changeBreadcrumbs({
        page:"Ventas",
        title:"Detalle de Ventas por Cliente",
        subtitle:"primary",
        description:"En este stock encontrará todas las ventas, gastos dados de alta en el sistema desde el principio de los tiempos"
       });
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];

      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        let datos = await baseApi().get(`/notaremision/getall?valor=2019-01-01|`+this.sfecha+`&word=${sword}&tipo=customer&customerid=`+this.$route.query.customerid);
        //console.log(datos.data);        
        
        if(datos.data.length >0){
          this.rows=datos.data    
          //var total2=0;    
          for (var i = 0; i < this.rows.length; i+=1) { 
            this.customerfullname=this.rows[0].customerfullname; 
            this.branchname=this.rows[0].branchname; 
            //console.log("PRICE: "+this.rows[i].price);            
            // if (this.rows[0].salesorderdiscount!=0) this.desc=(this.salesorder_subtotal*(this.rows[0].salesorderdiscount/100)); 
            // else this.desc=0;

            this.subtotal=(this.subtotal + (this.rows[i].subtotal_salesorders));  
            this.desc=(this.desc + (this.rows[i].discount_moneda));  
            this.subtotaldesc=(this.subtotaldesc + (this.rows[i].subtotal_salesorders_discount));  
            this.iva=(this.iva + (this.rows[i].iva_salesorders));              
            this.total=(this.total + (this.rows[i].total_salesorders));  
            // console.log(this.rows[i].discount_salesorders+'--'+this.desc);
            // console.log(this.rows[i].subtotal_salesorders+'--'+this.subtotal);
            // console.log(this.rows[i].total_salesorders+'--'+this.total);
          }      
          //console.log(1+this.rows[1].total);
          //this.desserts=datos.data          
        }else{
          this.rows=0;
        }

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()        
      } 

      // try{        
      //   this.showLoading({titulo:"Accediendo información",color:"primary"})        
      //   let datos = await baseApi().get(`/clientes/getall`);
      //   //console.log(datos);
      //   if(datos.data.length >0){
      //     this.customers=datos.data
      //   }else{
      //     this.customers="Sin resultados";
      //   }
      // }catch(error){
      //   //console.log(error);
      // } finally{
      //   this.hiddenLoading()
      // } 
    }
  },
  data(){
     return{
      toggle_exclusive:'',
      sword:"",
      dialogNotes:false,
      notes:'',
      dialog:false,
      investorsList: [],
      // editRow:null,
      branchname:"",      
      customerfullname:"",
      subtotal:0,
      subtotaldesc:0,
      desc:0,
      iva:0,
      total:0,
      sfecha:new Date().toISOString().substr(0,10),
      rows: [],
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        {
          text: 'Venta ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },        
        { text: 'Subtotal', value: 'amount_other' },
        { text: 'Descuento', value: 'amount_other' },
        { text: 'Subtotal c/Descuento', value: 'amount_other' },
        { text: 'IVA', value: 'cantidad' },
        { text: 'Total', value: 'cantidad' },
        { text: 'Notas', value: 'notes' },
        { text: 'Fecha Pago', value: 'fecha_pago' },
        { text: 'Fecha Registro', value: 'created_at' },
        { text: 'Fecha Actualización', value: 'updated_at' },       
      ],     
    }
  },
  created(){
    this.getBreadcrumps(this.sword)    
  }
}
</script>