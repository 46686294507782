<template>
  <div class="payment"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row :class="sm-6" >
            <v-col>
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>



<v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <router-link to="/payment-add">
                    <v-btn                  
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </router-link>
                  </template>
                  <span>Agregar Nuevo Pago</span>
                </v-tooltip>
            </template>                               
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>
  

  
<v-row>
  <v-col cols="12"    sm="12"      md="12"      lg="12"    
    v-if="payments.length > 0"
  >
    <v-row>
        <!-- 
          <PluginItem :plugin="plugin" v-for="plugin in plugins" :key="plugin.id" />
          -->
    <table class="table table-striped">
     <thead class="thead-dark">
      <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
     </thead>
     <tr v-for="(row, index ) of payments" :key="row.id">          
      <td>
         <!-- 
         <button v-on:click="deleteFriend(row.id, i)">x</button>
         -->
        <v-btn icon v-on:click="editRow = row.id">
          <v-icon>edit</v-icon>
        </v-btn>                                
      </td>      
      <td>
        <div v-if="editRow === row.id">
          <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.branch_id" /> -->
          <v-autocomplete
                    v-model="row.salesorder_id"
                    :items="salesorders"
                    item-text="id"
                    item-value="id"
                    label="Selecciona una venta*"
                    outlined
            ></v-autocomplete>
      
          <v-text-field
                      v-model="row.price"
                      v-on:keyup.13="updateRow(row)"
                      label="Precio"
          />          
          <v-text-field
                      v-model="row.date_pay"
                      v-on:keyup.13="updateRow(row)"
                      label="Fecha de Pago"
          />          

          <v-combobox
                    v-model="row.tipo" 
                    :items="itemsTipo"
                    label="Seleccione un Tipo"                           
          ></v-combobox>     

          <v-text-field
                      v-model="row.notes"
                      v-on:keyup.13="updateRow(row)"
                      label="Notas"
          />                
         
          <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
        </div>
        <div v-else>        
          <b>{{row.salesordertitle}}</b> <br>
          {{row.customerfullname}} - <small>{{row.branchname}}</small> <br>
          Index: {{index+1}} <br>
          <b>Folio:</b> {{row.id}} <br>
          <b>Venta</b> {{row.salesorder_id}} <br>
          <b>Pago: </b>{{row.date_pay}} <br>
          <b>Registro: </b>{{row.created_at}}<br>
          <b>Actualización: </b>{{row.updated_at}}
        </div>
      </td>            
      <td>{{row.price | currency }} </td>
      <td>
        <div v-if="row.notes != null && row.notes != ''">   
        <div class="text-center">
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red lighten-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  ver nota
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text>
                  {{row.notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          </div>
        </td>        
      <td>
        <div v-if="row.tipo == 'Dinero Electronico'">        
           <v-chip
             class="ma-2"
             color="green"
             label
             text-color="white"
           >
             {{row.tipo}}
           </v-chip>
          </div>
          <div v-else-if="row.tipo == 'SPEI'">        
           <v-chip
             class="ma-2"
             color="red"
             label
             text-color="white"
           >
               {{row.tipo}}
           </v-chip>
          </div>
          <div v-else-if="row.tipo == 'TC'">        
           <v-chip
             class="ma-2"
             color="yellow"
             label
             text-color="black"
           >
               {{row.tipo}} - Tarjeta de Credito
           </v-chip>
          </div>                 
          <div v-else>
           <v-chip
           class="ma-2"
           color="default"
           label
           text-color="black"
           >
             {{row.tipo}}
           </v-chip>
        </div>                                                                       
      </td>
     </tr>
   </table>   

    </v-row>
  </v-col>
  <v-col cols="12"        sm="12"        md="12"        lg="12"        v-else  >
        <v-alert
          dense
          outlined
          type="error"
        >
          No hay registros encontrados con esta busqueda
        </v-alert>
  </v-col>
</v-row>


  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
//import PluginItem from '@/components/PluginItem'
import baseApi from '@/services/baseApi';


export default {
  name: 'payment',
  components: {
    //PluginItem  
  },
  computed:{
    ...mapState(['currentUser','payments','salesorders'])
  },  
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(sword){
      try{        
        this.attribute.sword=sword;
        console.log(sword);
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadPayments',this.attribute);
        let date=new Date();        
        this.attribute.date_start=new Date(date.getFullYear(), date.getMonth() - 1, 1) //Un mes antes del actual con el primer día del mes
        this.attribute.date_start= `${date.getFullYear()}-${date.getMonth()}-${this.attribute.date_start.getDate()}` //Formateamos la fecha
        console.log(this.attribute.date_start);
        this.attribute.sword="";
        await this.$store.dispatch('loadSalesOrders2',this.attribute);
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    updateRow(row) {      
        try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                   
          baseApi().post(`/pagos/update/${row.id}`,JSON.stringify(row)).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("PAGOS: "+response.data.message);
          });                                                                                                   
          // console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
  },
  created(){
    this.changeBreadcrumbs({
        page:"Pagos",
        title:"Lista de Pagos",
        subtitle:"primary",
        description:"En esta lista encontrará todos los pagos dados de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  },
  data(){
     return{
      attribute: {sword: 'All',limit: 27,date_start:"",date_end:new Date().toISOString().substr(0,10)},
      sword:"All",            
      editRow:null,
      itemsTipo:[        
          'Efectivo',
          'SPEI',
          'TC',
          'TD',
          'Cheque',
          'Dinero Electronico'
        ],
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: '#', value: 'index' },
        { text: 'Precio', value: 'branch_id' },
        { text: 'Notas', value: 'notes' },
        { text: 'Type', value: 'notes' }
      ],
    }
  },
}
</script>