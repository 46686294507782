<template>
  <div class="events"> 
    <template>
      <v-container class="grey lighten-5">
        <v-row >
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <h5>Buscar:</h5>
            <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
          </v-col>              
        </v-row>            
      </v-container>
    </template>

<!-- 
<v-container>
  <v-layout >
    <v-flex xs6 md6 lg6>

       <v-data-table
        :headers="theader"
        :items="desserts"
        :items-per-page="15"
        class="elevation-1"
      ></v-data-table>   
--> 
<v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>post_add</v-icon>
          Agregar Evento
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Nuevo Evento</span>
          <v-btn
            class="headline"
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <EventForm :submitForm="AddEvent" buttonText="Guardar" hasEvent=true />            
               <!-- <v-text-field
                  v-model="salesorder_id"
                  label="Venta ID*"
                  required
                ></v-text-field> -->              
          </v-container>          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <small style="color:red;">*Campos Obligatorios</small>          
          <!-- <v-btn 
            color="blue darken-1"
            text
            v-on:click="submit">Guardar <v-icon>save</v-icon>
          </v-btn>         -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<div><br></div>


   <div>
    <table class="table table-striped">
     <thead class="thead-dark">
      <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
     </thead>
     <tr v-for="(row,index) of events" :key="row.id">          
       <td>
         <!-- 
         <button v-on:click="deleteFriend(row.id, i)">x</button>
         -->
        <v-btn v-on:click="editRow = row.id" icon>
           <v-icon>edit</v-icon>
         </v-btn>
         <a v-bind:href="url(row.id)">
           <v-icon>preview</v-icon>
         </a>                
       </td>             
      <td>
        <div v-if="editRow === row.id">
          <v-autocomplete
            v-model="row.customer_id"            
            :items="customers"
            item-text="razon_social"
            item-value="id"
            label="Selecciona un Cliente"  
            outlined
          ></v-autocomplete>          
          <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.customer_id" /> -->
          <input v-on:keyup.13="updateRow(row)" placeholder="Nombre" v-model="row.name" />
          <input v-on:keyup.13="updateRow(row)" placeholder="Teléfono" v-model="row.fecha_inicio" />
          <input v-on:keyup.13="updateRow(row)" placeholder="Celular" v-model="row.fecha_fin" />    
          <v-combobox
              v-model="row.status" 
              :items="itemsStatus"
              label="Seleccione un Status"
          ></v-combobox>          
          <input v-on:keyup.13="updateRow(row)" placeholder="Notas" v-model="row.notes" />
          <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
        </div>
        <div v-else>                    
          {{index+1}}
        </div>        
      </td>
      <td>{{row.id}}</td>
      <td>
          <a v-bind:href="urlCustomer(row.customer_id)">{{row.razon_social}}</a>                
          <br>
          <small>Customer ID: {{row.customer_id}}</small>
      </td>
      <td>{{row.name}}</td>
      <td>
        <div v-if="row.notes != null && row.notes != ''">   
        <div class="text-center">
            <v-dialog
              v-model="dialogNote"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red lighten-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  ver nota
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text>
                  {{row.notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <!-- <v-spacer></v-spacer> -->
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          </div>
        </td>        
      <td>{{row.fecha_inicio}}</td>
      <td>{{row.fecha_fin}}</td>
      <td>
        <div v-if="row.status == 'Finalizado'">        
          <v-chip
          class="ma-2"
          color="red"
          label
          text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{row.status}}
          </v-chip>
        </div>      
        <div v-else-if="row.status == 'Contratado'">
           <v-chip
           class="ma-2"
           color="green"
           label
           text-color="black"
           >
             <v-icon left>
               mdi-label
             </v-icon>
             {{row.status}}
           </v-chip>
        </div>
        <div v-else-if="row.status == 'Contactado'">
           <v-chip
           class="ma-2"
           color="yellow"
           label
           text-color="black"
           >
             <v-icon left>
               mdi-label
             </v-icon>
             {{row.status}}
           </v-chip>
        </div>
        <div v-else-if="row.status == 'No Responde'">
           <v-chip
           class="ma-2"
           color="orange"
           label
           text-color="black"
           >
             <v-icon left>
               mdi-label
             </v-icon>
             {{row.status}}
           </v-chip>
        </div>                                                                     
        <div v-else>
          <v-chip
          class="ma-2"
          color="default"
          label
          text-color="black"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{row.status}}
          </v-chip>
        </div>                                                                       
      </td>                
      <td>
        <div v-if="row.ver == '1'">        
          <v-chip
          class="ma-2"
          color="green"
          label
          text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            Activo
          </v-chip>
        </div>                 
        <div v-else>
          <v-chip
          class="ma-2"
          color="default"
          label
          text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            Desactivado
          </v-chip>
        </div>                                                                       
      </td>            
      <td>{{row.created_at}}</td>
      <td>{{row.updated_at}}</td>       
     </tr>
   </table>          
   </div>          
        
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->


  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations, mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import baseApi from '@/services/baseApi';
import EventForm from "@/components/EventForm";
import router from '@/router';

export default {
  name: 'events',
  computed:{
        ...mapState(['events','customers'])
  },
  components: {
      //FieldSearch,
      EventForm,
  },
  methods:{
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
      async getBreadcrumps(sword){       
        this.attribute.sword=sword;
        try{
          this.showLoading({titulo:"Accediendo información",color:"primary"})
          this.$store.dispatch('loadEvents',this.attribute);          
          this.attribute.sword="All";
          await this.$store.dispatch('loadCustomers',this.attribute);
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
        } 
      },      
      url: function (href){
          return 'detalleventascliente?eventsid='+ href
      }, 
      urlCustomer: function (href){
        return 'detalleventascliente?customerid='+ href
      },
      async AddEvent(EventInfo){
        // alert("Agregar Evento");
        // alert (EventInfo.name);
        try{ 
          this.showLoading({titulo:"Almacenando información",color:"warning"})
          let name=EventInfo.name;
          let customer_id=EventInfo.customer_id;
          let fecha_inicio=EventInfo.fecha_inicio;
          let fecha_fin=EventInfo.fecha_fin;
          let notes=EventInfo.notes;
          // const { salesorder_id,customer_id,fecha_inicio,cantidad } = this;        
          //let datos=await baseApi().post();          
          await baseApi().post(`/eventos`,JSON.stringify({ customer_id,name,fecha_inicio, fecha_fin, notes })).then((response)=>{
              if(!response.data.error){                            
                // this.$swal("Genial!","", "success");
                this.$swal("Genial!",response.data.message, "success");
              }else{
                // this.$swal("Error!",response.data.message, "error");
                this.$swal("Error!",JSON.stringify(response.data.validate), "error"); 
              }                                    
              // console.log("VENTA: "+response.data.message);
          });
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        } 
      },       
      updateRow(row) {      
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})        
            baseApi().post(`/eventos/update/${row.id}`,JSON.stringify(row)).then((response)=>{
                if(!response.data.error){                            
                  // this.$swal("Genial!","", "success");
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
                // console.log("VENTA: "+response.data.message);
            });                                                                                                    
            // console.log(datos);
          }catch(error){
            this.$swal("Error!", "Necesita ingresar sus credenciales", "error");
            router.push('/login');
            console.log(error);
          }  finally{
            this.hiddenLoading()
            // this.$swal("Genial!", "Se actualizó la información", "success");
          } 
      },
  },
  data(){
    return{
      attribute: {sword: '',limit: 500},
      dialog:false,
      // dialogNote:false,
      sword:"",      
      editRow:null,
      itemsStatus:[        
          'Proceso',
          'Contactado',
          'No Responde',
          'Finalizado'
      ],      
      theader: [
          { text: 'Acción', value: 'canasta_basica' },
          { text: '#', value: 'index' },
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Contacto', value: 'customer' },
          { text: 'Descripción', value: 'name' },
          { text: 'Notas', value: 'notes' },
          { text: 'Fecha Inicio', value: 'lastname' },
          { text: 'Fecha Fin', value: 'company' },
          { text: 'Status', value: 'status' },
          { text: 'Estado', value: 'ver' },        
          { text: 'Registro', value: 'created_at' },        
          { text: 'Actualización', value: 'updated_at' },        
      ],
    }
  }, 
  created(){
    this.changeBreadcrumbs({
          page:"Eventos",
          title:"Lista de Eventos",
          subtitle:"primary",
          description:"En esta lista encontrará todos los eventos dados de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  }
}
</script>