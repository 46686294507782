<template>
  <div class="quoteadd">
    <template>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >      
      <v-row>
        <v-col
          cols=12
          xs=12
          sm=12
          md=6
          lg=6
        >          
          <v-text-field
            v-model="title"
            label="Título"
            type="text"          
            :rules="[
              required('título')
            ]"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols=12
          xs=12
          sm=12
          md=6
          lg=6
        >
         <v-autocomplete
            v-model="customer_id"
            :hint="`${customer_id.id} - ${customer_id.razon_social}`"
            :items="customers"
            item-text="razon_social"
            item-value="id"
            label="Selecciona un Cliente"  
            outlined
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>                 
          <v-col cols=12 sm=12 xs=12 md=6 lg=6>
            <v-switch
              v-model="iva"
              label="IVA"
            ></v-switch>                   
        </v-col>
        <v-col cols=12 sm=12 xs=12 md=6 lg=6>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date_quote"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_quote"
                  label="Fecha de Venta"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_quote"
                locale="es-mx"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date_quote)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
      </v-row>
      <v-row>
        <v-col
          cols=12
          xs=12
          sm=12
          md=12
          lg=12
        >          
        <v-textarea
          v-model="notes"
          filled
          auto-grow
          label="Escribe una Nota"
          rows="4"
          row-height="30"
          shaped
        ></v-textarea> 
          
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 sm=12 xs=12 md=12 lg=12>
          <h5>Agregar Productos</h5>
        <table>
          <thead class="thead-dark">
            <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
          </thead>
          <tr v-for="(invoice_product, k) in salesorder_products" :key="k">              
              <td scope="row" class="trashIconContainer">
                  <v-btn icon @click="deleteRow(k, invoice_product)">
                    <v-icon color="red">delete_outline</v-icon>
                  </v-btn>                  
              </td>              
              <td>
                  <v-autocomplete
                    v-model="invoice_product.pricelist_id"
                    :items="priceslist"
                    item-text="description"
                    item-value="id"
                    label="Selecciona un producto*"
                    required
                    outlined
                  ></v-autocomplete>
              </td>
              <td>
                  <v-text-field
                      v-model="invoice_product.posicion"
                      class="form-control"
                      pricelabel="Nombre"
                      :rules="[
                        onlyNumeric('cantidad')
                      ]"
                      required
                  ></v-text-field>                    
              </td>
              <td>
                  <v-text-field
                      v-model="invoice_product.price"
                      @change="calculateLineTotal(invoice_product)"
                      class="form-control text-right"
                      type="number"
                      min="0" 
                      step=".01"
                      label="Precio"
                      :rules="[
                        onlyNumeric('cantidad')
                      ]"
                      required
                  ></v-text-field>
              </td>
              <td>
                  <v-text-field
                      v-model="invoice_product.cantidad"
                      @change="calculateLineTotal(invoice_product)"                      
                      class="form-control text-right"
                      type="number"
                      min="0" 
                      step=".01"
                      label="Cantidad"
                      :rules="[
                        onlyNumeric('cantidad')
                      ]"
                      required
                  ></v-text-field>                  
              </td>
              <td>
                  <v-text-field
                      v-model="invoice_product.discount"
                      @change="calculateLineTotal(invoice_product)"                      
                      class="form-control text-right"
                      type="number"
                      min="0" 
                      step=".01"
                      label="Descuento"
                      :rules="[
                        onlyNumeric('descuento')
                      ]"
                      required
                  ></v-text-field>                  
              </td>
              <td>
                  <v-text-field
                      v-model="invoice_product.line_total"
                      readonly
                      class="form-control text-right"
                      type="number"
                      min="0" 
                      step=".01"
                      label="Total"
                      :rules="[
                        onlyNumeric('cantidad')
                      ]"
                      required
                  ></v-text-field>                  
              </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right"><b>Subtotal:</b></td>
            <td class="text-right">${{salesorder_subtotal}}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right">IVA:</td>
            <td class="text-right">{{salesorder_tax}}%
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right">Total:</td>
            <td class="text-right">${{salesorder_total}}
            </td>
          </tr>
        </table>
        
                <v-btn icon class="btn" @click="addNewRow" >
                  <v-icon>add</v-icon>                    
                </v-btn>                
                <!-- <v-btn icon class="btn" @click="saveInvoice" >
                  <v-icon>save</v-icon>                    
                </v-btn>   -->
                <v-btn v-on:click="submit"  color="success"><v-icon>save</v-icon></v-btn>                
        </v-col>
      </v-row>            
    </v-form>
    </template>
  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import validations from '@/utils/validation';
import baseApi from '@/services/baseApi';

export default {
  name: "quoteadd",
  computed:{
    ...mapState(['customers','currentUser','priceslist','fields']),
    cantidad:{
      get() {
        return this.fields[0].cantidad;
      },
      set(){}
      // set(value) {
      //   value=0
      //   this.aumentar(value);
      // }
    }
  },  
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs",'aumentar','disminuir']),   
    saveInvoice() {            
            console.log(JSON.stringify(this.salesorder_products));
    },
    calculateLineTotal(invoice_product) {            
            var stotal = parseFloat(invoice_product.price) * parseFloat(invoice_product.cantidad);
            var total = parseFloat(stotal-(stotal*(invoice_product.discount/100)));
            
            if (!isNaN(total)) {
                invoice_product.line_total = total.toFixed(2);
            }
            this.calculateTotal();
    },
    calculateTotal() {
            var subtotal, total;
            subtotal = this.salesorder_products.reduce(function (sum, product) {
                var lineTotal = parseFloat(product.line_total);
                if (!isNaN(lineTotal)) {
                    return sum + lineTotal;
                }
            }, 0);

            this.salesorder_subtotal = subtotal.toFixed(2);
            total = (subtotal * (this.salesorder_tax / 100)) + subtotal;
            total = parseFloat(total);
            if (!isNaN(total)) {
                this.salesorder_total = total.toFixed(2);
            } else {
                this.salesorder_total = '0.00'
            }
    },    
    deleteRow(index, invoice_product) {
            var idx = this.salesorder_products.indexOf(invoice_product);
            console.log(idx, index);
            if (idx > -1) {
                this.salesorder_products.splice(idx, 1);
            }
            this.calculateTotal();
    },
    addNewRow() {
            this.salesorder_products.push({
                pricelist_id: '',
                posicion: this.salesorder_products.length+1,
                price: '',
                cantidad: '',
                line_total: 0
            });
    },
    async getBreadcrumps(){
      this.attribute.sword="All";
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        await this.$store.dispatch('loadCustomers',this.attribute);
        this.$store.dispatch('loadPricelists',"All");
      }catch(error){
        //console.log(error);
      } finally{
        this.hiddenLoading()
      } 
    },
    submit: async function() {
      let a=this.$refs.form.validate();            
      if(a==true){
        try{        
          this.showLoading({titulo:"Almacenando información",color:"warning"})            
          let branch_id=this.currentUser.branch_id;
          let detailquotes=this.salesorder_products;          
          // alert (branch_id);          
          const { customer_id,title,iva,date_quote,notes } = this;              
          baseApi().post(`/cotizaciones/createAll`,JSON.stringify({ branch_id,customer_id,title,iva,date_quote,notes,detailquotes})).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("COTIZACION: "+response.data.message);
          });
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        }      
      }
    }
  },
  data: function() {
    return {
      dialog:false,
      attribute: {sword: '',limit: 27},
      modal:'',
      valid: true,
      ...validations,
      id:'',
      customer_id:'',
      title:"",
      iva:"1",
      date_quote: new Date().toISOString().substr(0,10),
      notes:'',
      salesorder_subtotal: 0,
      salesorder_total: 0,
      salesorder_tax: 16,
      salesorder_products: [{
                pricelist_id: '',
                posicion: 1,
                price: '',
                cantidad: '',
                discount: 0,
                line_total: 0
      }],
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: 'Producto', value: 'index' },
        {
          text: 'Posición',
          align: 'start',
          sortable: false,
          value: 'posicion',
        }, 
        { text: 'Precio', value: 'price' },        
        { text: 'Cantidad', value: 'cantidad' },        
        { text: 'Descuento', value: 'descuento' },        
        { text: 'Total', value: 'total' },        
      ],
      
      
    };                  
  },
  created(){
    this.changeBreadcrumbs({
        page:"Nueva Cotización",
        title:"Agregar Nueva Cotización",
        subtitle:"primary",
        description:"Este formulario se agregará nueva cotización seleccionado"
    });
    this.getBreadcrumps()    
  }
}

</script>
<style>
  .v-date-picker-table {    
    height: auto;
  }
</style>