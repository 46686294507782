<template>
  <div class="supplier"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row
            :class="sm-6"
          >
            <v-col>
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

    <v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
                        
            
            <!-- Dialog para agregar Proveedor--> 
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Proveedor</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <SupplierCreate :user="user" />
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Nuevo Proveedor</span>
            </v-tooltip>
        </template> 
         <!-- FIN Dialog para agregar --> 


                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>



<v-row>
  <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        v-if="suppliers.length > 0"
  >
    <v-row>
      <!-- filteredSuppliers -->
        <SupplierItem :supplier="supplier" v-for="supplier in suppliers" :key="supplier.id" />
    </v-row>
  </v-col>
  <v-col 
        cols="12"
        sm="12"
        md="12"
        lg="12"
        v-else
  >
        <v-alert
          dense
          outlined
          type="error"
        >
          No hay registros encontrados con esta busqueda
        </v-alert>
  </v-col>
</v-row>   
        
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->


  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import SupplierItem from '@/components/SupplierItem'
import SupplierCreate from "@/components/SupplierCreate";
// import baseApi from '@/services/baseApi';
import router from '@/router';



export default {
  name: 'supplier',
  computed:{
        ...mapState(['suppliers'])
  },
  components: {
    SupplierItem,
    SupplierCreate 
  },  
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(sword){
      this.attribute.sword=sword;
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadSuppliers',this.attribute);
      }catch(error){
        if (error.message=='Request failed with status code 500') {
          this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
        }else if(error.message=='Request failed with status code 401'){
          this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
          router.push('/login');
        }else{
          this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
        }
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
  },
  created(){
    this.changeBreadcrumbs({
        page:"Proveedor",
        title:"Lista de Proveedores",
        subtitle:"primary",
        description:"En esta lista encontrará todos los proveedores dados de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  },
  data(){
     return{
      attribute: {sword: '',limit: 27},
      dialog: false,
      sword:"",            
      editRow:[],
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: '#', value: 'index' },
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Sucursal', value: 'branch_id' },
        { text: 'Razon Social', value: 'razon_social' },
        { text: 'RFC', value: 'rfc' },
        { text: 'Domicilio', value: 'address' },
        { text: 'Núm. Ext.', value: 'num_ext' },
        { text: 'Núm. Int.', value: 'num_int' },
        { text: 'C.P.', value: 'cp' },
        { text: 'Teléfono', value: 'phone' },
        { text: 'Móvil', value: 'mobile' },        
        { text: 'Email', value: 'email' },        
        { text: 'Notes', value: 'notes' },        
      ],
    }
  },
}
</script>
