<!-- 
  2022-09-1
  Problemas con la etiqueta y/o lib ejs-combobox  
-->
<template>
  <div>
  <span class="right"> <b>{{ pricelist.length }}</b> articulos encontrados </span>
  <table class="table table-striped ">
    <thead class="thead-dark">
      <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
     </thead>
    <tr v-for="pricelist in pricelist"  :key="pricelist.id">
      <!-- </table> -->
      <td>  
      <!-- QTY no funciona por que no existe en el arreglo-->
       <!-- Botones Carrito de compras -->
       <v-card 
          flat 
          class="py-2"
          max-width="210"
        >
          <v-card-text class="my-0 py-0">
            <v-row class="text-right" justify="center" >        
              <v-btn-toggle
                v-model="toggle_exclusive"
                mandatory
              >
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">              
                      <v-btn
                        @click="disminuir(pricelist)" 
                        color="red"               
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                      <v-icon>remove</v-icon>
                      </v-btn>
                    </template>
                    <span>Disminuir cantidad</span>
                  </v-tooltip>
              </template>
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">  
                      <!-- <v-responsive
                        class="mx-auto"
                        max-width="450"
                      >                     -->
                        <!-- rounded -->
                        <!-- single-line -->
                        <!-- append-icon="mdi-magnify" -->
                        <!-- class="escsizeinput" -->
                        <!-- label="Filled" -->
                        <!-- variant="outlined" -->
                        <v-text-field
                          class="my-0 py-0"
                          v-model="pricelist.qty"                
                          placeholder="Cantidad"
                          :value="0"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      <!-- </v-responsive> -->
                    </template>
                    <span>Ingresa cantidad</span>
                  </v-tooltip>
              </template> 
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">              
                      <v-btn
                        @click="aumentar(pricelist)"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >                
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>Aumentar cantidad</span>
                  </v-tooltip>
              </template>  
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">              
                      <v-btn
                        @click="addCar(pricelist)" 
                        color="green"               
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                      <v-icon>add_shopping_cart</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar al carrito de compras</span>
                  </v-tooltip>
              </template>
                
              </v-btn-toggle>
            </v-row>
          </v-card-text>
        </v-card>  
       <!-- FIN Botones Carrito de compras -->

       <!-- Botones Acciones -->
       <v-card 
          flat 
          class="py-0"
          max-width="210"
        >
          <v-card-text class="my-0 py-0">
            <v-row class="text-right" justify="center" >        
              <v-btn-toggle
                v-model="toggle_exclusive"
                mandatory
              >
              <!-- Link a Web -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">              
                      <v-btn
                        v-if='pricelist.url != null'
                        color="deep-white default"
                        v-bind:href="pricelist.url"
                        target="_blank"
                        v-on="on"
                      >                
                        <v-icon>public</v-icon>
                      </v-btn>
                    </template>
                    <span>Ir al eCommerce</span>
                  </v-tooltip>
              </template>
              <!-- FIN Link a Web -->

              <!-- Edit -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ attrs }">              
                      <v-btn  
                        color="deep-purple warning"
                        v-on:click="getDataProduct(pricelist.id)"
                        v-bind="attrs"
                        >                
                        <!-- v-on:click="editRow = pricelist.id"  -->
                        <!-- v-on="on" -->
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Articulo</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Edit -->
              
              <!-- View -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        color="blue"
                        v-on:click="getProduct(pricelist.id)"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                         <v-icon>visibility</v-icon>                         
                        </v-btn>
                      </template>
                      <span>Detalle del Articulo</span>
                  </v-tooltip>
              </template>           
              <!-- FIN View -->

              <!-- History -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        color="deep-black"
                        v-bind:href="url(pricelist.id)"   
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                         <v-icon>view_list</v-icon>                         
                        </v-btn>
                      </template>
                      <span>Historial del Articulo</span>
                  </v-tooltip>
              </template> 
              <!-- FIN History -->
                  

            </v-btn-toggle>
            </v-row>
          </v-card-text>
      </v-card>  
      <!-- FIN Botones Acciones -->


      <!-- Botones Acciones -->
      <v-card 
          flat 
          class="py-0"
          max-width="210"
        >
          <v-card-text class="my-0 py-0">
            <v-row class="text-right" justify="center" >        
              <v-btn-toggle
                v-model="toggle_exclusive"
                mandatory
              >

               <!-- Subir a Meli -->
               <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.meli == 1'
                        @click="upProductMeli(pricelist.id)"
                        color="yellow default"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>publish</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar Producto a Mercado libre</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Subir a Meli -->

              <!-- Sincronizar con WC -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.woo == 1'
                        @click="pricechangeWoo(pricelist.id)"
                        color="deep-orange default"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>price_change</v-icon>
                      </v-btn>
                    </template>
                    <span>Sincronizar con Woocommerce</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Sincronizar con WC -->


              <!-- Subir a WC -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.woo == 0'
                        @click="upProductWoo(pricelist.id)"
                        color="deep-purple default"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>publish</v-icon>
                      </v-btn>
                    </template>
                    <span>Subir Producto a Woocommerce</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Subir a WC -->
              
              <!-- Sincronizar con Syscom -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.syscom == 1'
                        color="deep-purple blue"
                        @click="upProductSyscom(pricelist.id)"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>publish</v-icon>
                      </v-btn>
                    </template>
                    <span>Sincronizar con Syscom</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Sincronizar con Syscom -->

              <!-- Sincronizar con CT -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.ctonline == 1'
                        color="deep-red red"
                        @click="upProductCTOnline(pricelist.id)"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>download</v-icon>
                      </v-btn>
                    </template>
                    <span>Sincronizar con CTOnline</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Sincronizar con CT -->

              <!-- Sincronizar con CVA -->
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on,attrs }">              
                      <v-btn
                        v-if='pricelist.cva == 1'
                        color="deep-green green"
                        @click="upProductCVA(pricelist.id)"
                        v-bind="attrs"
                        v-on="on"
                      >                
                         <!-- v-on:click="pricechangeWoo(pricelist.id)"  -->
                        <v-icon>download</v-icon>
                      </v-btn>
                    </template>
                    <span>Sincronizar con CVA</span>
                  </v-tooltip>
              </template> 
              <!-- FIN Sincronizar con CVA -->

            </v-btn-toggle>
            </v-row>
          </v-card-text>
      </v-card>  
      <!-- FIN Botones Acciones -->

      </td>  
      <td>
        {{pricelist.description}} <br>
        <b>Marca: </b>{{pricelist.brandname}} | <b>Modelo: </b>{{pricelist.modelo}} <br>
      </td>
      <td>
        <center>

          <BarcodeGenerator
          :value="pricelist.id"
          :format="'CODE39'"
          :lineColor="'#000'"
          :width="1"
          :height="20"
          :elementTag="'img'"
          :displayValue=false
          /> <br>
          <small>            
            {{pricelist.sku}} <br>
            <!-- <b>SKU FAB: </b>{{pricelist.modelo}} <br> -->
          </small>
        </center>
      </td>
      <td>{{pricelist.clave_sat}}</td>
      <td>{{pricelist.price | currency}}</td>
      <td>{{pricelist.price_publico | currency }}</td>
      <td>
        <v-chip
              class="ma-2"
              color="green"
              label
              text-color="white"
              >              
                <v-icon left>production_quantity_limits</v-icon>
                {{pricelist.stock }}              
              </v-chip>   

      </td>
      <td>
        <v-chip                
                class="ma-2"
                color="red"
                label
                text-color="white"
                >
                  <v-icon left>inventory</v-icon>
                  {{pricelist.min_stock}}
              </v-chip>    
      </td>
      <td></td>
      <td>
        <v-chip                
                class="ma-2"
                color="blue"
                label
                text-color="white"
                >
                  <v-icon left>local_shipping</v-icon>
                  {{pricelist.camino }}
              </v-chip>   
      </td>
    </tr>
  </table>

      <!-- VISTA PRODUCTO DIALOG-->
      <template>                 
                      <v-dialog
                        v-model="dialogViewProduct"
                        persistent
                        max-width="800px"                 
                      >
                          <v-card>
                            <v-card-title>
                              <span class="headline">Detalle Producto</span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialogViewProduct = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-container v-if='this.dialogViewProduct==true'>
                                <ProductView :productid="productid"  />
                            </v-container>
                            <!-- :pricelist="priceslist -->
                            <!-- buttonText="Guardar"   -->
                          <v-card-actions>
                              <v-btn 
                              
                              color="red" 
                              @click="dialogViewProduct = false"
                              >
                              cerrar
                            </v-btn>
                              <v-spacer></v-spacer>              
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
      </template>                 
      <!-- FIN VISTA PRODUCTO  DIALOG-->

      <!-- EDITAR PRODUCTO -->
      <template>                 
                          <v-dialog
                            v-model="dialog10"
                            persistent
                            max-width="800px"                 
                          >
                        
                              <v-card>
                                <v-card-title>
                                  <span class="headline">Editar Producto </span>
                                  <v-btn
                                    class="headline"
                                    color="blue darken-1"
                                    text
                                    @click="dialog10 = false"
                                  >
                                    <v-icon>close</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-card-text>
                                  <v-container v-if='this.dialog10==true'>
                                    <ProductEdit :product="product" />                                
                                  </v-container>
                              </v-card-text>
                              <v-card-actions>
                                  <v-btn 
                                    color="red" 
                                    @click="dialog10 = false"
                                  >
                                    cerrar
                                  </v-btn>
                                  <v-spacer></v-spacer>   
                                  <small style="color:red;">*Campos Obligatorios</small>
                                </v-card-actions>
                              </v-card>                    
                          </v-dialog>
      </template>    
      <!-- FIN EDITAR PRODUCTO -->

</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
import baseApi from '@/services/baseApi';
// import router from '@/router';
import BarcodeGenerator from "@/components/BarcodeGenerator";
import ProductEdit from "@/components/ProductEdit";
import ProductView from "@/components/ProductView";




export default {
  props:{
    pricelist:{
      type:Object,
      required:true
    }
  },
  computed:{
    //'products'
      ...mapState(['brands','measurements','familycategories','suppliers','currentUser'])
  },
  components: {    
    BarcodeGenerator,
    ProductView,
    ProductEdit
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    url: function (href){
        return 'detalleventasproducto?pricelistid='+ href
    },    
    aumentar(row){ 
      // this.pricelist.qty=0;
      // if(row.qty==null) row.qty=0
      row.qty ++
    },
    disminuir(row){      
      row.qty --
    },
    
    updateRow(row) {      
      try{                
        this.showLoading({titulo:"Actualizando información",color:"primary"})  
        // alert(row.id)
        baseApi().post(`/precios/update/${row.id}`,JSON.stringify(row)).then((response)=>{        
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("VENTA: "+response.data.message);
        });              
      }catch(error){
        console.log('Update Pricelist '+error);
        // if (error.message=='Request failed with status code 500') {
        //   this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
        // }else if(error.message=='Request failed with status code 401'){
        //   this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
        //   router.push('/login');
        // }else{
        //   this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
        // } 
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    },      
    async addCar(row){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Agregando al Carrito",color:"default"})      
          //let branch_id=this.currentUser.branch_id;          
          let pricelist_id=row.id;
          let price=row.price_publico/1.16; //Se divide 1.16 para que sea el precio sin iva
          let cantidad=row.qty;
          //let cantidad=1;
          //alert("ID:"+price)
          //const { pricelist_id,price, cantidad } = this;        
          await baseApi().post(`/carrito`,JSON.stringify({ pricelist_id,price,cantidad })).then((response)=>{
                if(!response.data.error){        
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
                // console.log("VENTA: "+response.data.message);
          });                      
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
        } 
    },
    async getProduct(ProductID){
      console.log('Producto ID:'+ProductID);
      this.productid=ProductID;
      // alert(this.productid);
      this.dialogViewProduct=true;      
    }, 
    async getProduct2(ProductID){
      this.dialogViewProduct=true;
      console.log('Producto ID:'+ProductID);
      baseApi().get(`/precios/${ProductID}`).then((response)=>{
              if(!response.data.error){                            
                // this.$swal("Genial!",response.data.message, "success");
                this.productrow=response.data
                // this.rows.productfilename=response.data.filename
                //alert(this.rows.productfilename)

              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
      });
    }, 
    getDataProduct(priceid) {      
          let id={pricelist_id: priceid}; //no se para que
          // alert(id.pricelist_id);
          // this.product=row
          this.product=id
          this.dialog10=true
    },  
    async upProductMeli(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Subir informacion a Mercado Libre",color:"blue"})
          
          await baseApi().get('/mlibre/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async upProductSyscom(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Obtener informacion de Syscom",color:"blue"})
          
          await baseApi().get('/syscom/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async upProductCTOnline(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Obtener informacion de CTOnline",color:"red"})
          
          await baseApi().get('/ctconnect/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async upProductCVA(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Obtener informacion de CVA",color:"green"})
          
          await baseApi().get('/cva/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async upProductWoo(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Creado el producto en Woocommerce",color:"default"})
          
          await baseApi().get('/woo/products/create/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
    async pricechangeWoo(id){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Actualizando el precio en Woocommerce",color:"default"})
          // let branchID=this.currentUser.branch_id;          
          // await baseApi().get('/woo/products/updatepriceone/'+id+'?branch_id='+branchID).then((response)=>{  
          await baseApi().get('/woo/products/updatepriceone/'+id).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message+"2", "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
    },
  },
  data(){
    return{
      // attribute: {sword: 'All',limit: 500},
      editRow: null,
      dialog10:false,
      dialogViewProduct:false,
      alertCar:false,
      toggle_exclusive:false,
      productrow:{},
      product:{},
      theader: [
          { text: '', value: 'name' },
          { text: 'Descripcion', value: 'name' },
          { text: 'sku', value: 'constructionDate' },
          { text: 'clave sat', value: 'constructionDate' },
          { text: 'precio', value: 'location' },
          { text: 'precio publico', value: 'location' },
          { text: 'stock', value: 'location' },
          { text: 'stock min', value: 'location' },
          { text: 'stock max', value: 'location' },
          { text: 'en camino', value: 'location' },       
      ],
      itemsStatus:[        
          'Público',
          'Privado',
          'Descontinuado',
      ],
      dataFields:{value:'id',text:'game'},
      itemsTypeArticle:[
         //'KIT',
         'Productos',
         'Servicios',
      ],
      dataIArticle:[
         {id: 'KIT', game: 'KIT'},
         {id: 'Productos', game: 'Productos'},
         {id: 'Servicios', game: 'Servicios'}         
        ],
      dataFArticle:{value:'id',text:'game'}
    }
  }
}
</script>

<style>
  .v-card__title{
    height: 155px;
    align-content: baseline;
    font-size:1rem;
    line-height:1.5rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }  
</style>