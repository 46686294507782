var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"EventForm"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.hasMeasurement)?_c('v-text-field',{attrs:{"label":"Siglas*","rules":[
                    _vm.required('Siglas')
                  ]},model:{value:(_vm.eventInfo.siglas),callback:function ($$v) {_vm.$set(_vm.eventInfo, "siglas", $$v)},expression:"eventInfo.siglas"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Descripción*","rules":[
                    _vm.required('descripción')
                  ]},model:{value:(_vm.eventInfo.name),callback:function ($$v) {_vm.$set(_vm.eventInfo, "name", $$v)},expression:"eventInfo.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.hasEvent)?_c('v-autocomplete',{attrs:{"items":_vm.customers,"item-text":"razon_social","item-value":"id","label":"Selecciona un Cliente","outlined":""},model:{value:(_vm.eventInfo.customer_id),callback:function ($$v) {_vm.$set(_vm.eventInfo, "customer_id", $$v)},expression:"eventInfo.customer_id"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.hasEvent)?_c('v-text-field',{attrs:{"label":"Fecha Inicio*","rules":[
                    _vm.required('Fecha Inicio')
                  ]},model:{value:(_vm.eventInfo.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.eventInfo, "fecha_inicio", $$v)},expression:"eventInfo.fecha_inicio"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.hasEvent)?_c('v-text-field',{attrs:{"label":"Fecha Fin*","rules":[
                      _vm.required('Fecha Fin')
                    ]},model:{value:(_vm.eventInfo.fecha_fin),callback:function ($$v) {_vm.$set(_vm.eventInfo, "fecha_fin", $$v)},expression:"eventInfo.fecha_fin"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.hasEvent || _vm.hasBrand)?_c('v-text-field',{attrs:{"label":"Notas"},model:{value:(_vm.eventInfo.notes),callback:function ($$v) {_vm.$set(_vm.eventInfo, "notes", $$v)},expression:"eventInfo.notes"}}):_vm._e()],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.submitForm(_vm.eventInfo)}}},[_c('v-icon',[_vm._v("save")]),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("cleaning_services")]),_vm._v(" Reset Form ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }