<template>
  <div class="leads"> 
    <template>
      <v-container class="grey lighten-5">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <h5>Buscar:</h5>
            <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
          </v-col>
        </v-row>
      </v-container>
    </template>


    <v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >

            <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <!-- 
                          <v-icon>add</v-icon>
                          -->
                        <v-icon>post_add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Nuevo Mensaje</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container>  
                            <MessageForm :submitForm="AddMessage" buttonText="Guardar" />            
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Nuevo Mensaje</span>
            </v-tooltip>
        </template>
        <!-- FIN Dialog para agregar --> 
                             
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>

   <div>
    <table class="table table-striped">
     <thead class="thead-dark">
      <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
     </thead>
     <tr v-for="row in rows" :key="row">          
       <td>
         <!-- 
         <button v-on:click="deleteFriend(row.id, i)">x</button>
         -->
        <v-btn v-on:click="editRow = row.id" icon>
           <v-icon>edit</v-icon>
         </v-btn>
        <v-btn v-on:click="sendText(row.id)" icon>
          <v-icon>send</v-icon>
        </v-btn>
       </td>      
      <td>
        <div v-if="editRow === row.id">
          <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.branch_id" /> -->
          <input v-on:keyup.13="updateRow(row)" placeholder="Titulo" v-model="row.title" />
          <input v-on:keyup.13="updateRow(row)" placeholder="Descripción" v-model="row.name" />     
          <v-combobox
            v-model="row.canal"
            :items="itemsCanal"
            label="Selecciona un Canal"
          ></v-combobox>
          <v-combobox
            v-model="row.type"
            :items="itemsType"
            label="Selecciona un Tipo"
          ></v-combobox>
          <v-combobox
            v-model="row.status"
            :items="itemsStatus"
            label="Selecciona un Status"
          ></v-combobox>
          <!-- 
            <ejs-combobox v-on:keyup.13="updateRow(row)"  :dataSource='dataCanal' :fields='dataFCanal' placeholder='Seleccione un canal' popupWidth="250px">
            </ejs-combobox>
            <ejs-combobox v-on:keyup.13="updateRow(row)" v-model="row.type" :dataSource='dataItem' :fields='dataFields' placeholder='Seleccione un tipo' popupWidth="250px">            
            </ejs-combobox>
            <ejs-combobox v-on:keyup.13="updateRow(row)" v-model="row.status" :dataSource='dataStatus' :fields='dataFStatus' placeholder='Seleccione un status' popupWidth="250px">
            </ejs-combobox>           
          -->
          <input v-on:keyup.13="updateRow(row)" placeholder="Fecha de Envío" v-model="row.date_send" />          
          <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
        </div>
        <div v-else>
          {{row.id}} <br> 
          <small>{{row.title}}</small><br>
          {{row.name}}
        </div>        
      </td>
      <td>{{row.canal}}</td>
      <td>{{row.type}}</td>
      <td>
        <div v-if="row.status == 'Enviado'">        
          <v-chip
          class="ma-2"
          color="green"
          label
          text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{row.status}}
          </v-chip>
        </div>                 
        <div v-else>
          <v-chip
          class="ma-2"
          color="default"
          label
          text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{row.status}}
          </v-chip>
        </div>                                                                       
      </td>
      <td>{{row.date_send}}</td>
      <td>{{row.created_at}}</td>
      <td>{{row.updated_at}}</td>        
     </tr>
   </table>          
   </div>          
        
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->


  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import MessageForm from "@/components/MessageForm";
import baseApi from '@/services/baseApi';

export default {
   name: 'leads',
    components: {
      //FieldSearch,
      MessageForm,
    },
    data(){
     return{
      sword:"",
      sfecha:new Date().toISOString().substr(0,10),
      rows: [],
      dialog:"",
      editRow:null,      
      itemsStatus: [
          'Pendiente',
          'Enviado',
      ],            
      itemsCanal: [
          '@DevCloudBits',
          '@iOnOffMX',
      ],                  
      itemsType: [
          'System',
          'Telegram',
      ],           
      
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        {
          text: 'Descripcion',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Canal', value: 'canal' },
        { text: 'Tipo', value: 'type' },
        { text: 'Status', value: 'status' },
        { text: 'Fecha Envío', value: 'date_send' },
        { text: 'Fecha Registro', value: 'created_at' },
        { text: 'Fecha Actualización', value: 'updated_at' },
      ],
    }
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(sword){
      this.changeBreadcrumbs({
        page:"Mensajes",
        title:"Lista de Mensajes",
        subtitle:"primary",
        description:"En esta lista encontrará todos los mensajes dados de alta en el sistema desde el principio de los tiempos"
       });
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];

      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        // const baseURL=`https://www.cloudbits.org.mx/fw/lumen/check/mensajes/getall?branch_id=1&&valor=2020-01-01|2020-12-31&word=${sword}`;
        // const config = {
        //   method: 'get', //you can set what request you want to be
        //   url:baseURL,
        //   timeout: 10000,
        //   headers: {
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Headers': '*',
        //     "Content-Type": "application/json",
        //     "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
        //   }
        // }
        
        // let datos=await axios(config)
        let datos = await baseApi().get(`/mensajes/getall?valor=2020-01-01|2020-12-31&word=${sword}`);
        //console.log(datos);
        
        if(datos.data.length >0){
          this.rows=datos.data          
          this.desserts=datos.data          
        }else{
          this.rows="Sin resultados";
        }

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    async AddMessage(MessageInfo){
        // alert("Agregar Evento");
        // alert (MessageInfo.name);
        try{ 
          this.showLoading({titulo:"Almacenando información",color:"warning"})
          let title=MessageInfo.title;
          let name=MessageInfo.name;
          let type=MessageInfo.tipo;
          let date_send=this.sfecha;
          //let customer_id=MessageInfo.customer_id;
          //let fecha_inicio=MessageInfo.fecha_inicio;
          //let fecha_fin=MessageInfo.fecha_fin;
          //let notes=MessageInfo.notes;
          // const { salesorder_id,customer_id,fecha_inicio,cantidad } = this;        
          //let datos=await baseApi().post();          

          await baseApi().post(`/mensajes`,JSON.stringify({ title,name,type,date_send })).then((response)=>{
              if(!response.data.error){                            
                // this.$swal("Genial!","", "success");
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
          });
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        } 
      }, 
    sendText($id) {      
      try{        
        //console.log($id);
        this.showLoading({titulo:"Enviando Mensaje a Telegram",color:"warning"})
        //var url="https://www.cloudbits.org.mx/fw/lumen/check/precios/update/"+ row.id;
        // const baseURL3=`https://www.cloudbits.org.mx/fw/lumen/check/telegram/sendtext/`+$id;
        // const config = {
        //   method: 'get', //you can set what request you want to be
        //   url:baseURL3,
        //   timeout: 10000,
        //   headers: {
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Headers': '*',
        //     "Content-Type": "application/json",
        //     "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
        //   }
        // }                                                                                                
        // let datos=this.axios(config)                                                                               
        let datos = baseApi().get(`/telegram/sendtext/`+$id);                         
        console.log(datos);
      }catch(error){
        console.log(error);
      }  finally{
        this.hiddenLoading()
        this.$swal("Genial!", "Se envió la información a Telegram", "success");
      }     
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})        
          //var url="https://www.cloudbits.org.mx/fw/lumen/check/precios/update/"+ row.id;
          // const baseURL2=`https://www.cloudbits.org.mx/fw/lumen/check/mensajes/update/${row.id}`;
          // const config = {
          //   data: JSON.stringify(row),
          //   method: "post", //you can set what request you want to be
          //   url:baseURL2,
          //   timeout: 10000,
          //   headers: {
          //     'Access-Control-Allow-Origin': '*',
          //     'Access-Control-Allow-Headers': '*',
          //     "Content-Type": "application/json",
          //     "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
          //   }
          // }
          // let datos=this.axios(config)   
          let datos = baseApi().post(`/mensajes/update/${row.id}`,JSON.stringify(row));                                                                                                      
          console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
  },
  created(){
    this.getBreadcrumps(this.sword)    
  }
}
</script>