<template>
  <div class="compararprice"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row
            :class="sm-6"
          >
            <v-col>
              <h5>Buscar:</h5>
              <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>  

    <v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <router-link to="/pwproduct-add">
                    <v-btn                  
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </router-link>
                  </template>
                  <span>Agregar Nuevo Producto</span>
                </v-tooltip>
            </template>        
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <router-link to="/pwproductcollate-add">
                    <v-btn                  
                      color="purple"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </router-link>
                  </template>
                  <span>Agregar Nuevo Collate del Producto</span>
                </v-tooltip>
            </template>                               
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>

   <div>
    <table class="table table-striped">
     <thead class="thead-dark">
      <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
     </thead>
     <tr v-for="row in rows" :key="row">                 
      <td>{{row.id}}</td>       
      <td>        
        <a :href="row.url+row.path" target="_blank">{{row.productdescription}}</a>                   
        <br><small><b>SKU:</b>{{row.skuprovider}}</small><br>
        <small>Product ID: {{row.productid}}</small>
      </td>      
      <td>
          <span style="font-size:.1.2rem;">{{row.fprecio | currency }}</span>                          
          <!-- <span style="font-size:.1.2rem; color:red;">{{row.fprecio | currency }}</span>                   -->
      </td>

      <td>
        <a v-bind:href="url(row.providerid)" >
           {{row.razon_social}}
        </a>                      
      </td>
      <td>{{row.created_at}}</td>        
     </tr>
   </table>          
   </div>   


  </div>
</template>

<script>
import axios from "axios";
// import{ mapMutations,mapState } from "vuex";
import{ mapMutations } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'

export default {
   name: 'compararprice',
   created(){
      this.changeBreadcrumbs({
          page:"Precios",
          title:"Comparacion de precios",
          subtitle:"primary",
          description:"En esta lista encontrará comparación de precios segun el mes escogido"
        });
      this.getBreadcrumps(this.sword)
    }, 
    computed:{
      // ...mapState(['pwproduct'])    
    },
    components: {
      //FieldSearch,
    },
    data(){
     return{
      sword:"",
      editRow: null,
      sfecha:new Date().toISOString().substr(0,10),
      pricelow:0,
      path:"",
      rows: [],
      dataItem:[        
          {id: 'Público', game: 'Público'},
          {id: 'Privado', game: 'Privado'},
          {id: 'Descontinuado', game: 'Descontinuado'},
      ],
      dataFields:{value:'id',text:'game'},
      theader: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descripcion', value: 'sku' },
        { text: 'Precio', value: 'sku' },
        { text: 'Market Place', value: 'sku' },
        { text: 'Fecha Registro', value: 'created_at' },
      ],
    }
  },
  methods:{  
    url: function (href){
        return 'pw-prices?productid='+ this.$route.query.productid + '&providerid='+href
    },   
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(sword){
      this.changeBreadcrumbs({
        page:"Comparacion de Productos "+sword,
        title:"Lista de Comparacion de Productos",
        subtitle:"primary",
        description:"En esta lista encontrará todos los precios del mes del producto seleccionado"
       });
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];      
      if (sword=="") {
        sword=this.$route.query.productid
      }

      // console.log("LOGWORD - "+this.$route.query.providerid)
      if (this.$route.query.providerid!== undefined) {
        this.path=sword+`&providerid=`+this.$route.query.providerid;        
      }else{        
        this.path=sword;
      }      
       

      try{            
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        //console.log(this.$route.query.id) // outputs 'yay'    
        const baseURL=`https://www.pwallet.online/api/historyprices/index?productid=`+this.path;
        const config = {
          method: 'get', //you can set what request you want to be
          url:baseURL,
          timeout: 10000,
          headers: {
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': '*',
            // "Content-Type": "application/json",
            // "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
          }
        }
        
        let datos=await axios(config)
        // console.log(datos.data.length);        
        if(datos.data.length>0){
          this.rows=datos.data;          
        }else{
          this.rows="Sin resultados";
        }

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    }
  }
}
</script>