var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"CustomerForm"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[(_vm.hasCustomer)?_c('v-combobox',{attrs:{"items":_vm.itemsTipo,"label":"Selecciona un Tipo*","rules":[
                      _vm.required('Tipo')
                    ]},model:{value:(_vm.customerInfo.tipo),callback:function ($$v) {_vm.$set(_vm.customerInfo, "tipo", $$v)},expression:"customerInfo.tipo"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[(_vm.hasCustomer || _vm.hasSupplier)?_c('v-text-field',{attrs:{"label":"Razon Social*","rules":[
                      _vm.required('razon social')
                    ]},model:{value:(_vm.customerInfo.razon_social),callback:function ($$v) {_vm.$set(_vm.customerInfo, "razon_social", $$v)},expression:"customerInfo.razon_social"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[(_vm.hasCustomer)?_c('v-text-field',{attrs:{"label":"Titulo"},model:{value:(_vm.customerInfo.title),callback:function ($$v) {_vm.$set(_vm.customerInfo, "title", $$v)},expression:"customerInfo.title"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(_vm.hasCustomer || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"Nombre"},model:{value:(_vm.customerInfo.firstname),callback:function ($$v) {_vm.$set(_vm.customerInfo, "firstname", $$v)},expression:"customerInfo.firstname"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.hasCustomer || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"Apellido"},model:{value:(_vm.customerInfo.lastname),callback:function ($$v) {_vm.$set(_vm.customerInfo, "lastname", $$v)},expression:"customerInfo.lastname"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.hasCustomer || _vm.hasSupplier || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"Domicilio","rules":[
                      () => !!_vm.customerInfo.address || 'Se necesita el domicilio',
                      () => !!_vm.customerInfo.address && _vm.customerInfo.address.length <= 175 || 'Domicilio debe ser mayor a 1 y menor 175 caracteres'              
                    ]},model:{value:(_vm.customerInfo.address),callback:function ($$v) {_vm.$set(_vm.customerInfo, "address", $$v)},expression:"customerInfo.address"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[(_vm.hasCustomer || _vm.hasSupplier || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"Num. Ext","rules":[
                      _vm.required('num. ext')
                    ]},model:{value:(_vm.customerInfo.num_ext),callback:function ($$v) {_vm.$set(_vm.customerInfo, "num_ext", $$v)},expression:"customerInfo.num_ext"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(_vm.hasCustomer || _vm.hasSupplier || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"Colonia"},model:{value:(_vm.customerInfo.colonia),callback:function ($$v) {_vm.$set(_vm.customerInfo, "colonia", $$v)},expression:"customerInfo.colonia"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2","md":"4"}},[(_vm.hasCustomer || _vm.hasSupplier || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"C.P.","rules":[
                      _vm.onlyNumeric('codigo postal'),
                      _vm.NoComas('codigo postal')
                    ]},model:{value:(_vm.customerInfo.zip),callback:function ($$v) {_vm.$set(_vm.customerInfo, "zip", $$v)},expression:"customerInfo.zip"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.hasCustomer || _vm.hasSupplier || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"RFC"},model:{value:(_vm.customerInfo.rfc),callback:function ($$v) {_vm.$set(_vm.customerInfo, "rfc", $$v)},expression:"customerInfo.rfc"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(_vm.hasCustomer || _vm.hasSupplier || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"Email","rules":[
                      // required('email'),
                      //invalidEmail('email')
                    ]},model:{value:(_vm.customerInfo.email),callback:function ($$v) {_vm.$set(_vm.customerInfo, "email", $$v)},expression:"customerInfo.email"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(_vm.hasCustomer || _vm.hasSupplier || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"Telefono"},model:{value:(_vm.customerInfo.phone),callback:function ($$v) {_vm.$set(_vm.customerInfo, "phone", $$v)},expression:"customerInfo.phone"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(_vm.hasCustomer)?_c('v-text-field',{attrs:{"label":"Oficina"},model:{value:(_vm.customerInfo.office),callback:function ($$v) {_vm.$set(_vm.customerInfo, "office", $$v)},expression:"customerInfo.office"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(_vm.hasCustomer || _vm.hasSupplier || _vm.hasEmployee)?_c('v-text-field',{attrs:{"label":"Celular"},model:{value:(_vm.customerInfo.mobile),callback:function ($$v) {_vm.$set(_vm.customerInfo, "mobile", $$v)},expression:"customerInfo.mobile"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[(_vm.hasCustomer)?_c('v-combobox',{attrs:{"items":_vm.itemsStatus,"label":"Selecciona un Status","rules":[
                      _vm.required('Status')
                    ]},model:{value:(_vm.customerInfo.status),callback:function ($$v) {_vm.$set(_vm.customerInfo, "status", $$v)},expression:"customerInfo.status"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[(_vm.hasCustomer)?_c('v-combobox',{attrs:{"items":_vm.itemsFuente,"label":"Selecciona una Fuente","rules":[
                      _vm.required('Fuente')
                    ]},model:{value:(_vm.customerInfo.fuente),callback:function ($$v) {_vm.$set(_vm.customerInfo, "fuente", $$v)},expression:"customerInfo.fuente"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.hasEmployee)?_c('v-combobox',{attrs:{"items":_vm.itemsType,"label":"Selecciona un Tipo"},model:{value:(_vm.customerInfo.tipo),callback:function ($$v) {_vm.$set(_vm.customerInfo, "tipo", $$v)},expression:"customerInfo.tipo"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.hasEmployee)?_c('v-combobox',{attrs:{"items":_vm.itemsPuesto,"label":"Selecciona un Puesto"},model:{value:(_vm.customerInfo.puesto),callback:function ($$v) {_vm.$set(_vm.customerInfo, "puesto", $$v)},expression:"customerInfo.puesto"}}):_vm._e()],1)],1),_c('v-row',[(_vm.hasCustomer || _vm.hasSupplier || _vm.hasEmployee)?_c('v-textarea',{attrs:{"filled":"","auto-grow":"","label":"Escribe comentarios","rows":"4","row-height":"30","shaped":""},model:{value:(_vm.customerInfo.notes),callback:function ($$v) {_vm.$set(_vm.customerInfo, "notes", $$v)},expression:"customerInfo.notes"}}):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.hasReset)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("cleaning_services")]),_vm._v(" Borrar Formulario ")],1):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.submitForm(_vm.customerInfo)}}},[_c('v-icon',[_vm._v("save")]),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }