<template>
  <div class="car"> 
    <template>
      <v-container class="grey lighten-5">
        <v-row>
            <v-col sm="6">          
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

<v-card
    flat
    class="py-12"
  >
    <v-card-text >
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
          v-model="toggle_exclusive"
          mandatory
        >        
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="orderByAsc"                  
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>format_list_numbered</v-icon>
                </v-btn>
              </template>
              <span>Ordenar la lista de productos</span>
            </v-tooltip>
        </template> 
        
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Producto / Servicio</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container>            
                            <ProductForm :submitForm="DetailProduct" hasQuote=true buttonText="Guardar"   />
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Producto</span>
            </v-tooltip>
        </template>   
        <!-- Dialog para agregar --> 

        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="convertir('Quote')"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>picture_as_pdf</v-icon>
                </v-btn>
              </template>
              <span>Convertir en Cotización</span>
            </v-tooltip>
        </template>          
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="convertir('SalesOrder')"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>picture_as_pdf</v-icon>
                </v-btn>
              </template>
              <span>Convertir en Salida</span>
            </v-tooltip>
        </template> 
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="convertir('Purchase')"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>picture_as_pdf</v-icon>
                </v-btn>
              </template>
              <span>Convertir en Compra</span>
            </v-tooltip>
        </template>  
        <!-- Devolucion -->
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="convertir('Return')"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>picture_as_pdf</v-icon>
                </v-btn>
              </template>
              <span>Convertir en Devolucion</span>
            </v-tooltip>
        </template>  
        <!-- FIN Devolucion -->

                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
        </template>
          
          
        </v-btn-toggle>
      </v-row>
    </v-card-text>
</v-card>

<v-row>
  <v-col cols="12" xs="12" sm="12" md="8" lg="10" xl="10" v-if="rows.length > 0">
    <!-- Busqueda Car -->
      <CarItem :cars="rows"  />
    <!-- FIN Busqueda Car -->
  </v-col>
  <!-- LADO DERECHO -->
  <v-col cols="12" xs="12" sm="12" md="4" lg="2" xl="2">    
    <h5 >Ordenar Lista {{ draggingInfo }}</h5>
    <div class="form-check">
          <input
            id="disabled"
            type="checkbox"
            v-model="enabled"
            class="form-check-input"
          />
          <label class="form-check-label" for="disabled">Habilitar</label>
    </div>
    <br><br>
      <v-card
        class="mx-auto"
        max-width="100%"
      >
        <v-list>
          <v-list-item-group       
            mandatory     
            color="indigo"
          >
              <draggable
                :list="rows"
                :disabled="!enabled"
                class="list-group"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging=false"
                @end="order2()"
              >
                <v-list-item
                  v-for="(row,index) in rows"
                  :key="row"                  
                  v-slot="{ active, toggle }"
                >
                  <v-list-item-avatar>
                    <v-img
                        :src="row.pricelist.filename"
                        :lazy-src="row.pricelist.filename"
                        :alt="`${row.pricelist.description} avatar`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        max-height="95"
                        max-width="95"
                    ></v-img>                    
                  </v-list-item-avatar>
                  <v-list-item-content
                    :color="active ? 'primary' : ''"
                    @click="toggle"
                  >                    
                    <v-list-item-title>
                      <a :href="row.url" target="_blank">{{row.pricelist.description}}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <small><b>SKU:</b> {{row.pricelist_id}}</small><br>
                      Index: {{index+1}} -  Posicion {{row.posicion}}
                    </v-list-item-subtitle>                    
                  </v-list-item-content>                   
                </v-list-item>
              </draggable>
          </v-list-item-group>
        </v-list>     
      </v-card>  
  </v-col>
  <!-- FIN LADO DERECHO -->
</v-row>

      
  </div>
</template>

<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import draggable from "vuedraggable";
//import FieldSearch from '@/components/FieldSearch.vue'
//import DetailQuoteAdd from "@/components/DetailQuoteAdd.vue";
import baseApi from '@/services/baseApi';
import icheckApi from '@/services/icheckApi';
import ProductForm from "@/components/ProductForm";
// import CarView from "@/components/CarView";
import CarItem from "@/components/CarItem";


export default {
  name: 'car',
  computed:{
    ...mapState(['currentUser'])
  },  
  components: {
      //FieldSearch,
      draggable,
      CarItem,
      ProductForm,
  },      
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    dateToday(row){
      if(row.status == "Inventario"){
        //alert("Estas como inventario")
        row.date_arrive=this.sfecha;
      }else{
        row.date_arrive="";
      }
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                
          baseApi().post(`/carrito/update/${row.id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              // this.$swal("Genial!","", "success");
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("COTIZACION: "+response.data.message);
          });                                                                                                     
          // console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },    
    checkMove: function(e) {      
      window.console.log("Nombre: " + e.draggedContext.element.productname);
      window.console.log("Future index: " + e.draggedContext.futureIndex);
      window.console.log("Origen index: " + e.draggedContext.index);
      window.console.log("destino index: " + e.relatedContext.index);  
      this.index=e.draggedContext.index;
      this.order=e.relatedContext.index;      
      window.console.log("Index: " + this.index);
      window.console.log("Orden: " + this.order);      
    },
    orderByAsc(){
      let i=0;      
      let size=this.rows.length;      
      for (i; i < size; i++) { 
            this.rows[i].posicion = i+1;                       
            this.updateRow(this.rows[i]); //Funciona pero pasa todos los valores
            window.console.log("Posicion: " + this.rows[i].posicion);
      }         
      
    },
    order2(){
      this.dragging=false;
      let i=0;
      let id=this.index;      
      let order=this.order;
      let size=this.rows.length;

      // alert("Por fin lo solto");
      // console.log("ID: "+id)
      // console.log("Size: "+this.list.length)            

      if (order>id){        
          for (i = id; i < size; i++) { 
            this.rows[i].posicion = i+1;                       
          }          
      }else{
        for (i = order; i < size; i+=1) { 
          this.rows[i].posicion = i+1;                       
        }
      }
      
    },
          
    async getBreadcrumps(){      
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];
      try{            
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        // let branch_id=this.currentUser.branch_id;
        // console.log('Car icheckApi');
        let datos= await icheckApi().get(`/carrito/getall`);
        // let datos= await baseApi().get(`/carrito/getall`);
        // let datos= await baseApi().get(`/detalleventa/`+this.$route.query.salesorderid+`?branch_id=`+branch_id);
        // console.log(datos.data.length);
        if(datos.data.length >0){
          this.rows=datos.data;            
          for (var i = 0; i < this.rows.length; i+=1) {   
            // console.log(this.rows[i]);
            // this.folio=this.rows[0].purchase_id;            
            // this.razon_social=this.rows[0].razon_social; 
            // this.date_purchase=this.rows[0].date_buy; 
            this.subtotal=(this.subtotal + (this.rows[i].price*this.rows[i].qty));  //Se multiplica con uno para que lo convierta en numero            

            let total_line=this.calculateLineTotal(this.rows[i]);
            // console.log(total_line);
            //this.quote_subtotal=(this.quote_subtotal + (total_line-(total_line*(this.rows[i].discount/100))));  //Se multiplica con uno para que lo convierta en numero                        
            this.car_subtotal=this.car_subtotal + total_line;                      

            let purchaseTotal=this.car_subtotal;            
            
            if (this.rows[0].discount!=0) this.desc=(this.car_subtotal*(this.rows[0].discount/100)); 
            else this.desc=0;

            this.car_subtotal2=purchaseTotal-this.desc;
            
            if (this.rows[0].iva!=0) this.iva=(purchaseTotal*0.16); 
            else this.iva=0;            

            this.totalcost=this.car_subtotal2 + this.iva;            
          }                     
          //this.desserts=datos.data          
        }else{
          this.rows=[];
        }

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },  
    convertir(action){
      let path=''
      let word=''
      if (action=='Return') {
        path='/carrito/convReturn'     
        word='devolucion'   
      }else if (action=='Purchase') {
        path='/carrito/convPurchase'
        word='compra'   
      }else if (action=='SalesOrder') {
        path='/carrito/convSalesOrder'
        word='salida'   
      }else if (action=='Quote') {
        path='/carrito/convQuote'        
        word='cotizacion'   
      } else {
        path=''
        word=''
      }
      this.$swal({
              title: "Desea convertir el carrito en "+word+"?",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Convertir",
              denyButtonText: "Cancelar"
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                try{        
                  this.showLoading({titulo:"Accediendo información",color:"warning"})                  
                  baseApi().get(path).then((response)=>{
                    if(!response.data.error){                            
                        this.$swal("Genial!",response.data.message, "success");
                    }else{
                        this.$swal("Error!",response.data.message, "error");
                    }                                    
                    console.log("Copiar a Compra: "+response.data.message);
                  });
                }catch(error){
                  console.log(error);     
                }  finally{
                  this.hiddenLoading()          
                  //this.$swal("Genial!", "Se copió la información como ventas", "success");            
                } 
              } else{ //if (result.isDenied) {
                this.$swal("Los datos continuan igual", "", "info");
              }
      });
      // if(confirm("Desea convertir el carrito en "+word+"?")){
        
      // }
    }, 
    
    async DetailProduct(ProductInfo){
      // alert("Agregar Product");
      // alert (ProductInfo.quote_id);
      // alert (ProductInfo.pricelist_id);
      // alert (ProductInfo.cantidad);
      // alert (ProductInfo.price);
      try{ 
        this.showLoading({titulo:"Almacenando información",color:"warning"})        
        //let purchase_id=ProductInfo.purchase_id;
        let purchase_id=this.$route.query.purchaseid; //this.rows[0].quote_id
        let pricelist_id=ProductInfo.pricelist_id;        
        let price=ProductInfo.price;
        let cantidad=ProductInfo.cantidad;
        let posicion=ProductInfo.posicion;
        let notes=ProductInfo.notes;
        baseApi().post(`/carrito`,JSON.stringify({ purchase_id,pricelist_id,posicion,price,cantidad,notes })).then((response)=>{        
            if(!response.data.error){                            
              // this.$swal("Genial!","", "success");
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("VENTA: "+response.data.message);
        });
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      } 
    },    
                  
    
  },
  data(){
    return{
      enabled: false,      
      dragging: false,
      dialog: false,
      toggle_exclusive:'',
      draggingInfo:'',
      sword:"",
      productrow:{},
      sfecha:new Date().toISOString().substr(0,10),
      rows: [],
      subtotal:0,
      car_subtotal:0,
      editRow:null,
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: '#', value: 'subtotal_quote' },
        // {
        //   text: 'ID',
        //   align: 'start',
        //   sortable: false,
        //   value: 'id',
        // },
        
        { text: 'Imagen', value: 'filename' },        
        { text: 'Producto', value: 'subtotal_quote' },
        { text: 'Precio de Lista', value: 'precio_other' },
        { text: 'Descuento', value: 'discount' },                
        { text: 'Precio Unidad', value: 'precio_other' },
        { text: 'Cantidad', value: 'cantidad' },                
        { text: 'Importe', value: 'total_quote' }, 
        { text: 'Fecha Registro', value: 'created_at' },
        { text: 'Fecha Actualización', value: 'updated_at' },
      ],
      acciones: [
        { title: 'Download PDF',icon:"save",metodo:"downloadPDF" }
      ],      
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Carrito de Compras "+this.sword,
        title:"Lista del Carrito de Compra",
        subtitle:"primary",
        description:"En esta lista encontrará todos los detalles del carrito de compras dada de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps()     
  }
}
</script>