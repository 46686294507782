<template>
  <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
    <v-card>
      <v-img
          height="100px"
          src="https://www.ionoff.com.mx/wp-content/uploads/2021/03/20287026-scaled.jpg"
      >              
            <v-card-title class="white--text ">
              <div class="text-center">
                <v-avatar size="57">
                  <img
                    alt="user"
                    src="https://www.cloudbits.org.mx/img/avatar-1.png"
                  >
                </v-avatar>
              </div>
              <p class="ml-3">
                {{plugin.title}} <br> 
                <small>( {{plugin.type}} )</small>
              </p>                            
          </v-card-title>
        </v-img>        
          <v-card-text>
            <div class="font-weight-bold ml-8 mb-2">
              #{{plugin.id}}<br>
              Host: {{plugin.host}} <br>
              Port: {{plugin.port}}<br>
              Protocolo: {{plugin.protocol}} <br>
              Encryption: {{plugin.encryption}} <br>
              Channel: {{plugin.channel}} <br>
              Email: {{plugin.email}} <br>
              Password: {{plugin.password}} <br>
              Code: {{plugin.code}} <br>
              Key: {{plugin.key}} <br>
              Key ID: {{plugin.key_id}} <br>
              Key Secret: {{plugin.key_secret}} <br>
              Token1: {{plugin.token1}} <br>
              Token2: {{plugin.token2}} <br>
              Refresh Token: {{plugin.refresh_token}}
            </div>
            <div v-if="editRow === plugin.id">
              <!-- <input v-on:keyup.13="updateRow(row)" v-model="row.branch_id" /> -->
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.title" placeholder="Título"/>
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.host" placeholder="Host"/>
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.port" placeholder="Port" />
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.protocol" placeholder="Protocol"/>
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.encryption" placeholder="Encrypt"/>
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.channel" placeholder="Channel"/>
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.email" placeholder="Email"/>
              <v-text-field
                          class="my-2 py-0"
                          v-model="plugin.username"    
                          v-on:keyup.13="updateRowBranch(plugin)"             
                          label="Username"
                          placeholder="Escribe un username"
                          outlined
              ></v-text-field>
              <!-- <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.username" placeholder="Username"/> -->
              <v-text-field
                          class="my-2 py-0"
                          v-model="plugin.password"    
                          v-on:keyup.13="updateRowBranch(plugin)"             
                          label="Password"
                          placeholder="Escribe un Password"
                          outlined
              ></v-text-field>
              <!-- <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.password" placeholder="Password"/> -->
              <v-text-field
                          class="my-2 py-0"
                          v-model="plugin.code"    
                          v-on:keyup.13="updateRowBranch(plugin)"             
                          label="Code"
                          placeholder="Escribe un codigo"
                          outlined
              ></v-text-field>
              <!-- <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.code" placeholder="Code"/> -->
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.key" placeholder="Key"/>
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.key_id" placeholder="Key ID"/>
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.key_secret" placeholder="Key Secret"/>
              <v-text-field
                          class="my-2 py-0"
                          v-model="plugin.token1"    
                          v-on:keyup.13="updateRowBranch(plugin)"             
                          label="Token 1"
                          placeholder="Escribe un Token 1"
                          outlined
              ></v-text-field>
              <!-- <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.token1" placeholder="Token 1"/> -->
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.token2" placeholder="Token 2"/>
              <input v-on:keyup.13="updateRow(plugin)" v-model="plugin.refresh_token" placeholder="Refresh Token"/>              
            </div>                                        
          </v-card-text>
          <v-app-bar>
            <div v-if="plugin.ver == '1'">        
              <v-chip
              class="ma-2"
              color="green"
              label
              text-color="white"
              >
                Activo
              </v-chip>
            </div>                 
            <div v-else>
              <v-chip
              class="ma-2"
              color="default"
              label
              text-color="white"
              >
                Desactivado
              </v-chip>
            </div>

            <div v-if="plugin.notes != null && plugin.notes != ''">   
              <div class="text-center">
                <v-dialog
                  v-model="dialog"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="red lighten-2"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      ver nota
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline grey lighten-2">
                      Notas
                    </v-card-title>
                    <v-card-text>
                      {{plugin.notes}}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
              
           
                  <div v-if="plugin.type == 'Mautic' || plugin.type =='ML' || plugin.type =='SW'">   
                    <v-btn v-on:click="goToLink(plugin)" class="purple">1er Step</v-btn>
                  </div>
                  <div v-if="plugin.type =='ML'">   
                    <v-btn @click="genTokenMeli" class="red">2do Step</v-btn>
                  </div>
                  <div v-if="plugin.type =='Mautic'">   
                    <v-btn @click="genTokenMautic" class="red">2do Step</v-btn>
                    <v-btn @click="genRefreshTokenMautic" class="yellow">3er Step</v-btn>
                  </div>
                  <div v-if="plugin.type =='CTConnect'">   
                    <v-btn @click="genTokenCTConnect" class="red">1er Step</v-btn>
                  </div>
                  <div v-if="plugin.type =='ML'">   
                    <v-btn @click="genRefreshTokenML" class="deep-dark">Refresh</v-btn>
                  </div>
                                                                              
        </v-app-bar>
        <v-app-bar>
          <div>
            <v-btn v-on:click="editRow = plugin.id" icon>
                  <v-icon>edit</v-icon>
            </v-btn>
                  </div>
                  <div v-if="editRow === plugin.id">
                    <v-btn v-on:click="updateRow(plugin)" class="green">Actualizar</v-btn>
                    <v-btn v-on:click="editRow=false" class="deep-dark">cerrar</v-btn>
                  </div> 
        </v-app-bar>
        </v-card>
  </v-col>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import baseApi from '@/services/baseApi';

export default {
  props:{
    plugin:{
      type:Object,
      required:true
    }
  },
  computed:{
    ...mapState(['currentUser'])
  }, 
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    url: function (href){
        return 'detalleventascliente?customerid='+ href
    },
    goToLink(row){
      if(row.type=="Mautic"){                            
        window.open("https://www.cloudbits.org.mx/fw/mautic/oauth/v2/authorize?grant_type=authorization_code&response_type=code&client_id="+row.key_id+"&redirect_uri=https://www.ionoff.com.mx/callback");
      }else if(row.type=="SW"){
        console.log('a');
        alert('falta implementar');
      }else{
        window.open("https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id="+row.key_id);
      }      
    },
    async genRefreshTokenML(){
      try{        
        // this.showLoading({titulo:"Generando User Test de Mercado Libre",color:"default"}) 
        await baseApi().get(`/mlibre/refresh_tokens`).then((response)=>{
          if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
        });                               
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
        //this.$swal("Genial!", this.rdesc, this.rtitle);
      }      
    },
    async genTokenCTConnect(){
      try{        
        this.showLoading({titulo:"Generando Token de Mautic",color:"default"})
        await baseApi().get(`/ctconnect/login`).then((response)=>{
          if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
        });                               
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
        //this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    },
    async genTokenMautic(){
      try{        
        this.showLoading({titulo:"Generando Token de Mautic",color:"default"})
        await baseApi().get(`/mautic/gettokens`).then((response)=>{
          if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
        });                               
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
        //this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    },
    async genRefreshTokenMautic(){
      try{        
        this.showLoading({titulo:"Actualizando Token de Mautic",color:"default"})
        await baseApi().get(`/mautic/refreshtokens`).then((response)=>{
          if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
        });                               
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
        //this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    },
    async genTokenMeli(){
      try{        
        this.showLoading({titulo:"Genera Token de Mercado Libre",color:"default"})
        // const baseURL=`https://www.cloudbits.org.mx/fw/lumen/check/mlibre/tokens`;
        // const config = {
        //   method: 'get', //you can set what request you want to be
        //   url:baseURL,
        //   timeout: 90000,
        //   headers: {
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Headers': '*',
        //     "Content-Type": "application/json",
        //     "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
        //   }
        // }
        // let datos=await axios(config)   
        
        /* Para UPDATE ROW */
        //var url="https://www.cloudbits.org.mx/fw/lumen/check/precios/update/"+ row.id;
        // const baseURL2=`https://www.cloudbits.org.mx/fw/lumen/check/precios/update/${row.id}`;
        // const config = {
        //   data: JSON.stringify(row),
        //   method: "post", //you can set what request you want to be
        //   url:baseURL2,
        //   timeout: 10000,
        //   headers: {
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Headers': '*',
        //     "Content-Type": "application/json",
        //     "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
        //   }
        // }

        // let datos=this.axios(config)
        let branchID=this.currentUser.branch_id;
        await baseApi().get(`/mlibre/tokens?branch_id=`+branchID).then((response)=>{
          if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
        });                               
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
        //this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                               
          baseApi().post(`/plugins/update/${row.id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            console.log("Plugins: "+response.data.message);
          });         
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
  },
  data(){
    return{
      editRow: null,
      urlwhats:"https://api.whatsapp.com/send?phone=52",
      itemsStatus: [
          'Customer',
          'Distribuidor',
          'Lead',
      ],
      itemsFuente: [
          'Email',
          'Facebook',
          'Google',
          'Instagram',
          'Mercado Libre',
          'Website',
      ]      
    }
  }
}
</script>
