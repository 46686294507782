<template>
  <div class="supplieradd">
      <div>
        <h5>RFC</h5>
        <input type="text" v-model="rfc" />                
      </div>                                     
      <div>
        <h5>Razon Social</h5>
        <v-text-field
              v-model="razon_social"
              label="Razon Social"
              :rules="[
                required('razon social')
              ]"
        ></v-text-field>
      </div>

      <div>
        <h5>Domicilio</h5>
        <input type="text" v-model="address" />
      </div>
      <div>
        <h5>Num. Exterior</h5>
        <input type="text" v-model="num_ext" />
      </div>
      <div>
        <h5>Num. Interior</h5>
        <input type="text" v-model="num_int" />
      </div>      
      <div>
        <h5>Teléfono</h5>
        <input type="text" v-model="phone" />
      </div>
      <div>
        <h5>Celular</h5>
        <input type="text" v-model="mobile" />
      </div>
      <div>
        <h5>Email</h5>
        <input type="text" v-model="email" />
      </div>                        
      <div>
        <h5>Notas</h5>
        <input type="text" v-model="notes" />
      </div> 

      <v-btn v-on:click="submit">Guardar <v-icon>save</v-icon></v-btn>
  </div>
</template>


<script>
// import axios from "axios";
import validations from '@/utils/validation';
import{ mapMutations,mapState } from "vuex";
import baseApi from '@/services/baseApi';

export default {
  name: "supplieradd",
  computed:{
    ...mapState(['currentUser'])    
  },     
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(){      
      console.log("Dentro del Proveedor")
    },
    submit: async function() {
      // let a=this.$refs.form.validate();
      // if(a==true){
        try{        
          this.showLoading({titulo:"Almacenando información",color:"warning"})
          let branch_id=this.currentUser.branch_id;
          // alert (branch_id);                        
          const { razon_social, rfc, address, colonia,num_ext,num_int,cp,phone,mobile,email,notes } = this;           
          baseApi().post(`/proveedores`,JSON.stringify({ branch_id,razon_social, rfc, address, colonia,num_ext,num_int,cp,phone,mobile,email,notes })).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("COMPRAS: "+response.data.message);
          });                    
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        }      
      // }
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Nuevo Precio",
        title:"Agregar Nuevo Proveedor",
        subtitle:"primary",
        description:"Este formulario se agregará nuevo proveedor"
      });
    this.getBreadcrumps()    
  },
  data: function() {
    return {
      attribute: {sword: 'All',limit: 27},
      valid: true,      
      ...validations,
       id:'',
       rfc:'',
       razon_social: '',
       address:'',
       colonia:'',
       num_ext:0,
       num_int:0, 
       cp:"",        
       phone:'',
       mobile:'',
       email:'',
       notes:'',
    };                  
  }
}

</script>