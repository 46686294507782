<template>
  <div class="pwcollateproductadd" >
    <v-row>

          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="sku_provider"
              label="SKU Proveedor"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
          <v-text-field
          v-model="fprecio"
          label="Precio al dia de Hoy"              
          ></v-text-field>
        </v-col>

          
    </v-row>
    <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="path"
              label="Path de URL"              
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="shortener"
              label="URL corta"
            ></v-text-field>
          </v-col>
    </v-row>

   

      <v-row>
        <v-col
        cols="12"
        sm="6"
        >
        <v-autocomplete
              v-model="product_id"
              :hint="`${description}`"
              :items="pwproducts"
              item-text="description"
              item-value="id"
              label="Selecciona un Producto"  
              outlined
        ></v-autocomplete> 
      </v-col>
      <v-col
          cols="12"
          sm="6"
        >
          <v-autocomplete
              v-model="provider_id"
              :hint="`${razon_social}`"
              :items="pwproviders"
              item-text="razon_social"
              item-value="id"
              label="Selecciona un Proveedor"  
              outlined
          ></v-autocomplete> 

          <!-- <v-combobox
            v-model="provider_id"
            :items="itemsType"
            label="Selecciona un Proveedor"
          ></v-combobox> -->
          <!-- <ejs-combobox v-model="tipo" :dataSource='dataItem' :fields='dataFields' placeholder='Seleccione un tipo' popupWidth="250px"> </ejs-combobox>  -->
        </v-col>      
      </v-row>

      <v-btn v-on:click="submit">Guardar <v-icon>save</v-icon></v-btn>
  </div>
</template>


<script>
import validations from '@/utils/validation';
import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import baseApi from '@/services/baseApi';

export default {
  name: "pwcollateproductadd",
  created(){
    this.changeBreadcrumbs({
        page:"Nuevo Collate Producto",
        title:"Agregar nuevo Proveedor al Producto de Pocket Wallet",
        subtitle:"primary",
        description:"Este formulario se agregará nuevo collate producto"
      });
    // this.getBreadcrumps(this.sword)
    this.getBreadcrumps()    
  },
  computed:{
    ...mapState(['currentUser',"pwproviders",'pwproducts'])    
  },     
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(){
      try{        
          this.showLoading({titulo:"Accediendo información",color:"primary"})        
          this.$store.dispatch('loadPWProviders');    
          this.$store.dispatch('loadPWProduct',this.sword);            

        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
        } 
    },
    submit: async function() {
      try{        
        this.showLoading({titulo:"Almacenando información",color:"warning"})
        //let branch_id=this.currentUser.branch_id;        
        const { provider_id,product_id, sku_provider, fprecio, path, shortener } = this;
        const baseURL=`https://www.pwallet.online/api/prices/create`;

        let response = await axios.post(baseURL,{ provider_id,product_id, sku_provider, fprecio, path, shortener });    

        console.log(response.data.error); 
        console.log(response.data.message); 
        if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
        }else{
                this.$swal("Error!",JSON.stringify(response.data.message), "error");                           
        }          
    

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      }              
    }
  },
  data: function() {
    return {
      ...validations,
       sword:'',
       id:'',
       description:'',
       metrica: '',
       canasta_basica:0, 
       provider_id:"16",       
       itemsType: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '8',
          '9',
          '11',
          '16',
          '17',
          '18',
          '21',
          '22',
          '23',
       ],       
    };                  
  }
}

</script>