<template>
  <div id="ProductCreate">
    <!-- success msg component 
    <Success :msg="msg" v-show="success" />
    <ProductForm :user="user" @submit-form="saveUser" hasCustomer=true buttonText="Guardar" />
     -->
    <ProductForm :user="user" :submitForm="addRow" buttonText="Guardar" hasReset=true hasProduct=true hasProductCreate=true hasDescription=true />
  </div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import ProductForm from "@/components/ProductForm";
//import Success from "./Success";
import baseApi from '@/services/baseApi';
import router from '@/router';

export default {
  name: "ProductCreate",
  computed:{
    ...mapState(['currentUser']),    
  },
  components: {
    ProductForm,
    //Success,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      success: false,
      msg: "",
    };
  },
  methods: {
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    async addRow(ProductInfo){
      this.showLoading({titulo:"Almacenando información",color:"warning"})
      // alert("Agregar Product");
      // alert (ProductInfo.quote_id);
      // alert (ProductInfo.pricelist_id);
      // alert (ProductInfo.cantidad);
      // alert (ProductInfo.price);
      try{         
        let branch_id=this.currentUser.branch_id;
        let sku=ProductInfo.sku;
        let modelo=ProductInfo.modelo;
        let clave_sat=ProductInfo.clave_sat;
        let brand_id=ProductInfo.brand_id;
        let prodcategory_id=ProductInfo.prodcategory_id;
        let ctonline_id=ProductInfo.ctonline_id;
        let syscom_id=ProductInfo.syscom_id;
        let stock_syscom=ProductInfo.stock_syscom;
        let line_id=ProductInfo.line_id;
        let description=ProductInfo.description;    
        let measurement_id=ProductInfo.measurement_id;        
        let canasta_basica=ProductInfo.canasta_basica;        
        let filename=ProductInfo.filename;        
        let type_article=ProductInfo.type_article;
        let notes=ProductInfo.notes;     
        let famcategory_id=ProductInfo.famcategory_id;
        let subfamily_id=ProductInfo.subfamily_id;
        let price=ProductInfo.price;    
        let supplier_id='1';
        let warehouse_id='1';
        let iva='';
        let iva_referencia='';            
        // const { id, branch_id, supplier_id, warehouse_id, product_id, famcategory_id, price,iva,iva_referencia } = this;
        await baseApi().post(`/precios`,JSON.stringify({ branch_id, supplier_id,warehouse_id, famcategory_id, subfamily_id,price,iva,iva_referencia,sku,modelo, clave_sat, prodcategory_id, line_id,syscom_id, stock_syscom,ctonline_id,brand_id, description, measurement_id, canasta_basica, filename, type_article, notes })).then((response)=>{       
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              // this.$swal("Error!",response.data.message, "error");
              this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
            }                                    
          });
      }catch(error){
        if (error.message=='Request failed with status code 500') {
          this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
        }else if(error.message=='Request failed with status code 401'){
          this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
          router.push('/login');
        }else{
          this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
        }
      }  finally{
        this.hiddenLoading()
      }
    },
  },
};
</script>