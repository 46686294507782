<template>
  <div id="ProductQuoteCreate">
    <!-- success msg component 
    <Success :msg="msg" v-show="success" />
    <ProductForm :user="user" @submit-form="saveUser" hasCustomer=true buttonText="Guardar" />
     -->
    <ProductForm :user="user" :submitForm="addRow" buttonText="Guardar" hasQuote=true />
    <!--<ProductForm :submitForm="DetailProduct"  buttonText="Guardar"   />-->

  </div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import ProductForm from "@/components/ProductForm";
//import Success from "./Success";
import baseApi from '@/services/baseApi';


export default {
  name: "ProductQuoteCreate",
  computed:{
    ...mapState(['currentUser']),    
  },
  components: {
    ProductForm,
    //Success,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      success: false,
      msg: "",
    };
  },
  methods: {
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    async addRow(ProductInfo){
      //alert(this.rows[0].quote_id);
      // alert("Agregar Product");
      // alert (ProductInfo.quote_id);
      // alert (ProductInfo.pricelist_id);
      // alert (ProductInfo.cantidad);
      // alert (ProductInfo.price);
      try{ 
        this.showLoading({titulo:"Almacenando información",color:"warning"})
        // let quote_id=ProductInfo.quote_id;
        let quote_id=this.$route.query.quoteid; //this.rows[0].quote_id
        let pricelist_id=ProductInfo.pricelist_id;
        let price=ProductInfo.price;
        let posicion=ProductInfo.posicion;
        let cantidad=ProductInfo.cantidad;
        // const { quote_id,pricelist_id,price,cantidad } = this;
        //let datos=await baseApi().post();
        baseApi().post(`/detallecotizacion`,JSON.stringify({ quote_id,pricelist_id,posicion,price,cantidad })).then((response)=>{
            if(!response.data.error){                            
              // this.$swal("Genial!","", "success");
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("COTIZACION: "+response.data.message);
        });
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      } 
    },
  },
};
</script>