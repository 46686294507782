<template>
<div id="ExpensiveForm">  
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  > 
    <v-row>              
        <v-col cols="12" sm="6" md="4">                                                                                
        </v-col>
        <v-col cols="12" sm="6" md="4">                                                            
        </v-col>
        <!-- <v-col
                cols="12"
                sm="12"
                md="12"
        >
                <v-text-field
                  v-model="expensiveInfo.branch_id" 
                  value="1"
                  label="Sucursal"
                  :rules="[
                    required('sucursal')
                  ]"       
                ></v-text-field>                
        </v-col>             -->
        <v-col cols="12">
                <!-- <v-text-field
                  v-model="expensiveInfo.categoryexpensive_id"
                  label="Categoría"
                  :rules="[
                    required('categoría')
                  ]"       
                ></v-text-field>                 -->
                <v-autocomplete
                  v-model="expensiveInfo.categoryexpense_id"
                  :items="categoryExpensives"
                  item-text="name"
                  item-value="id"
                  label="Selecciona una Categoría"  
                  outlined          
                ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6"  md="6"
              >
                <v-text-field
                    v-model="expensiveInfo.price"
                    label="Precio"
                    :rules="[
                      required('precio')
                    ]"       
                />
        </v-col>
              
        <v-col cols="12" sm="6">
                 

                <v-date-picker 
                v-model="expensiveInfo.date_buy" 
                locale="es-mx"
                no-title             
              >
              <!-- full-width -->
              </v-date-picker>    
              <v-text-field
                  v-model="expensiveInfo.date_buy"
                  label="Fecha Gasto"
                  readonly
                  :rules="[
                    required('Fecha del Gasto')
                  ]"       
                />         
         
        </v-col>
        
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="expensiveInfo.notes"
                  label="Notas"
                />
              </v-col>
  </v-row>        

  <v-btn
          color="error"
          class="mr-4"
          @click="reset"
        >
          <v-icon>cleaning_services</v-icon> Reset Form
        </v-btn>        
  <v-btn
          color="primary"
          class="mr-4"
          @click="submitForm(expensiveInfo)" 
  >
        <v-icon>save</v-icon> {{buttonText}}
  </v-btn>        
  </v-form>
</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import validations from '@/utils/validation';

export default {
  name: 'ExpensiveForm',  
  components: {
  },  
  computed:{
    ...mapState(['categoryExpensives'])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async getPrice2(){      
      try{       
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        // this.$store.dispatch('loadPricelists',"All"); 
        this.attribute.sword="All";
        await this.$store.dispatch('loadCategoryExpensives','All');
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.expensiveInfo.branch_id=currentUser.branch_id;       
      }
    },
    reset () {
        this.$refs.form.reset()
    }
  },
  props:["submitForm","buttonText","hasSalesOrder","hasQuote","hasDescription"],
  data(){
     return{
        valid: false,
        ...validations,
        attribute: {sword: '',limit: 27},
        // pricelists:[],
        expensiveInfo:{ 
          branch_id: "",
          categoryexpense_id: '',
          price: '',
          date_buy:new Date().toISOString().substr(0,10),
          notes:''
        }
     }
  },
  created(){
    this.getPrice2()    
  }  
}
</script>          
