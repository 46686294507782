<template>
  <div class="quotes"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row>
            <v-col cols=12  sm=12  xs=12 md=12 lg=12 xl="12" >
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>



<!-- 
<v-container>
  <v-layout >
    <v-flex xs6 md6 lg6>

       <v-data-table
        :headers="theader"
        :items="desserts"
        :items-per-page="15"
        class="elevation-1"
      ></v-data-table>   
--> 

<v-card
    flat
    class="py-12"
  >
    <v-card-text >
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
        
          mandatory
        >
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <router-link to="/quote-add">
                <v-btn                  
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </router-link>
              </template>
              <span>Agregar Nueva Devoluciones</span>
            </v-tooltip>
        </template>    
        
           <!-- Dialog para agregar Cliente --> 
           <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog2"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="darken-2"
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>group_add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Cliente</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog2 = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <CustomerCreate :user="user" />
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Nuevo Cliente</span>
            </v-tooltip>
        </template> 
        <!-- fin dialgo Cliente -->

        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="getReturn" 
                  color="blue darken-5"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>refresh</v-icon>
                </v-btn>
              </template>
              <span>Refrescar la Pagina</span>
            </v-tooltip>
        </template>
        
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
        </template>
          
          
        </v-btn-toggle>
      </v-row>
  </v-card-text>
</v-card>

  <!-- Busqueda Return -->
  <ReturnItem :returns="comeback"  />
  <!-- FIN Busqueda Return -->



  </div>
</template>


<script>
import{ mapMutations, mapState } from "vuex";
import CustomerCreate from '@/components/CustomerCreate.vue'
import baseApi from '@/services/baseApi';
import ReturnItem from "@/components/ReturnItem";


export default {
  name: 'comeback',  
  computed:{
      ...mapState(['comeback','currentUser','user'])    
  },
  components: {    
      CustomerCreate,
      ReturnItem

  },  
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]), 
   
    
   
    async getReturn(sword){
      this.showLoading({titulo:"Accediendo información",color:"primary"})
      try{         
        this.attribute.sword=sword       
        await this.$store.dispatch('loadComeback',this.attribute);
        this.showLoading({titulo:"Accediendo información",color:"primary"})
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    async getBreadcrumps(sword){   
      this.getReturn(sword);
    },    
   
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})  
          // let datos = baseApi().post(`/devoluciones/update/${row.id}`,JSON.stringify(row));   
          // console.log(datos);
          baseApi().post(`/devoluciones/update/${row.id}`,JSON.stringify(row)).then((response)=>{
          // baseApi().get(`/cotizaciones/copySales/${id}`).then((response)=>{
              if(!response.data.error){                            
                  this.$swal("Genial!",response.data.message, "success");
                  this.getReturn();
              }else{
                  this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("Transferir COTIZACION: "+response.data.message);
          });                                                                                                 
        }catch(error){
          console.log(error);          
        }  finally{
          this.hiddenLoading()
        } 
    },
    updateRowProject(row){
      try{        
        this.showLoading({titulo:"Accediendo información",color:"warning"})                
        baseApi().post(`/devoluciones/updateFolio/${row.project_id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
              this.getReturn();
            }else{
              // this.$swal("Error!",response.data.message, "error");
              this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
            }                                    
            console.log("RETURN: "+response.data.message);
        });         
      }catch(error){
        console.log(error);        
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    }

  },
  data(){
     return{
      dialog2:false, //bug se cicla
      dialogNotes:false,
      editRowProjectID:false,
      attribute: {sword: '',limit: 500},      
      msg:0,
      sword:"",
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Devoluciones",
        title:"Lista de Devoluciones",
        subtitle:"primary",
        description:"En esta lista encontrará todos las devoluciones dadas de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  },
}
</script>