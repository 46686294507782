<template>
  <div class="expenses"> 
    <template>
      <v-container class="grey lighten-5">
        <v-row >
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <h5>Buscar:</h5>
            <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>


    <!-- FIN Dialog confirmación -->
<v-card
    flat
    class="py-12"
  >
    <v-card-text >
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
          v-model="toggle_exclusive"
          mandatory
        >           
        
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Producto / Servicio</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container>      
                            <ExpensiveForm :submitForm="AddExpensive" buttonText="Guardar" /> 
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Producto</span>
            </v-tooltip>
        </template>   
        <!-- Dialog para agregar --> 
        <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <router-link to="/customer-best">                      
                    <v-btn                  
                      color="darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>search</v-icon>
                    </v-btn>
                  </router-link>
                  </template>
                  <span>Consultar Gastos Fijos </span>
                </v-tooltip>
        </template> 

        
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="getExpensive('')" 
                  color="primary darken-5"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>refresh</v-icon>
                </v-btn>
              </template>
              <span>Refrescar Página</span>
            </v-tooltip>
        </template>

        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
        </template>
          
          
        </v-btn-toggle>
      </v-row>
    </v-card-text>
  </v-card>

  <v-row>
      <v-col cols=12 sm=12 md=12 lg=12>   
        <table>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Total IVA:</b> {{ totaliva | currency }}</td> 
          </tr>
        </table>
      </v-col>
  </v-row> 

<!-- Busqueda Expensives -->
<ExpensiveItem :expensives="expensives"  />
<!-- FIN Busqueda Expensives -->  
      


  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import ExpensiveForm from '@/components/ExpensiveForm.vue'
import ExpensiveItem from '@/components/ExpensiveItem.vue'
import baseApi from '@/services/baseApi';


export default {
  name: 'expenses',
  components: {
      //FieldSearch,
      ExpensiveForm,
      ExpensiveItem
  },    
  computed:{
    ...mapState(['currentUser','expensives'])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]), 
    async getExpensive(sword){
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadExpensives',sword);       
        
        for (var i = 0; i < this.expensives.length; i+=1) { 
          //console.log('IVA:'+this.expensives[i].iva_expenses)
          this.totaliva+=this.expensives[i].iva_expenses;              
        }
        // let datos = await baseApi().get(`/gastos/getall?word=${sword}`);
        // console.log(datos);
        
        // if(datos.data.length >0){
        //   this.rows=datos.data          
        //   this.desserts=datos.data          
        // }else{
        //   this.rows="Sin resultados";
        // }

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    async getBreadcrumps(sword){
      this.changeBreadcrumbs({
        page:"Gastos",
        title:"Lista de Gastos",
        subtitle:"primary",
        description:"En esta lista encontrará todos los gastos dados de alta en el sistema desde el principio de los tiempos"
       });
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];
      this.getExpensive(sword);      
    },
    async AddExpensive(ExpensiveInfo){
      // alert("Agregar Product");
      // alert (ExpensiveInfo.quote_id);
      // alert (ExpensiveInfo.pricelist_id);
      // alert (ExpensiveInfo.cantidad);
      // alert (ExpensiveInfo.price);
      try{ 
        this.showLoading({titulo:"Almacenando información",color:"warning"})
        let branch_id=this.currentUser.branch_id;
        let categoryexpense_id=ExpensiveInfo.categoryexpense_id;        
        let price=ExpensiveInfo.price;
        let date_buy=ExpensiveInfo.date_buy;
        let notes=ExpensiveInfo.notes;        
        // const { salesorder_id,pricelist_id,price_other,cantidad } = this;        
        // let datos=await baseApi().post();        
        baseApi().post(`/gastos`,JSON.stringify({ branch_id,categoryexpense_id,price,date_buy,notes })).then((response)=>{
            if(!response.data.error){                            
              // this.$swal("Genial!","", "success");
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
            }                                    
            // console.log("VENTA: "+response.data.message);
        });
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      } 
    }, 

  },
  data(){
     return{
      dialog:false,
      notes:'',
      // currentUser:[],
      toggle_exclusive:'',
      sword:"All",
      totaliva:0,
      sfecha:new Date().toISOString().substr(0,10),
      rows: [],
    }
  },
  created(){
    this.getBreadcrumps(this.sword)    
  }
}
</script>
