<template>
  <div class="campaignadd">
      <div>
        <h5>Nombre de Campaña</h5>
        <input type="text" v-model="name" />
      </div>                      
      <div>
        <h5>Descripción</h5>
        <input type="text" v-model="description" />
      </div>                      
      <div>
        <h5>Fecha Envio</h5>
        <input type="text" v-model="date_send" />
      </div>  
      
      <v-combobox
          v-model="type"
          :items="itemsType"
          label="Selecciona un Tipo"
      ></v-combobox>
      <!-- <ejs-combobox v-model="type" :dataSource='dataItem' :fields='dataFields' placeholder='Seleccione una categoría' popupWidth="250px">
      </ejs-combobox>                       -->
      
      <div>
        <h5>Notas</h5>
        <input type="text" v-model="notes" />
      </div> 

      <v-btn v-on:click="submit">Guardar <v-icon>save</v-icon></v-btn>
  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations } from "vuex";
import baseApi from '@/services/baseApi';

export default {
  name: "campaignadd",  
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(){
      
    },
    submit: async function() {
      try{        
        this.showLoading({titulo:"Almacenando información",color:"warning"})        
        const { name,description,date_send,type, notes } = this;                
        baseApi().post(`/campaigns`,JSON.stringify({ name,description,date_send,type, notes })).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("COMPRAS: "+response.data.message);
        });                                    
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      }      
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Nueva Campaña",
        title:"Agregar Nueva Campaña",
        subtitle:"primary",
        description:"Este formulario se agregará nueva campaña"
    });
    this.getBreadcrumps()    
  },
  data: function() {
    return {
       editFriend: null,
       friends: [],
       id:'',
       description: '',
       name: '',
       type:'Leads',
       date_send:new Date().toISOString().substr(0,10)+" 00:00:00",
       notes:'', 
      itemsType: [
          'Leads',
          'Frecuentes',
      ],       
    };                  
  }
}

</script>