<template>
  <div class="purchaseadd">
    <template>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=8 lg=10>
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=4 lg=2>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date_buy"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_buy"
                  label="Fecha de Compra"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_buy"
                locale="es-mx"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date_buy)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
            <v-text-field
              v-model="title"
              label="Título"
              type="text"          
              :rules="[
                required('title')
              ]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
            <v-autocomplete
                    v-model="supplier_id"
                    :hint="`${suppliers.id} - ${suppliers.razon_social}`"
                    :items="suppliers"
                    item-text="razon_social"
                    item-value="id"
                    label="Selecciona un Proveedor"  
                    outlined
            ></v-autocomplete>            
          </v-col>
        </v-row>    
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-switch
              v-model="iva"
              label="IVA"
            ></v-switch>             
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-autocomplete
                    v-model="currency_id"
                    :items="currencies"
                    item-text="tipo_cambio"
                    item-value="id"
                    label="Selecciona un tipo de cambio*"
                    outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-text-field
                      v-model="num_pedimento"
                      label="Número de pedimento"
                  />              
          </v-col>
        </v-row>               
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-text-field
                      v-model="comision"
                      label="Comisión %"
                  />
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-text-field
                      v-model="shipping_cost"
                      label="Costo de Envío"
            />         
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-text-field
                      v-model="other_cost"
                      label="Otro Costo"
            />
          </v-col>
        </v-row>   
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
            <v-textarea
              v-model="notes"
              filled
              auto-grow
              label="Escribe una Nota"
              rows="4"
              row-height="30"
              shaped
            ></v-textarea>        
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
            <h5>Agregar Productos</h5>
            <table>
              <thead class="thead-dark">
                <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
              </thead>
              <tr v-for="(invoice_product, k) in invoice_products" :key="k">              
                  <td scope="row" class="trashIconContainer">
                      <v-btn icon @click="deleteRow(k, invoice_product)">
                        <v-icon color="red">delete_outline</v-icon>
                      </v-btn>                  
                  </td>              
                  <td>        
                    <v-autocomplete
                      v-model="invoice_product.pricelist_id"
                      :items="priceslist"
                      item-text="description"
                      item-value="id"
                      label="Selecciona un producto*"
                      required
                      outlined
                      ></v-autocomplete>                      
                  </td>
                  <td>
                      <v-text-field
                          v-model="invoice_product.posicion"
                          class="form-control"
                          label="Posición"
                          :rules="[
                            onlyNumeric('posicion')
                          ]"
                          required
                      ></v-text-field>                    
                  </td>
                  <td>                    
                      <v-text-field
                          v-model="invoice_product.price"
                          @change="calculateLineTotal(invoice_product)"
                          class="form-control text-right"
                          type="number"
                          min="0" 
                          step=".01"
                          label="Precio"
                          prefix="$"
                          :rules="[
                            required('precio'),
                            onlyNumeric('precio')
                          ]"
                          required
                      ></v-text-field>
                  </td>
                  <td>
                      <v-text-field
                          v-model="invoice_product.cantidad"
                          @change="calculateLineTotal(invoice_product)"                      
                          class="form-control text-right"
                          type="number"
                          min="0" 
                          step=".01"
                          label="Cantidad"
                          :rules="[
                            onlyNumeric('cantidad')
                          ]"
                          required
                      ></v-text-field>     
                      <span class="red" @click="disminuir(0)"><v-icon>remove</v-icon></span>
                      <span class="primary" @click="aumentar(0)"><v-icon>add</v-icon></span>             
                  </td>
                  <td>
                      <v-text-field
                          v-model="invoice_product.line_total"
                          readonly
                          class="form-control text-right"
                          type="number"
                          min="0" 
                          step=".01"
                          label="Total"
                          :rules="[
                            onlyNumeric('cantidad')
                          ]"
                          required
                      ></v-text-field>                  
                  </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right"><b>Subtotal:</b></td>
                <td class="text-right">${{invoice_subtotal}}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right"><b>IVA:</b></td>
                <td class="text-right">{{invoice_tax}}%
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right"><b>Total:</b></td>
                <td class="text-right">${{invoice_total}}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
            <v-btn icon class="btn" @click="addNewRow" >
                <v-icon>add</v-icon>                    
            </v-btn>    
            <v-btn v-on:click="submit"  color="success"><v-icon>save</v-icon></v-btn>                      
          
              <!-- <v-btn icon class="btn" @click="saveInvoice" >
                  <v-icon>save</v-icon>                    
              </v-btn>   -->          
          </v-col>
        </v-row>              
      </v-form>
    </template>
  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import validations from '@/utils/validation';
import baseApi from '@/services/baseApi';

export default {
  name: "purchaseadd", 
  computed:{
    ...mapState(['suppliers','currentUser','priceslist','currencies','fields']),
    cantidad:{
      get() {
        return this.fields[0].cantidad;
      },
      set(){}
      // set(value) {
      //   value=0
      //   this.aumentar(value);
      // }
    }
  },   
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs",'aumentar','disminuir']), 
    saveInvoice() {
            console.log(JSON.stringify(this.invoice_products));
    },
    calculateTotal() {
            var subtotal, total;
            subtotal = this.invoice_products.reduce(function (sum, product) {
                var lineTotal = parseFloat(product.line_total);
                if (!isNaN(lineTotal)) {
                    return sum + lineTotal;
                }
            }, 0);

            this.invoice_subtotal = subtotal.toFixed(2);

            total = (subtotal * (this.invoice_tax / 100)) + subtotal;
            total = parseFloat(total);
            if (!isNaN(total)) {
                this.invoice_total = total.toFixed(2);
            } else {
                this.invoice_total = '0.00'
            }
    },
    calculateLineTotal(invoice_product) {
            var total = parseFloat(invoice_product.price) * parseFloat(invoice_product.cantidad);
            if (!isNaN(total)) {
                invoice_product.line_total = total.toFixed(2);
            }
            this.calculateTotal();
    },
    deleteRow(index, invoice_product) {
            var idx = this.invoice_products.indexOf(invoice_product);
            console.log(idx, index);
            if (idx > -1) {
                this.invoice_products.splice(idx, 1);
            }
            this.calculateTotal();
    },
    addNewRow() {
            this.invoice_products.push({
                product_id: '',
                posicion: this.invoice_products.length+1,
                price: '',
                cantidad: '',
                line_total: 0
            });
    },   
    async getBreadcrumps(){    
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"});
        this.attribute.sword="All",
        await this.$store.dispatch('loadPricelists',"All");
        //await this.$store.dispatch('loadProducts',"All");
        await this.$store.dispatch('loadSuppliers',this.attribute);  
        await this.$store.dispatch('loadCurrencies',this.attribute);      
        //await this.$store.dispatch('loadCustomers',this.attribute);
        // let datos=await baseApi().get(`/clientes/getall?branch_id=1`);
      }catch(error){
        //console.log(error);
      } finally{
        this.hiddenLoading()
      } 
    },    
    submit: async function() {
      let a=this.$refs.form.validate();
      if(a==true){
        try{        
          this.showLoading({titulo:"Almacenando información",color:"warning"})  
          let branch_id=this.currentUser.branch_id;                    
          let detailpurchases=this.invoice_products;
          //alert (branch_id);                  

          const { supplier_id,currency_id, title, num_pedimento,comision,shipping_cost,other_cost,date_buy, iva, notes } = this; 
          baseApi().post(`/compras/createAll`,JSON.stringify({ branch_id, supplier_id, currency_id,title,num_pedimento,comision,shipping_cost, other_cost,date_buy,iva, notes,detailpurchases })).then((response)=>{
            if(!response.data.error){                                      
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("COMPRAS: "+response.data.message);
          });                   
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        }   
      }         
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Nueva Compra",
        title:"Agregar Nueva Compra",
        subtitle:"primary",
        description:"Este formulario se agregará nuevas compras"
    });
    this.getBreadcrumps()    
  },
  data: function() {
    return {
      attribute: {sword: '',limit: 27},
      valid: true,
      ...validations,
      id:'',
      supplier_id:'',
      currency_id:'14',
      other_cost:'0',
      shipping_cost:'0',
      comision:'2.99',
      num_pedimento:'0',
      title:'',
      iva:'1',
      modal:'',
      date_buy: new Date().toISOString().substr(0,10),
      notes:'',
      invoice_subtotal: 0,
      invoice_total: 0,
      invoice_tax: 16,
      invoice_products: [{
                product_id: '',
                posicion: 1,
                price: '',
                cantidad: '',
                line_total: 0
      }],
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: 'Producto', value: 'index' },
        {
          text: 'Posición',
          align: 'start',
          sortable: false,
          value: 'posicion',
        }, 
        { text: 'Precio', value: 'price' },        
        { text: 'Cantidad', value: 'cantidad' },        
        { text: 'Total', value: 'total' },        
      ],
    };                  
  },
}

</script>
<style>
  .v-date-picker-table {    
    height: auto;
  }
</style>