var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"MessageForm"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}}),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Titulo*","rules":[
                    _vm.required('titulo')
                  ]},model:{value:(_vm.messageInfo.title),callback:function ($$v) {_vm.$set(_vm.messageInfo, "title", $$v)},expression:"messageInfo.title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Descripción*","rules":[
                    _vm.required('descripción')
                  ]},model:{value:(_vm.messageInfo.name),callback:function ($$v) {_vm.$set(_vm.messageInfo, "name", $$v)},expression:"messageInfo.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-combobox',{attrs:{"items":_vm.itemsTipo,"label":"Seleccione un Tipo"},model:{value:(_vm.messageInfo.tipo),callback:function ($$v) {_vm.$set(_vm.messageInfo, "tipo", $$v)},expression:"messageInfo.tipo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.hasDateRange)?_c('v-text-field',{attrs:{"label":"Fecha Inicio*","rules":[
                    _vm.required('Fecha Inicio')
                  ]},model:{value:(_vm.messageInfo.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.messageInfo, "fecha_inicio", $$v)},expression:"messageInfo.fecha_inicio"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.hasDateRange)?_c('v-text-field',{attrs:{"label":"Fecha Fin*","rules":[
                      _vm.required('Fecha Fin')
                    ]},model:{value:(_vm.messageInfo.fecha_fin),callback:function ($$v) {_vm.$set(_vm.messageInfo, "fecha_fin", $$v)},expression:"messageInfo.fecha_fin"}}):_vm._e()],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.submitForm(_vm.messageInfo)}}},[_c('v-icon',[_vm._v("save")]),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("cleaning_services")]),_vm._v(" Reset Form ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }