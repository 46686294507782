<template>
  <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
    <v-card>
      <v-img
          height="200px"
          src="https://www.ionoff.com.mx/wp-content/uploads/2021/03/20287026-scaled.jpg"
      >
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
          >
              <v-spacer></v-spacer>
                <v-menu>
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="">
                        <v-btn
                          color="white"
                          icon
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn v-on:click="editRow = supplier.id" icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <a v-bind:href="url(supplier.id)">
                          <v-icon>visibility</v-icon>
                        </a>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn @click="updateMailchimp(supplier.mailchimp_id)" class="yellow">
                          <v-icon >refresh</v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn @click="addWooOne(supplier.id)" class="blue">
                          <v-icon >add</v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn @click="addMautic(supplier.id)" class="purple">
                          <v-icon >add</v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
            </v-app-bar>

            <v-card-title class="white--text ">
              <p class="ml-3">
                {{supplier.razon_social}}
              </p>
          </v-card-title>
        </v-img>



          <v-card-text>
            <div class="font-weight-bold ml-8 mb-2">
              # {{supplier.id}} <br>
              RFC: {{supplier.rfc}} <br>
              Domicilio: {{supplier.address}} <br>
              Num. Ext: {{supplier.num_ext}} <br>
              Num. Int: {{supplier.num_int}} <br>
              C.P.: {{supplier.cp}} <br>
              Compañía: {{supplier.company}}<br>
              Telefono {{supplier.phone}} <br>
              Movil:
              <v-btn
                  v-if='supplier.mobile != null'
                  v-bind:href="urlwhats+supplier.mobile"
                  target="_blank"
              >     {{supplier.mobile}}
              </v-btn>
              <br>
              Email {{supplier.email}} <br>
            </div>
            <div v-if="editRow === supplier.id">
              <input v-on:keyup.13="updateRow(supplier)" placeholder="Sucursal id" v-model="supplier.branch_id" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="Razon Social" v-model="supplier.razon_social" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="RFC" v-model="supplier.rfc" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="Email" v-model="supplier.email" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="Domicilio" v-model="supplier.address" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="Núm. Ext." v-model="supplier.num_ext" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="Núm. Ext." v-model="supplier.num_int" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="C.P." v-model="supplier.cp" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="Teléfono" v-model="supplier.phone" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="Celular" v-model="supplier.mobile" />
              <input v-on:keyup.13="updateRow(supplier)" placeholder="Notas" v-model="supplier.notes" />
            </div>
          </v-card-text>
          <v-app-bar>
            <div v-if="supplier.ver == '1'">
              <v-chip
              class="ma-2"
              color="green"
              label
              text-color="white"
              >
                Activo
              </v-chip>
            </div>
            <div v-else>
              <v-chip
              class="ma-2"
              color="default"
              label
              text-color="white"
              >
                Desactivado
              </v-chip>
            </div>
            <div v-if="supplier.notes != null && supplier.notes != ''">
              <div class="text-center">
                <v-dialog
                  v-model="dialog"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="red lighten-2"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      ver nota
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline grey lighten-2">
                      Notas
                    </v-card-title>
                    <v-card-text>
                      {{supplier.notes}}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <div v-if="editRow === supplier.id">
              <v-btn v-on:click="updateRow(supplier)" class="green">Actualizar</v-btn>
            </div>
          </v-app-bar>
        </v-card>
  </v-col>
</template>

<script>
import{ mapMutations } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
// // import FieldSearch from '@/components/FieldSearch.vue'
// import FieldSearch2 from '@/components/FieldSearch2'
// import PricelistItem from '@/components/PricelistItem'
// import ProductForm from "@/components/ProductForm";
import baseApi from '@/services/baseApi';

export default {
  props:{
    supplier:{
      type:Object,
      required:true
    }
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    url: function (href){
        return 'detalleventascliente?customerid='+ href
    },
    async updateMailchimp(href){
    //console.log(href);
     try{
       //console.log(this.sword2);
       this.showLoading({titulo:"Actualizando información Mailchimp",color:"warning"})
       let datos = await baseApi().get(`/mailchimps/updatemembers/` + href);
       //console.log(datos);
       //console.log(datos.data.success.length);
       if(datos.data[0].success.length >0){
         this.rdesc=datos.data[0].success;
         this.rtitle="success";
       }else{
         this.rdesc="Sin resultados";
         this.rtitle="error";
       }
      }catch(error){
       //console.log(error);
      }  finally{
       this.hiddenLoading()
       this.$swal("Genial!", this.rdesc, this.rtitle);
      }
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                               
          baseApi().post(`/proveedores/update/${row.id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("Proveedor: "+response.data.message);
          });         
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    }
  },
  data(){
    return{
      editRow: null,
      urlwhats:"https://api.whatsapp.com/send?phone=52",
    }
  }
}
</script>
