<template>
  <div class="crypto">
    <div class="row">
      <template>
       <v-container class="grey lighten-5">
          <v-row
            :class="sm-12"
          >
            <v-col>
              <div class="input-field col s6">
                <i class="material-icons prefix">search</i>
                <input type="text" placeholder="Escribe un título" v-model="textSearch"  @keyup="searchCoin()" autofocus>
              </div>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

      <table class="table table-hover table-dark text-light">
        <thead>
          <tr>
            <th v-for="(title, index) in titles" :key="index">
              {{ title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(coin, index) in filteredCoins" :key="coin.id"
              :class="[
                coin.price_change_percentage_24h > 0
                  ? 'success'
                  : '',
              ]"
            >
            <td class="text-muted">{{ index }}</td>
            <td>
              <img :src="coin.image" :alt="coin.name" style="width: 2rem" class="me-2" />
              <span>
                {{ coin.name }}
              </span>
              <span class="ms-2 text-muted text-uppercase">
                {{ coin.symbol }}
              </span>
            </td>
            <td>${{ coin.current_price.toLocaleString() }}</td>
            <td
              :class="[
                coin.price_change_percentage_24h > 0
                  ? 'success'
                  : 'red',
              ]"
            >
              {{ coin.price_change_percentage_24h }}%
            </td>
            <td>$ {{ coin.total_volume.toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import{ mapMutations } from "vuex";

export default {
  name: "crypto",
  components: {},
  created(){
    this.changeBreadcrumbs({
        page:"CryptoMarket",
        title:"Lista de Cryptomonedas",
        subtitle:"primary",
        description:"En esta lista encontrará todos los cyrptomonedas dados de alta en el sistema desde el principio de los tiempos"
    });
    //this.getBreadcrumps(this.sword)    
  },
  data() {
    return {
      coins: [],
      filteredCoins: [],
      titles: ["#", "Coin", "Price", "Price Change", "24h Volume"],
      textSearch: "",
    };
  },
  async mounted() {
    const res = await fetch(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=500&page=1&sparkline=false"
    );
    const data = await res.json();
    this.coins = data;
    this.filteredCoins = data;
  },
  methods: {
    ...mapMutations(["changeBreadcrumbs"]),    
    searchCoin() {
      this.filteredCoins = this.coins.filter(
        (coin) =>
          coin.name.toLowerCase().includes(this.textSearch.toLowerCase()) ||
          coin.symbol.toLowerCase().includes(this.textSearch.toLowerCase())
      );
    },
  },
};
</script>

<style>
</style>