<template>
  <div class="projects"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row>
            <v-col cols=12  sm=12  xs=12 md=12 lg=12 xl="12" >
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>



<!-- 
<v-container>
  <v-layout >
    <v-flex xs6 md6 lg6>

       <v-data-table
        :headers="theader"
        :items="desserts"
        :items-per-page="15"
        class="elevation-1"
      ></v-data-table>   
--> 

<v-card
    flat
    class="py-12"
  >
    <v-card-text >
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
        
          mandatory
        >
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <router-link to="/quote-add">
                <v-btn                  
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </router-link>
              </template>
              <span>Agregar Nueva Proyecto</span>
            </v-tooltip>
        </template>    
        
           <!-- Dialog para agregar Cliente --> 
           <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog2"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="darken-2"
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>group_add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Cliente</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog2 = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <CustomerCreate :user="user" />
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Nuevo Cliente</span>
            </v-tooltip>
        </template> 
        <!-- fin dialgo Cliente -->

        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="getProjects('')" 
                  color="primary darken-5"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>refresh</v-icon>
                </v-btn>
              </template>
              <span>Refrescar Página</span>
            </v-tooltip>
        </template>        
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
        </template>
          
          
        </v-btn-toggle>
      </v-row>
  </v-card-text>
</v-card>

  <v-row>
    <v-col cols="12" sm="12" md="12" lg="12"    
        v-if="projects.length > 0"
    >
        <span class="right"><b>{{ projects.length }}</b> registro(s) encontrados <br></span>

      <table class="table table-striped">
          <thead class="thead-dark">
            <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
          </thead>
          <tr v-for="(row, index ) of projects" :key="row.id">          
            <td>
              <!-- 
              <button v-on:click="deleteFriend(row.id, i)">x</button>
              -->
              <v-btn icon v-on:click="editRow = row.id">
                <v-icon>edit</v-icon>
              </v-btn>
              <a v-bind:href="url(row.id)">
                <v-icon>visibility</v-icon>
              </a>             
                    
            </td>    
            <td>{{index+1}}</td>

            <td>
              <div v-if="editRow === row.id">
                <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.branch_id" /> -->
             
                <v-autocomplete
                  v-model="row.employee_id"
                  :hint="`${employees.id} - ${employees.fullname}`"
                  :items="employees"
                  item-text="fullname"
                  item-value="id"
                  label="Selecciona un Empleado"  
                  outlined
                ></v-autocomplete>
                <v-text-field
                            v-model="row.title"
                            v-on:keyup.13="updateRow(row)"
                            label="Título"
                />
            
                <v-text-field
                            v-model="row.description"
                            v-on:keyup.13="updateRow(row)"
                            label="Descripcion"
                />                    
                          
                <v-text-field
                            v-model="row.notes"
                            v-on:keyup.13="updateRow(row)"
                            label="Notas"
                />          
                <v-date-picker 
                  v-model="row.date_project" 
                  locale="es-mx"
                  no-title             
                >
                <!-- full-width -->
                </v-date-picker>    
                <v-text-field
                            v-model="row.date_project"
                            v-on:keyup.13="updateRow(row)"
                            readonly
                            label="Fecha de Pago"
                />

                <v-combobox
                  v-model="row.status" 
                  :items="itemsStatus"
                  label="Selecciona un Status"
                ></v-combobox>    
                <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
                <v-btn v-on:click="editRow=false" class="deep-dark">cerrar</v-btn>
              </div>
              <div v-else>        
                <h6>{{row.title}}</h6>
                <b>Proyecto: </b>{{row.date_project}} <br>
                <b>Registro: </b>{{row.created_at}}<br>
                <b>Actualización: </b>{{row.updated_at}}
              </div>
            </td>  
            <td>
              <div v-if="row.folio!=null">{{row.folio }} </div>  
              <div v-else>
                    <v-chip
                      class="ma-2"
                      color="deep-orange"
                      label
                      text-color="white"
                      >
                        <v-icon left>
                          mdi-label
                        </v-icon>
                        Sin Folio
                      </v-chip>
              </div>  
                
              <v-btn v-on:click="editRowProjectID = row.id" icon>
                    <v-icon>edit</v-icon>
              </v-btn>
              <div v-if="editRowProjectID === row.id">
                  <v-text-field
                                class="my-5 py-0"
                                v-model="row.folio"    
                                v-on:keyup.13="updateRow(row)"
                                label="Folio Proyecto"
                                placeholder="Escribe un folio"
                                rounded
                                outlined
                  ></v-text-field>
                  <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
                  <v-btn v-on:click="editRowProjectID=false" class="deep-black">Cerrar</v-btn>
              </div>
      </td>

            <td v-if="row.employee_id!=null">{{row.employee.firstname}} </td>  
            <td v-else>
              <v-chip
                class="ma-2"
                color="deep-orange"
                label
                text-color="white"
                >
                  <v-icon left>
                    mdi-label
                  </v-icon>
                  Sin Asignar
                </v-chip>
            </td>  
            <td>{{row.description}} <br> </td>  

            <td>        
              <div v-if="row.notes != null && row.notes != ''">   
                      <v-btn
                        color="red lighten-2"
                        dark
                        @click="getNote(row.notes)" 
                      >
                        ver nota
                      </v-btn>
              </div>
                
            </td>        
            <td>
              <div v-if="row.status == 'Terminado'">        
                <v-chip
                  class="ma-2"
                  color="green"
                  label
                  text-color="white"
                >
                  {{row.status}}
                </v-chip>
                </div>
                <div v-else-if="row.status == 'Cancelado'">        
                <v-chip
                  class="ma-2"
                  color="red"
                  label
                  text-color="white"
                >
                    {{row.status}}
                </v-chip>
                </div>
                <div v-else-if="row.status == 'Desarrollo'">        
                <v-chip
                  class="ma-2"
                  color="yellow"
                  label
                  text-color="black"
                >
                    {{row.status}}
                </v-chip>
                </div>                 
                <div v-else>
                <v-chip
                class="ma-2"
                color="default"
                label
                text-color="black"
                >
                  {{row.status}}
                </v-chip>
              </div>                                                                       
            </td>
          </tr>
        </table>          
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12" v-else  >
          <v-alert
            dense
            outlined
            type="error"
          >
            No hay registros encontrados con esta busqueda
          </v-alert>
    </v-col>
  </v-row> 
   
   <!-- DIALOG NOTAS -->
   <div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->

  </div>
</template>


<script>
import{ mapMutations, mapState } from "vuex";
import CustomerCreate from '@/components/CustomerCreate.vue'
import baseApi from '@/services/baseApi';

export default {
  name: 'projects',  
  computed:{
      ...mapState(['projects','employees','currentUser','user'])    
  },
  components: {    
      CustomerCreate,
  },  
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]), 
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    },
    url: function (href){
        return 'detalleproyecto?projectid='+ href
    },
    async getBreadcrumps(){
      this.getProjects(this.sword);
      this.getCustomer();
    }, 
    async getProjects(sword){
      // if (sword!='') this.attribute.sword=sword
      this.attribute.sword=sword
      try{                
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadProjects',this.attribute);
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    async getCustomer(){
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadEmployees',this.attribute);
      }catch(error){
        //console.log(error);
        this.$swal("Error!", "Necesita ingresar sus credenciales", "error");
      } finally{
        this.hiddenLoading()
      }   
      
     
    },   
    updateRow(row){
      try{        
        this.showLoading({titulo:"Accediendo información",color:"warning"})                
        baseApi().post(`/proyectos/update/${row.id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
              // this.getReturn();
            }else{
              // this.$swal("Error!",response.data.message, "error");
              this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
            }                                    
            console.log("RETURN: "+response.data.message);
        });         
      }catch(error){
        console.log(error);        
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    }
  },
  data(){
     return{
      dialog2:false, //bug se cicla
      dialogNotes:false,
      notes:'',      
      attribute: {sword: 'All',limit: 500},
      msg:0,
      sword:"",
      // customers:[],
      customer_id:77,
      editRow:null,
      editRowProjectID:null,
      itemsStatus: [
          'Proceso',
          'Desarrollo',
          'Terminado',
          'Cancelado',
      ],            
      theader: [
        { text: 'Acción', value: 'canasta_basica' },        
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descripcion', value: 'total_quote' },
        { text: 'Folio', value: 'total_quote' },
        { text: 'Asignado', value: 'total_quote' },
        { text: 'Descripcion', value: 'total_quote' },
        { text: 'Notas', value: 'notes' },
        { text: 'Status', value: 'status' },        
      ],
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Proyectos",
        title:"Proyectos",
        subtitle:"primary",
        description:"En esta lista encontrará todos los proyectos dados de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps() 

   
  },
}
</script>
<style>
  .v-date-picker-table {    
    height: auto;
  }
</style>