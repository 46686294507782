<template>
  <div class="paymentadd">
    <template>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-autocomplete
                    v-model="salesorder_id"
                    :items="salesorders"
                    item-text="id"
                    item-value="id"
                    label="Selecciona una venta*"
                    outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date_pay"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_pay"
                  label="Fecha de Compra"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_pay"
                locale="es-mx"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date_pay)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
                 
          </v-col>
        </v-row>    
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
                  
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-text-field
                      v-model="price"
                      label="Precio"
                  />              
          </v-col>
          <v-col cols=12 sm=12 xs=12 md=4 lg=4>
            <v-combobox
                    v-model="tipo" 
                    :items="itemsTipo"
                    label="Seleccione un Tipo"
                    :rules="[
                      required('tipo')
                    ]"       
                  ></v-combobox>     
          </v-col>
        </v-row>   
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
            <v-textarea
              v-model="notes"
              filled
              auto-grow
              label="Escribe una Nota"
              rows="4"
              row-height="30"
              shaped
            ></v-textarea>        
          </v-col>
        </v-row>
        
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
            <v-btn icon class="btn" @click="addNewRow" >
                <v-icon>add</v-icon>                    
            </v-btn>    
            <v-btn v-on:click="submit"  color="success"><v-icon>save</v-icon></v-btn>                      
          
              <!-- <v-btn icon class="btn" @click="saveInvoice" >
                  <v-icon>save</v-icon>                    
              </v-btn>   -->          
          </v-col>
        </v-row>              
      </v-form>
    </template>
  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import validations from '@/utils/validation';
import baseApi from '@/services/baseApi';

export default {
  name: "paymentadd", 
  computed:{
    ...mapState(['currentUser','salesorders']),
    cantidad:{
      get() {
        return this.fields[0].cantidad;
      },
      set(){}
      // set(value) {
      //   value=0
      //   this.aumentar(value);
      // }
    }
  },   
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]), 
    async getBreadcrumps(){    
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"});
        let date=new Date();        
        this.attribute.date_start=new Date(date.getFullYear(), date.getMonth() - 1, 1) //Un mes antes del actual con el primer día del mes
        this.attribute.date_start= `${date.getFullYear()}-${date.getMonth()}-${this.attribute.date_start.getDate()}` //Formateamos la fecha
        console.log(this.attribute.date_start);
        await this.$store.dispatch('loadSalesOrders2',this.attribute);        
      }catch(error){
        console.log(error);
      } finally{
        this.hiddenLoading()
      } 
    },    
    submit: async function() {
      let a=this.$refs.form.validate();
      if(a==true){
        try{        
          this.showLoading({titulo:"Almacenando información",color:"warning"})  
          const { salesorder_id,price, date_pay, iva,tipo, notes } = this; 
          baseApi().post(`/pagos`,JSON.stringify({ salesorder_id,price, date_pay, iva,tipo, notes })).then((response)=>{
            if(!response.data.error){                                      
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("COMPRAS: "+response.data.message);
          });                   
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        }   
      }         
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Nuevo Pago",
        title:"Agregar Nuevo Pago",
        subtitle:"primary",
        description:"Este formulario se agregará nuevos pagos"
    });
    this.getBreadcrumps()    
  },
  data: function() {
    return {
      attribute: {sword: '',limit: 27,date_start:"",date_end:new Date().toISOString().substr(0,10)},
      valid: true,
      ...validations,
      id:'',
      modal:'',
      date_pay: new Date().toISOString().substr(0,10),
      notes:'',
      itemsTipo:[        
          'Efectivo',
          'SPEI',
          'TC',
          'TD',
          'Cheque',
          'Dinero Electronico'
        ],
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: 'Producto', value: 'index' },
        {
          text: 'Posición',
          align: 'start',
          sortable: false,
          value: 'posicion',
        }, 
        { text: 'Precio', value: 'price' },        
        { text: 'Cantidad', value: 'cantidad' },        
        { text: 'Total', value: 'total' },        
      ],
    };                  
  },
}

</script>
<style>
  .v-date-picker-table {    
    height: auto;
  }
</style>