<template>
  <div class="campaigns"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row
            :class="sm-6"
          >
            <v-col>
              <h5>Buscar:</h5>
              <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

<!-- 
<v-container>
  <v-layout >
    <v-flex xs6 md6 lg6>

       <v-data-table
        :headers="theader"
        :items="desserts"
        :items-per-page="15"
        class="elevation-1"
      ></v-data-table>   
--> 
<router-link to="/campaign-add">
  <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>add</v-icon>
  </v-btn>
</router-link> 
<div><br></div>


   <div>
    <table class="table table-striped">
     <thead class="thead-dark">
      <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
     </thead>
     <tr v-for="(row,index) of compaigns" :key="row">          
       <td>
         <!-- 
         <button v-on:click="deleteFriend(row.id, i)">x</button>
         -->
        <v-btn v-on:click="editRow = row.id" icon>
           <v-icon>edit</v-icon>
         </v-btn>
         <a v-bind:href="url(row.id)">
           <v-icon>visibility</v-icon>
         </a>                
       </td>             
      <td>
        <div v-if="editRow === row.id">          
          <input v-on:keyup.13="updateRow(row)" placeholder="Nombre" v-model="row.name" />
          <input v-on:keyup.13="updateRow(row)" placeholder="Descripción" v-model="row.description" />
          <v-combobox
            v-model="row.type"
            :items="itemsType"
            label="Selecciona un Tipo"
          ></v-combobox>
          <!-- <ejs-combobox v-model="row.type" :dataSource='dataItem' :fields='dataFields' placeholder='Seleccione una categoría' popupWidth="250px">
          </ejs-combobox>                       -->
          <input v-on:keyup.13="updateRow(row)" placeholder="Fecha de Envío" v-model="row.date_send" />          
          <input v-on:keyup.13="updateRow(row)" placeholder="Notas" v-model="row.notes" />
          <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
        </div>
        <div v-else> {{index+1}}</div>        
      </td>      
      <td>{{row.id}}</td>
      <td>{{row.name}}</td>
      <td>{{row.description}}</td>
      <td>{{row.type}}</td>
      <td>
        <div v-if="row.ver == '1'">        
          <v-chip
          class="ma-2"
          color="green"
          label
          text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            Activo
          </v-chip>
        </div>                 
        <div v-else>
          <v-chip
          class="ma-2"
          color="default"
          label
          text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            Desactivado
          </v-chip>
        </div>                                                                       
      </td>           
      <td>{{row.date_send}}</td>
      <td>{{row.notes}}</td> 
      <td>{{row.created_at}}</td>
      <td>{{row.updated_at}}</td>       
     </tr>
   </table>          
   </div>          
        
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->


  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import baseApi from '@/services/baseApi';

export default {
  name: 'campaigns',
    components: {
      //FieldSearch,
  },    
  computed:{
        ...mapState(['compaigns'])
  },  
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    url: function (href){
        return 'detalleventascliente?eventsid='+ href
    }, 
    urlCustomer: function (href){
      return 'detalleventascliente?customerid='+ href
    }, 
    async getBreadcrumps(sword){
      this.changeBreadcrumbs({
        page:"Campañas",
        title:"Lista de Campañas",
        subtitle:"primary",
        description:"En esta lista encontrará todos las campañas dados de alta en el sistema desde el principio de los tiempos"
       });
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];
      this.attribute.sword=sword;

      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadCompaigns',this.attribute);        
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                     
          let datos=baseApi().post(`/campaigns/update/${row.id}`,JSON.stringify(row));
          console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
  },
  created(){
    this.changeBreadcrumbs({
        page:"Campañas",
        title:"Lista de Campañas",
        subtitle:"primary",
        description:"En esta lista encontrará todas las Campañas dados de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  },
  data(){
     return{
      attribute: {sword: '',limit: 27},
      sword:"All",
      sfecha:new Date().toISOString().substr(0,10),
      rows: [],
      editRow:null,      
      itemsType: [
          'Leads',
          'Frecuentes',
      ],          
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: '#', value: 'index' },
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Nombre', value: 'name' },
        { text: 'Descripción', value: 'description' },                
        { text: 'Tipo', value: 'type' },
        { text: 'Status', value: 'ver' },
        { text: 'Fecha de Envío', value: 'date_send' },
        { text: 'Notas', value: 'notesw' },
        { text: 'Registro', value: 'created_at' },        
        { text: 'Actualización', value: 'updated_at' },        
      ],
    }
  },
}
</script>
