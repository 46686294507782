<template>
  <div class="pwproductadd" >
    <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="id"
              label="ID"              
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="sku"
              label="SKU"
            ></v-text-field>
          </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
      >
        <v-text-field
          v-model="description"
          label="Descripcion"              
        ></v-text-field>
      </v-col>
     
    </v-row>

   

      <v-row>
        <v-col
        cols="12"
        sm="6"
        >
        <v-text-field
          v-model="canasta_basica"
          label="Canasta Basica"
        ></v-text-field>
      </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-combobox
            v-model="productcomposed_id"
            :items="itemsType"
            label="Selecciona un Categoria Asignada"
          ></v-combobox>
          <!-- <ejs-combobox v-model="tipo" :dataSource='dataItem' :fields='dataFields' placeholder='Seleccione un tipo' popupWidth="250px"> </ejs-combobox>  -->
        </v-col>      
      </v-row>

      <v-btn v-on:click="submit">Guardar <v-icon>save</v-icon></v-btn>
  </div>
</template>


<script>
import validations from '@/utils/validation';
import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import baseApi from '@/services/baseApi';

export default {
  name: "pwproductadd",
  created(){
    this.changeBreadcrumbs({
        page:"Nuevo Producto",
        title:"Agregar Nuevo Producto de Pocket Wallet",
        subtitle:"primary",
        description:"Este formulario se agregará nuevo producto"
      });
    // this.getBreadcrumps(this.sword)
    this.getBreadcrumps()    
  },
  computed:{
    ...mapState(['currentUser',"pwproviders"])    
  },     
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    submit: async function() {
      try{        
        this.showLoading({titulo:"Almacenando información",color:"warning"})
        //let branch_id=this.currentUser.branch_id;        
        const { id, sku, description, canasta_basica, productcomposed_id } = this;
        const baseURL=`https://www.pwallet.online/api/products/create`;

        //await axios.post(baseURL,JSON.stringify({ id, sku, description, canasta_basica, productcomposed_id })).then((response)=>{
        let response = await axios.post(baseURL,{ id, sku, description, canasta_basica, productcomposed_id });    

        console.log(response.data.error); 
        console.log(response.data.message); 
        if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
        }else{
                this.$swal("Error!",JSON.stringify(response.data.message), "error");                           
        }          
    

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      }              
    },
    async getBreadcrumps(){
        try{        
          this.showLoading({titulo:"Accediendo información",color:"primary"})        
          this.$store.dispatch('loadPWProviders');        

        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
        } 
    }
  },
  data: function() {
    return {
      ...validations,
       id:'',
       description:'',
       metrica: '',
       canasta_basica:0, 
       productcomposed_id:"8",       
       itemsType: [
          '1',
          '8',
          '9',
          '10',
          '12',
       ],       
    };                  
  }
}

</script>