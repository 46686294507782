
<template>
<div>
  <!-- Busqueda Return -->
  <v-row>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="returns.length > 0">
      <span class="right"><b>{{ returns.length }}</b> registro(s) encontrados <br></span>
      <br><br>
        <!-- Tabla Salida -->
        <template>
          <!-- height="700px" -->
          <v-simple-table
            fixed-header
            dense
          >
            <template v-slot:default>
              <thead>
                <tr><th class="text-left" v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>
              </thead>
              <tbody>
                <!-- v-for="row in subfamilies" -->
                <tr
                  v-for="(row,index) of returns"
                  :key="row.id"
                >
                <td>
                    <!-- 
                    <button v-on:click="deleteFriend(row.id, i)">x</button>
                    -->
                    <v-btn icon v-on:click="editRow = row.id">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <a v-bind:href="url(row.id)">
                      <v-icon>visibility</v-icon>
                    </a>     
                    <v-btn
                        v-if="row.notes != null && row.notes != ''"
                              color="red lighten-2"
                              icon
                              @click="getNote(row.notes)" 
                            >
                            <v-icon>note</v-icon>
                    </v-btn>    
                    
                                                    
                        
                  </td>      
                  <td>
                    {{index+1}} <br><br>

                    <div v-if="editRow === row.id">
                      <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.branch_id" /> -->
                      <v-autocomplete
                        v-model="row.employee_id"
                        :hint="`${employee_id.id} - ${employee_id.fullname}`"
                        :items="employees"
                        item-text="fullname"
                        item-value="id"
                        label="Selecciona un Empleado"  
                        outlined
                      ></v-autocomplete>
                      <!-- <v-text-field
                                  v-model="row.salesorder_id"
                                  v-on:keyup.13="updateRow(row)"
                                  label="Salida ID"
                      />    -->
                
                      <v-text-field
                                  v-model="row.title"
                                  v-on:keyup.13="updateRow(row)"
                                  label="Título"
                      />                                 
                      <!-- <ejs-combobox 
                        v-on:keyup.13="updateRow(row)" 
                        v-model="row.status" 
                        :dataSource='dataIStatus' 
                        :fields='dataFStatus' 
                        placeholder='Seleccione un Status' 
                        popupWidth="250px">
                      </ejs-combobox> -->
                      <v-text-field
                                  v-model="row.date_return"
                                  v-on:keyup.13="updateRow(row)"
                                  label="Fecha de Devolucion"
                      />          
                      <v-text-field
                                  v-model="row.notes"
                                  v-on:keyup.13="updateRow(row)"
                                  label="Notas"
                      />                
                      <v-combobox
                        v-model="row.status" 
                        :items="itemsStatus"
                        label="Selecciona un Status"
                      ></v-combobox>    
                      <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
                      <v-btn v-on:click="editRow=false" class="deep-dark">cerrar</v-btn>
                    </div>                     
                  </td>    
                  <td>{{row.id}}</td>                                         
                  <td>
                    <div v-if="row.project_id!=null">{{row.projectfolio }} / {{row.project_id }} </div>  
                    <div v-else>
                          <v-chip
                            class="ma-2"
                            color="deep-orange"
                            label
                            text-color="white"
                            >
                              <v-icon left>
                                mdi-label
                              </v-icon>
                              Sin Folio
                            </v-chip>
                    </div>  
                      
                    <v-btn v-on:click="editRowProjectID = row.id" icon>
                          <v-icon>edit</v-icon>
                    </v-btn>
                    <div v-if="editRowProjectID === row.id">
                        <v-text-field
                                      class="my-5 py-0"
                                      v-model="row.project_id"    
                                      v-on:keyup.13="updateRowProject(row)"
                                      label="Folio Proyecto"
                                      placeholder="Escribe un folio"
                                      rounded
                                      outlined
                        ></v-text-field>
                        <v-btn v-on:click="updateRowProject(row)" class="green">Actualizar</v-btn>
                        <v-btn v-on:click="editRowProjectID=false" class="deep-black">Cerrar</v-btn>
                    </div>       
                  </td>
                  <td>
                      {{row.employeename}} <br>
                      <!-- <b>Salida ID:</b> {{row.salesorder_id}} <br> -->
                      <small>{{row.branchname}}</small> <br>
                      <h6>{{row.title}}</h6>
                  </td>   
                  <td><span style="font-weight:bold; font-size:1.3rem">{{row.totalreturn | currency }}</span> </td>
                 
                  <td>
                    <div v-if="row.status == 'Autorizada'">        
                      <v-chip
                        class="ma-2"
                        color="green"
                        label
                        text-color="white"
                      >
                        {{row.status}}
                      </v-chip>
                      </div>
                      <div v-else-if="row.status == 'Cancelada'">        
                      <v-chip
                        class="ma-2"
                        color="red"
                        label
                        text-color="white"
                      >
                          {{row.status}}
                      </v-chip>
                      </div>
                      <div v-else>
                      <v-chip
                      class="ma-2"
                      color="default"
                      label
                      text-color="black"
                      >
                        {{row.status}}
                      </v-chip>
                    </div>                                                                       
                  </td>
                  <td>{{formatDate(row.date_salesorder)}}</td>     
                  <td>{{formatDate(row.created_at)}}</td>     
                  <td>{{formatDate(row.updated_at)}}</td>     
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <!-- FIN table -->                                    
    </v-col>
    <v-col cols=12 sm=12 md=12 lg=12
      v-else
    >
      <v-alert
          dense
          outlined
          type="error"
      >
          No hay registros encontrados
      </v-alert>
    </v-col>
  </v-row>
  <!-- FIN Busqueda SalesOrder -->  

  <!-- DIALOG NOTAS -->
<div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->

</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
import baseApi from '@/services/baseApi';
// import router from '@/router';
import Dayjs from 'dayjs';
import es from "dayjs/locale/es"




export default {
  props:{
    returns:{
      type:Object,
      required:true
    }
  },
  computed:{
    //'products'
      ...mapState(['employees','currentUser'])
  },
  components: {    

  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    formatDate(dateString) {
            Dayjs.locale(es)
            const date = Dayjs(dateString);
            return date.format('dddd MMMM D, YYYY')
    },
    url: function (href){
        return 'detalledevolucion?returnid='+ href
    }, 
    async getEmployees(){   
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        this.$store.dispatch('loadEmployees',this.attribute);
      }catch(error){
        //console.log(error);
        this.$swal("Error!", "Necesita ingresar sus credenciales", "error");
      } finally{
        this.hiddenLoading()
      } 
    },
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})  
          // let datos = baseApi().post(`/devoluciones/update/${row.id}`,JSON.stringify(row));   
          // console.log(datos);
          baseApi().post(`/devoluciones/update/${row.id}`,JSON.stringify(row)).then((response)=>{
          // baseApi().get(`/cotizaciones/copySales/${id}`).then((response)=>{
              if(!response.data.error){                            
                  this.$swal("Genial!",response.data.message, "success");
                  this.getReturn();
              }else{
                  this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("Transferir COTIZACION: "+response.data.message);
          });                                                                                                 
        }catch(error){
          console.log(error);          
        }  finally{
          this.hiddenLoading()
        } 
    },
    updateRowProject(row){
      try{        
        this.showLoading({titulo:"Accediendo información",color:"warning"})                
        baseApi().post(`/devoluciones/updateFolio/${row.project_id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
              this.getReturn();
            }else{
              // this.$swal("Error!",response.data.message, "error");
              this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
            }                                    
            console.log("RETURN: "+response.data.message);
        });         
      }catch(error){
        console.log(error);        
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    }
   
    
  },
  destroyed() {
    console.log("Destruido ReturnItem");
  },
  mounted(){
    console.log("Montado ReturnItem");
    // if (this.productid>0) {
      this.getEmployees()
    // }
  },
  data(){
    return{
      attribute: {sword: '',limit: 500},
      dialogNotes:false,
      editRow:null,
      editRowProjectID:null,
      employee_id:'',      
      notes:'',
      itemsStatus: [
          'Proceso',
          'Cancelada',
          'Autorizada',
      ],   
      theader: [
          { text: 'Accion', value: 'customername' },
          { text: '#', value: 'customername' },
          { text: 'ID', value: 'customername' },
          { text: 'Folio Proyecto', value: 'customername' },
          { text: 'Empleado', value: 'customername' },
          { text: 'Total', value: 'total_quote ' },       
          { text: 'Status', value: 'total_quote ' },       
          { text: 'Devolucion', value: 'iva_quote' },
          { text: 'Registro', value: 'iva_quote' },
          { text: 'Actualizacion', value: 'iva_quote' },
      ],
    }
  }
}
</script>