<template>
  <div class="purchase"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row >
            <v-col>
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>


    <v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <router-link to="/purchase-add">
                    <v-btn                  
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </router-link>
                  </template>
                  <span>Agregar Nueva Compra</span>
                </v-tooltip>
            </template>                  

            <!-- agregar Proveedor --> 
     <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>group_add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Proveedor</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <SupplierCreate  />
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Nuevo Proveedor</span>
            </v-tooltip>
        </template> 
         <!-- FIN agregar Proveedor-->  
            
        <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="getPurchase('')" 
                      color="primary darken-5"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Página</span>
                </v-tooltip>
        </template>
                      
        <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>

 
<v-row>
      <v-col cols=12 sm=12 md=12 lg=12>   
        <table>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Total IVA:</b> {{ totaliva | currency }}</td> 
          </tr>
        </table>
      </v-col>
</v-row> 

<!-- Busqueda Purchase -->
<PurchaseItem :purchases="purchases"  />
<!-- FIN Busqueda Purchase -->
  


  
  

  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
// import ProductForm from "@/components/ProductForm";
// import baseApi from '@/services/baseApi';
import SupplierCreate from "@/components/SupplierCreate";
import PurchaseItem from "@/components/PurchaseItem";

export default {
  name: 'purchase',
  computed:{
      ...mapState(['currentUser','purchases'])    
  },
  components: {
    SupplierCreate,
    PurchaseItem
      //FieldSearch,
      // ProductForm
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),       
    async getPurchase(sword){  
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        
        await this.$store.dispatch('loadPurchases',sword);
        for (var i = 0; i < this.purchases.length; i+=1) { 
          //console.log(this.salesorders[i].iva_salesorders)
          this.totaliva+=this.purchases[i].purchaseiva;              
        }
      }catch(error){
        //console.log(error);
        this.$swal("Error!", "Necesita ingresar sus credenciales", "error");
      }  finally{
        this.hiddenLoading()
      }     
    },
    async getBreadcrumps(sword){      
      this.getPurchase(sword)      
    },
    
  },
  created(){
    this.changeBreadcrumbs({
        page:"Compras",
        title:"Compras",
        subtitle:"primary",
        description:"En esta lista encontrará todas las compras dados de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  },
  data(){
     return{
      dialog:false,
      toggle_exclusive:'',
      attribute: {sword: 'All',limit: 500},
      sword:"",
      totaliva:0,
      sfecha:new Date().toISOString().substr(0,10),
      rows: [],
      
    }
  }
}
</script>