
<template>
<div>
  <!-- Busqueda Return -->
  <v-row>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="quotes.length > 0">
      <span class="right"><b>{{ quotes.length }}</b> registro(s) encontrados <br></span>
      <br><br>
        <!-- Tabla Salida -->
        <template>
          <!-- height="700px" -->
          <v-simple-table
            fixed-header
            dense
          >
            <template v-slot:default>
              <thead>
                <tr><th class="text-left" v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>
              </thead>
              <tbody>
                <!-- v-for="row in subfamilies" -->
                <tr
                  v-for="(row,index) of quotes"
                  :key="row.id"
                >              
                  <td>
         <!-- 
         <button v-on:click="deleteFriend(row.id, i)">x</button>
         -->
        <v-btn icon v-on:click="editRow = row.id">
          <v-icon>edit</v-icon>
        </v-btn>
        <a v-bind:href="url(row.id)">
          <v-icon>visibility</v-icon>
        </a>       
        <v-btn v-on:click="sendMail(row.id)" icon>
             <v-icon>mail</v-icon>
        </v-btn>         
        <v-btn
            icon
            color="green darken-1"
            @click="copyQuote(row.id)"
        >        
          <v-icon>content_copy</v-icon>
        </v-btn>                                        
        <v-btn
            icon
            color="blue darken-1"
            @click="copySales(row.id)"
        >        
          <v-icon>sync</v-icon>
        </v-btn>   
        <v-btn
                        v-if="row.notes != null && row.notes != ''"
                              color="red lighten-2"
                              icon
                              @click="getNote(row.notes)" 
                            >
                            <v-icon>note</v-icon>
        </v-btn>                 
      </td>      
      <td>
        {{ index+1 }} <br><br>
        <div v-if="editRow === row.id">
          <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.branch_id" /> -->
          <v-autocomplete
            v-model="row.customer_id"
            :hint="`${customer_id.id} - ${customer_id.razon_social}`"
            :items="customers"
            item-text="razon_social"
            item-value="id"
            label="Selecciona un Cliente"  
            outlined
          ></v-autocomplete>
          <v-text-field
                      v-model="row.title"
                      v-on:keyup.13="updateRow(row)"
                      label="Título"
          />
          <v-switch
            v-model="row.iva"
            label="IVA"
          ></v-switch>           
          <v-text-field
                      v-model="row.discount"
                      v-on:keyup.13="updateRow(row)"
                      label="Descuento %"
          />                    
          <!-- <ejs-combobox 
            v-on:keyup.13="updateRow(row)" 
            v-model="row.status" 
            :dataSource='dataIStatus' 
            :fields='dataFStatus' 
            placeholder='Seleccione un Status' 
            popupWidth="250px">
          </ejs-combobox> -->
          <v-text-field
                      v-model="row.date_quote"
                      v-on:keyup.13="updateRow(row)"
                      label="Fecha de Cotización"
          />          
          <v-text-field
                      v-model="row.notes"
                      v-on:keyup.13="updateRow(row)"
                      label="Notas"
          />                
          <v-combobox
            v-model="row.status" 
            :items="itemsStatus"
            label="Selecciona un Status"
          ></v-combobox>    
          <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
          <v-btn v-on:click="editRow=false" class="deep-dark">cerrar</v-btn>
        </div>        
      </td>    
      <td>{{row.id}}</td>
      <td>
        <div v-if="row.project_id!=null">{{row.projectfolio }} / {{row.project_id }} </div>  
        <div v-else>
              <v-chip
                class="ma-2"
                color="deep-orange"
                label
                text-color="white"
                >
                  <v-icon left>
                    mdi-label
                  </v-icon>
                  Sin Folio
                </v-chip>
        </div>  
           
        <v-btn v-on:click="editRowProjectID = row.id" icon>
              <v-icon>edit</v-icon>
        </v-btn>
        <div v-if="editRowProjectID === row.id">
            <v-text-field
                          class="my-5 py-0"
                          v-model="row.project_id"    
                          v-on:keyup.13="updateRowProject(row)"
                          label="Folio Proyecto"
                          placeholder="Escribe un folio"
                          rounded
                          outlined
            ></v-text-field>
            <v-btn v-on:click="updateRowProject(row)" class="green">Actualizar</v-btn>
            <v-btn v-on:click="editRowProjectID=false" class="deep-black">Cerrar</v-btn>
        </div>       
      </td>
      <td>

        {{row.razon_social}} <br>
        <small>{{row.branchname}}</small>
        <h6>{{row.title}}</h6>
      </td>   
        
        

      <td>{{row.subtotal_quotes | currency }} </td>
      <td>{{row.discount_quotes }}% <br><small style="color:red;">{{row.discount_moneda | currency }}</small></td>
      <td>{{row.subtotal_quotes_discount | currency }} </td>
      <td>{{row.iva_quotes | currency }} </td>
      <td><span style="font-weight:bold; font-size:1.3rem">{{row.total_quotes | currency }}</span> </td>
      <td>
        <div v-if="row.status == 'Pagado'">        
           <v-chip
             class="ma-2"
             color="green"
             label
             text-color="white"
           >
             {{row.status}}
           </v-chip>
          </div>
          <div v-else-if="row.status == 'Cancelada'">        
           <v-chip
             class="ma-2"
             color="red"
             label
             text-color="white"
           >
               {{row.status}}
           </v-chip>
          </div>
          <div v-else-if="row.status == 'Contratado'">        
           <v-chip
             class="ma-2"
             color="yellow"
             label
             text-color="black"
           >
               {{row.status}}
           </v-chip>
          </div>                 
          <div v-else>
           <v-chip
           class="ma-2"
           color="default"
           label
           text-color="black"
           >
             {{row.status}}
           </v-chip>
        </div>                                                                       
      </td>              
          
                  <td>{{formatDate(row.date_quote)}}</td>     
                  <td>{{formatDate(row.created_at)}}</td>     
                  <td>{{formatDate(row.updated_at)}}</td>     
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <!-- FIN table -->                                    
    </v-col>
    <v-col cols=12 sm=12 md=12 lg=12
      v-else
    >
      <v-alert
          dense
          outlined
          type="error"
      >
          No hay registros encontrados
      </v-alert>
    </v-col>
  </v-row>
  <!-- FIN Busqueda SalesOrder -->  

  <!-- DIALOG NOTAS -->
<div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->

</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
import baseApi from '@/services/baseApi';
// import router from '@/router';
import Dayjs from 'dayjs';
import es from "dayjs/locale/es"




export default {
  props:{
    quotes:{
      type:Object,
      required:true
    }
  },
  computed:{
    //'products'
      ...mapState(['customers','currentUser'])
  },
  components: {    

  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    formatDate(dateString) {
            Dayjs.locale(es)
            const date = Dayjs(dateString);
            return date.format('dddd MMMM D, YYYY')
    },
    sendMail(id){            
      try{                
        this.showLoading({titulo:"Accediendo información",color:"warning"})    
        // let branch_id=this.currentUser.branch_id;
        if(confirm("Desea enviar el correo de la cotización: "+ id+"?")){            
          // baseApi().get(`https://www.cloudbits.org.mx/fw/lumen/check/email/detallecotizacion/${id}?branch_id=`+branch_id).then((response)=>{
          baseApi().get(`https://www.cloudbits.org.mx/fw/lumen/check/email/detallecotizacion/${id}`).then((response)=>{
              if(!response.data.error){                            
                if(response.data.error==false)                            
                  this.$swal("Genial!",response.data.message, "success");
                else
                  this.$swal("Error!",response.data.message, "error");  
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("COTIZACION: "+response.data.message);
          });         
        }
      }catch(error){
        console.log(error);        
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    },
    url: function (href){
        return 'detallecotizacion?quoteid='+ href
    }, 
    async getCustomer(){   
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadCustomers',this.attribute);
      }catch(error){
        //console.log(error);
        this.$swal("Error!", "Necesita ingresar sus credenciales", "error");
      } finally{
        this.hiddenLoading()
      } 
    },
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    },
    copyQuote(id) {
      if(confirm("Desea copiar la cotización: "+ id+"?")){
        try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                  
          baseApi().get(`/cotizaciones/copyQuote/${id}`).then((response)=>{
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
            }else{
                this.$swal("Error!",response.data.message, "error");
            }                                    
            console.log("Copiar COTIZACION: "+response.data.message);
          });
        }catch(error){
          console.log(error);     
        }  finally{
          this.hiddenLoading()          
          //this.$swal("Genial!", "Se copió la información como ventas", "success");            
        } 
      }else{
        console.log('User'+this.user.name);
        // console.log('CurrentUser: '+this.currentUser.branch_id);
      }
    },
    copySales(id) {
      if(confirm("Desea transferir la cotización como venta?")){
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})                    
            let datos = baseApi().get(``);
            console.log(datos);
            baseApi().get(`/cotizaciones/copySales/${id}`).then((response)=>{
              if(!response.data.error){                            
                  this.$swal("Genial!",response.data.message, "success");
              }else{
                  this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("Transferir COTIZACION: "+response.data.message);
            });
          }catch(error){
            console.log(error);
          }  finally{
            this.hiddenLoading()            
            // this.$swal("Genial!", "Se copió la información como ventas", "success");
          } 
      }
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})        
          //var url="https://www.cloudbits.org.mx/fw/lumen/check/precios/update/"+ row.id;
          // const baseURL2=`https://www.cloudbits.org.mx/fw/lumen/check/cotizaciones/update/${row.id}`;
          // const config = {
          //   data: JSON.stringify(row),
          //   method: "post", //you can set what request you want to be
          //   url:baseURL2,
          //   timeout: 10000,
          //   headers: {
          //     'Access-Control-Allow-Origin': '*',
          //     'Access-Control-Allow-Headers': '*',
          //     "Content-Type": "application/json",
          //     "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
          //   }
          // }
          // let datos=this.axios(config)    
          let datos = baseApi().post(`/cotizaciones/update/${row.id}`,JSON.stringify(row));                                                                                                    
          console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
    updateRowProject(row){
      try{        
        this.showLoading({titulo:"Accediendo información",color:"warning"})                
        baseApi().post(`/cotizaciones/updateFolio/${row.project_id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
              this.getReturn();
            }else{
              // this.$swal("Error!",response.data.message, "error");
              this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
            }                                    
            console.log("RETURN: "+response.data.message);
        });         
      }catch(error){
        console.log(error);        
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    }
    
   
    
  },
  destroyed() {
    console.log("Destruido QuoteItem");
  },
  mounted(){
    console.log("Montado QuoteItem");
    // if (this.productid>0) {
      this.getCustomer()
    // }
  },
  data(){
    return{
      attribute: {sword: '',limit: 500},
      dialogNotes:false,
      editRow:null,
      editRowProjectID:null,
      customer_id:77,
      employee_id:'',      
      notes:'',
      itemsStatus: [
          'Proceso',
          'Cancelada',
          'Pagado',
          'Contratado',
      ],    
      theader: [
          { text: 'Accion', value: 'customername' },
          { text: '#', value: 'customername' },
          { text: 'ID', value: 'customername' },
          { text: 'Folio Proyecto', value: 'customername' },
          { text: 'Cliente', value: 'customername' },
          { text: 'Subtotal', value: 'subtotal_quote' },
          { text: 'Descuento', value: 'subtotal_quote' },
          { text: 'Subtotal c/Desc', value: 'subtotal_quote' },
          { text: 'IVA', value: 'iva_quote' },
          { text: 'Total', value: 'total_quote' },
          { text: 'Status', value: 'status' },          
          { text: 'Cotizacion', value: 'iva_quote' },
          { text: 'Registro', value: 'iva_quote' },
          { text: 'Actualizacion', value: 'iva_quote' },
      ],
    }
  }
}
</script>