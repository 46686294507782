<template>
 <div>
    <h5>Buscar:</h5>
    <v-text-field
            v-model="search"
            hide-details
            prepend-icon="mdi-magnify"
            single-line        
            placeholder="buscar..."
            autofocus
    ></v-text-field>

    <v-radio-group
      v-model="article"
      v-if="hasProduct"
      row
    >
      <v-radio
        label="Ninguno"
        value="Ninguno"
      ></v-radio>
      <v-radio
        label="Productos"
        value="Productos"
      ></v-radio>
      <v-radio
        label="Servicios"
        value="Servicios"
      ></v-radio>      
    </v-radio-group>

    <v-radio-group
      v-model="status"
      v-if="hasPricelist"
      row
    >
      <v-radio
        label="Descontinuado"
        value="Descontinuado"
      ></v-radio>
      <v-radio
        label="Privado"
        value="Privado"
      ></v-radio>      
      <v-radio
        label="Público"
        value="Público"
      ></v-radio>      
    </v-radio-group>   
    
    <v-radio-group
      v-model="stock"
      v-if="hasPricelist"
      row
    >
      <v-radio
        label="Ninguno"
        value="ninguno"
      ></v-radio>
      <v-radio
        label="En Camino"
        value="camino"
      ></v-radio>
      <v-radio
        label="En Stock"
        value="stock"
      ></v-radio> 
      <v-radio
        label="Minimo en Stock"
        value="minstock"
      ></v-radio> 
      <v-radio
        label="Maximo en Stock"
        value="maxstock"
      ></v-radio>     
      <v-radio
        label="Stock Syscom"
        value="ssyscom"
      ></v-radio>  
      <v-radio
        label="Stock CT"
        value="sctonline"
      ></v-radio> 
      <v-radio
        label="Stock CVA"
        value="scva"
      ></v-radio>      
    </v-radio-group>

  </div>
</template>
<script>
export default {
  computed:{
    search:{
      get(){
        //.toLowerCase() Para no aceptar Mayusculas
        return this.$store.state.filter.query;
      },
      set(val){
        this.$store.commit('SET_QUERY',val);
      }
    },
    status:{
      get(){
        return this.$store.state.filter.status;
      },
      set(val){
        this.$store.commit('SET_STATUS',val);
      }
    },
    article:{
      get(){
        return this.$store.state.filter.type_article;
      },
      set(val){
        this.$store.commit('SET_ARTICLE',val);
      }
    },
    stock:{
      get(){
        return this.$store.state.filter.stock;
      },
      set(val){
        this.$store.commit('SET_STOCK',val);
      }
    },

  },
  methods:{
      async getPricelist(){
        await this.$store.dispatch('loadPricelists2',this.attribute);
      }
  },
  data: function() {
    return {      
      attribute: {
        sword: 'All',
        status:'All',
        // date_start:'2019-01-01',
        // date_end:new Date().toISOString().substr(0,10),
        // pricelistid:'',
        // limit: 27
      },
    }

  },
  props:["hasPricelist","hasProduct","hasRangeDate"],
  created(){
      this.getPricelist();
  }, 
  
}
</script>