<template>
  <div class="compararprice"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row
            :class="sm-6"
          >
            <v-col>
              <h5>Buscar:</h5>
              <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>  

    <v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <router-link to="/pwproduct-add">
                    <v-btn                  
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </router-link>
                  </template>
                  <span>Agregar Nuevo Producto</span>
                </v-tooltip>
            </template>   
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <router-link to="/pwproductcollate-add">
                    <v-btn                  
                      color="purple"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </router-link>
                  </template>
                  <span>Agregar Nuevo Collate del Producto</span>
                </v-tooltip>
            </template>                                  
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>


<!-- <tr v-for="row in rows" :key="row">                  -->
<v-row>
  <div v-for="row in pwproducts" :key="row.id" class="col xs4 sm4 md4 lg4">
  <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="374"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div v-if='row.productfilename == ""'>
      <v-img
        lazy-src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
        height="250"
        src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
      ></v-img>                                    
    </div> 
    <div v-else>
      <v-img
        v-bind:lazy-src="row.productfilename"
        max-height="250"
        max-width="250"
        v-bind:src="row.productfilename"
      ></v-img>
    </div>   
    
    
    <v-card-title>{{row.description}}</v-card-title>

    <v-card-text>
      <v-row
        class="mx-0"
      >
        <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>

        <div class="grey--text ml-4">
          4.5 (413)
        </div>
      </v-row>

      <div class="my-4 subtitle-1">
        <span style="font-size:2rem; color:green;">{{row.totalprice | currency }}</span> <span style="font-size:.1.2rem; color:gray;">{{row.stock }}</span><br>
        <span style="font-size:.1.2rem; color:red;">{{row.totalprice_siniva | currency }} <small>  s/iva</small></span>        
      </div>

      <div>
        <!-- <small>{{row.branchname}}</small><br> -->
        <b>Producto ID: {{row.id}}</b> <br>                           
        <b>SKU:</b> {{row.sku}}               
        <br>
        <b>SKU FAB:</b> {{row.productskufab}} 
        <br>        
      </div>
    </v-card-text>

    <v-card-text>
      <div v-if="editRow === row.id"> 
            <v-divider class="mx-4"></v-divider>
            <h6>Actualizar información</h6>
            <v-text-field
              v-model="row.description"
              v-on:keyup.13="updateRow(row)"
              label="Descripcion"       
              placeholder="Descripcion"
            ></v-text-field>
            <v-text-field
              v-model="row.productcomposed_id"
              v-on:keyup.13="updateRow(row)"
              label="Producto Compuesto ID"       
              placeholder="Producto Compuesto ID"
            ></v-text-field>
            <v-text-field
              v-model="row.marca"
              v-on:keyup.13="updateRow(row)"
              label="Marca"       
              placeholder="Marca"
            ></v-text-field>            
            <v-text-field
              v-model="row.metrica"
              v-on:keyup.13="updateRow(row)"
              label="Metrica"       
              placeholder="Metrica"
            ></v-text-field>  
            <v-text-field
              v-model="row.canasta_basica"
              v-on:keyup.13="updateRow(row)"
              label="Canasta Basica"       
              placeholder="Canasta Basica"
            ></v-text-field>
              
            <!-- 
            <v-autocomplete
              v-model="pricelist.brand_id"
              :hint="`${pricelist.brand_id}`"
              :items="brands"
              item-text="name"
              item-value="id"              
              label="Selecciona una Marca"  
              outlined
            ></v-autocomplete>
            -->
            
            <v-switch
              v-model="row.ver"
              label="Habilitar"
            ></v-switch>             
          
          
            <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
          </div>       
    </v-card-text>

    <v-divider class="mx-4"></v-divider>    

    <v-card-actions>
      <div v-if='row.producturl != null' style="text-align:left;">
        <a v-bind:href="row.producturl" 
          class="v-btn v-btn--text theme--light v-size--default deep-purple--text text--lighten-2"
          color="deep-purple lighten-2"
          text
          target="_blank">
          <v-icon>language</v-icon>          
          </a>

      </div>      

      <a v-bind:href="url(row.id)">
          <v-icon>compare_arrows</v-icon>
      </a>  

      <v-btn
            color="deep-purple warning"
            v-on:click="editRow = row.id" 
          >
            <v-icon>edit</v-icon>
      </v-btn> 
    </v-card-actions>
  </v-card>
  </div>
</v-row>

    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->


  </div>
</template>

<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import ApiPW from '@/services/apiPW';


export default {
  name: 'compararprice',
  created(){
      this.changeBreadcrumbs({
          page:"Precios",
          title:"Comparacion de precios",
          subtitle:"primary",
          description:"En esta lista encontrará comparación de precios segun el mes escogido"
        });
      this.getBreadcrumps(this.sword)
  }, 
  computed:{
      ...mapState(['pwproducts'])    
  },
  components: {
      //FieldSearch,
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    url: function (href){
        return 'pw-prices?productid='+ href
    }, 
    updateRow(row) {      
      try{                
        this.showLoading({titulo:"Actualizando información",color:"primary"})  
        // alert(row.id)
        ApiPW().post(`/products/update/${row.id}`,JSON.stringify(row)).then((response)=>{        
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("VENTA: "+response.data.message);
        });              
      }catch(error){
        console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    },
    async getBreadcrumps(sword){    
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        this.$store.dispatch('loadPWProduct',sword);        

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    }
  },
  data(){
     return{
      sword:"",
      editRow: null,
      sfecha:new Date().toISOString().substr(0,10),
      brows: [],
      dataItem:[        
          {id: 'Público', game: 'Público'},
          {id: 'Privado', game: 'Privado'},
          {id: 'Descontinuado', game: 'Descontinuado'},
      ],
      dataFields:{value:'id',text:'game'},
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'SKU', value: 'sku' },
      ],
    }
  }
}
</script>