<template>
  <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
    <v-card>
      <v-img
          height="200px"
          src="https://www.ionoff.com.mx/wp-content/uploads/2021/03/20287026-scaled.jpg"
      >     
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
          >                                  
              <v-spacer></v-spacer>              
                <v-menu>
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="">
                        <v-btn
                          color="white"
                          icon
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>                                                                                                                                                                                
                      </template>
                    </v-tooltip>
                  </template>
                  <v-list>                                                                                                                              
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn v-on:click="editRow = customer.id" icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <a v-bind:href="url(customer.id)">
                          <v-icon>visibility</v-icon>
                        </a>     
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn @click="updateMailchimp(customer.mailchimp_id)" class="yellow">
                          <v-icon >refresh</v-icon>
                        </v-btn>   
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn @click="updateMautic(customer.mautic_id)" class="purple">
                          <v-icon >refresh</v-icon>
                        </v-btn>   
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn @click="addWooOne(customer.id)" class="blue">
                          <v-icon >add</v-icon> 
                        </v-btn>   
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn @click="addMautic(customer.id)" class="purple">
                          <v-icon >add</v-icon> 
                        </v-btn>   
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
            </v-app-bar>

            <v-card-title class="white--text ">
              <div class="text-center">
                <v-avatar size="57">
                  <img
                    alt="user"
                    src="https://www.cloudbits.org.mx/img/avatar-1.png"
                  >
                </v-avatar>
              </div>
              <p class="ml-3">
                {{customer.customerfullname}}
              </p>
              <p class="ml-3">
                <v-rating
                  v-model="customer.rank"
                  color="yellow darken-2"
                  background-color="grey darken-1"
                  empty-icon="$ratingFull"
                  half-increments
                  hover
                  large
                ></v-rating>
              </p>
              
          </v-card-title>
        </v-img>
          


          <v-card-text>
            <div class="font-weight-bold ml-8 mb-2">
              <span v-if='customer.status != "Lead"' style="font-size:1.6rem;">${{customer.coinpurse}} <br></span>
              # {{customer.id}} <br>
              WooID: {{customer.woo_id}} <br>
              Mailchimp: {{customer.mailchimp_id}} <br>
              Mautic: {{customer.mautic_id}} <br>
              Compañía: {{customer.company}}<br>     
              Telefono {{customer.phone}} <br>              
              Movil 
              <v-btn
                  v-if='customer.mobile != null'
                  v-bind:href="urlwhats+customer.mobile"
                  target="_blank"
              >     {{customer.mobile}}              
              </v-btn> 
              <br> 
              Email {{customer.email}} <br>
              Fuente {{customer.fuente}} <br>
              Status {{customer.status}} <br>
            </div>
            <div v-if="editRow === customer.id">
              <!-- <input v-on:keyup.13="updateRow(customer)" placeholder="Sucursal id" v-model="customer.branch_id" />                             -->
              <input v-on:keyup.13="updateRow(customer)" placeholder="Título" v-model="customer.title" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Razon Social" v-model="customer.razon_social" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Nombre" v-model="customer.firstname" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Apellido" v-model="customer.lastname" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Compañia" v-model="customer.company" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Email" v-model="customer.email" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Domicilio" v-model="customer.address" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Núm. Ext." v-model="customer.num_ext" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Colonia" v-model="customer.colonia" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="C.P." v-model="customer.zip" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Teléfono" v-model="customer.phone" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="Celular" v-model="customer.mobile" />
              <input v-on:keyup.13="updateRow(customer)" placeholder="RFC" v-model="customer.rfc" />              
              <v-switch
                v-model="customer.woocommerce"
                label="Woocommerce"
              ></v-switch>          
              <input v-on:keyup.13="updateRow(customer)" placeholder="Woo id" v-model="customer.woo_id" />  
              <v-switch
                v-model="customer.mailchimp"
                label="Mailchimp"
              ></v-switch>  
              <input v-on:keyup.13="updateRow(customer)" placeholder="Mailchimp id" v-model="customer.mailchimp_id" />
              <v-switch
                v-model="customer.mautic"
                label="Mautic"
              ></v-switch>   
              <input v-on:keyup.13="updateRow(customer)" placeholder="Mautic id" v-model="customer.mautic_id" />  
              <v-combobox
                v-model="customer.fuente" 
                :items="itemsFuente"
                label="Selecciona una fuente"
              ></v-combobox>
        
              <!-- <ejs-combobox 
                v-on:keyup.13="updateRow(customer)" 
                v-model="customer.fuente" 
                :dataSource='dataIFuente' 
                :fields='dataFFuente' 
                placeholder='Seleccione una fuente' 
                popupWidth="250px">
              </ejs-combobox> -->
              <v-combobox
                v-model="customer.status"
                :items="itemsStatus"
                label="Selecciona un Status"
              ></v-combobox>
              <!-- <ejs-combobox 
                v-on:keyup.13="updateRow(customer)" 
                v-model="customer.status" 
                :dataSource='dataIStatus' 
                :fields='dataFStatus' 
                placeholder='Seleccione un Status' 
                popupWidth="250px">
              </ejs-combobox>           -->
              <input v-on:keyup.13="updateRow(customer)" placeholder="Notas" v-model="customer.notes" />
            </div>
          </v-card-text>
          <v-app-bar>
            <div v-if="customer.ver == '1'">        
              <v-chip
              class="ma-2"
              color="green"
              label
              text-color="white"
              >
                Activo
              </v-chip>
            </div>                 
            <div v-else>
              <v-chip
              class="ma-2"
              color="default"
              label
              text-color="white"
              >
                Desactivado
              </v-chip>
            </div>
            <div v-if="customer.notes != null && customer.notes != ''">   
              <div class="text-center">
                <v-dialog
                  v-model="dialog"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="red lighten-2"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      ver nota
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline grey lighten-2">
                      Notas
                    </v-card-title>
                    <v-card-text>
                      {{customer.notes}}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>  
            <div v-if="editRow === customer.id">
              <v-btn v-on:click="updateRow(customer)" class="green">Actualizar</v-btn>
            </div>                                                                     
          </v-app-bar>
        </v-card>
  </v-col>
</template>

<script>
import{ mapMutations } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
// // import FieldSearch from '@/components/FieldSearch.vue'
// import FieldSearch2 from '@/components/FieldSearch2'
// import PricelistItem from '@/components/PricelistItem'
// import ProductForm from "@/components/ProductForm";
import baseApi from '@/services/baseApi';

export default {
  props:{
    customer:{
      type:Object,
      required:true
    }
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    url: function (href){
        return 'detalleventascliente?customerid='+ href
    },    
    async updateMailchimp(href){
    //console.log(href);
     try{        
       //console.log(this.sword2);       
       this.showLoading({titulo:"Actualizando información Mailchimp",color:"warning"})      
       let datos = await baseApi().get(`/mailchimps/updatemembers/` + href);      
       //console.log(datos);
       //console.log(datos.data.success.length);
       if(datos.data[0].success.length >0){
         this.rdesc=datos.data[0].success;
         this.rtitle="success";
       }else{
         this.rdesc="Sin resultados";
         this.rtitle="error";
       }
      }catch(error){
       //console.log(error);
      }  finally{
       this.hiddenLoading()
       this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    },
    async updateMautic(href){
    //console.log(href);
     try{        
       //console.log(this.sword2);       
       this.showLoading({titulo:"Actualizando información Mautic",color:"warning"})      
       let datos = await baseApi().get(`/mautic/updatecontact/` + href);      
       //console.log(datos);
       //console.log(datos.data.success.length);
       if(!datos.data.error){                            
              this.$swal("Genial!",datos.data.message, "success");
       }else{
              this.$swal("Error!",datos.data.message, "error");
       } 
      }catch(error){
       //console.log(error);
      }  finally{
       this.hiddenLoading()
      } 
    },
    async addWooOne(href){
     //console.log(href);
     try{        
       //console.log(this.sword2);       
       this.showLoading({titulo:"Agregar información Woocommerce",color:"primary"})
      //  const baseURL=`https://www.cloudbits.org.mx/fw/lumen/check/woo/customers/createwoo/` + href;
      //  const config = {
      //    method: 'get', //you can set what request you want to be
      //    url:baseURL,
      //    timeout: 90000,
      //    headers: {
      //      'Access-Control-Allow-Origin': '*',
      //      'Access-Control-Allow-Headers': '*',
      //      "Content-Type": "application/json",
      //      "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
      //    }
      //  }
      //  let datos=await axios(config)
       let datos = await baseApi().get(`/woo/customers/createwoo/` + href);
       //console.log(datos);
       //console.log(datos.data.success.length);
       if(!datos.data.error){                            
              this.$swal("Genial!",datos.data.message, "success");
       }else{
              this.$swal("Error!",datos.data.message, "error");
       } 
      }catch(error){
       //console.log(error);
      }finally{
       this.hiddenLoading()
       //this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    },
    async addMautic(href){
     //console.log(href);
     try{        
       //console.log(this.sword2);       
       this.showLoading({titulo:"Agregar información Mautic",color:"purple"})
       let datos = await baseApi().get(`/mautic/addcontact/` + href);
       //console.log(datos);
       //console.log(datos.data.success.length);
       //console.log(datos.data.message);
       if(!datos.data.error){                            
              this.$swal("Genial!",datos.data.message, "success");
       }else{
              this.$swal("Error!",datos.data.message, "error");
       }  
      }catch(error){
       //console.log(error);
      }finally{
       this.hiddenLoading()
       //this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                                             
          baseApi().post(`/clientes/update/${row.id}`,JSON.stringify(row)).then((response)=>{        
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("VENTA: "+response.data.message);
          });              
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        }
    }
  },
  data(){
    return{
      dialog:false,
      dialog2:false,
      editRow: null,
      urlwhats:"https://api.whatsapp.com/send?phone=52",
      itemsStatus: [
          'Customer',
          'Distribuidor',
          'Lead',
      ],
      itemsFuente: [
          'Email',
          'Facebook',
          'Google',
          'Instagram',
          'Mercado Libre',
          'Website',
      ]      
    }
  }
}
</script>
