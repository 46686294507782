<template>
  <div class="leadadd">
        <div>
     <div>
       <h5>Sucursal</h5>
       <input type="text" v-model="branch_id" />
     </div>
     <div>
       <h5>Telegram</h5>
       <input type="text" v-model="telegram_id" />
     </div>      
     <div>
       <h5>email</h5>
       <input type="text" v-model="email" />
     </div>
     <div>
       <h5>Website</h5>
       <input type="text" v-model="website" />
     </div>
     <div>
       <h5>Fuente</h5>
       <input type="text" v-model="fuente" />
     </div>
     <div>
       <h5>Nombre</h5>
       <input type="text" v-model="firstname" />
     </div>
     <div>
       <h5>Apellido</h5>
       <input type="text" v-model="lastname" />
     </div>
     <div>
       <h5>phone</h5>
       <input type="text" v-model="phone" />
     </div>
     <div>
       <h5>mobile</h5>
       <input type="text" v-model="mobile" />
     </div>
     <div>
       <h5>Oficina</h5>
       <input type="text" v-model="office" />
     </div>
     <div>
       <h5>RFC</h5>
       <input type="text" v-model="rfc" />
     </div>
     <div>
       <h5>Domicilio</h5>
       <input type="text" v-model="address" />
     </div>
     <div>
       <h5>Colonia</h5>
       <input type="text" v-model="colonia" />
     </div>
     <div>
       <h5>CP</h5>
       <input type="text" v-model="zip" />
     </div>
     <div>
       <h5>Núm. Ext.</h5>
       <input type="text" v-model="num_ext" />
     </div>
     <div>
       <h5>Núm. Int.</h5>
       <input type="text" v-model="num_int" />
     </div>
     <div>
       <h5>Ciudad</h5>
       <input type="text" v-model="city_id" />
     </div>
     <div>
       <h5>Tipo</h5>
       <input type="text" v-model="tipo" />
     </div>
     <div>
       <h5>Notas</h5>
       <input type="text" v-model="notes" />
     </div>
     <div>
       <v-btn v-on:click="submit">Guardar <v-icon>save</v-icon></v-btn>
     </div>
   </div>
   
  </div>
</template>


<script>
import axios from "axios";
import{ mapMutations } from "vuex";

export default {
  name: "leadadd",
  data: function() {
    return {
       editFriend: null,
       friends: [],
       branch_id: 1,
        telegram_id: 0,
        name: "",
        email: "",
        website: "",
        fuente: "Website",
        firstname:"",
        lastname:"",
        phone:"",
        mobile:"",
        office:"",
        rfc:"",
        colonia:"",
        zip:"",
        notes:"",
        tipo:"Moral",
        address:"",
        num_ext:0,
        num_int:0,
        city_id:1,
       sbrand_id:null,
       ssbrand_id:[
         {value:null,text:"Seleccione una marca"},
         {value:"2",text:"General"},
         {value:"3",text:"General2"},
       ],

       names: [
            {id: 1, value: 'Alice'},
            {id: 1, value: 'Bob'},
            {id: 1, value: 'Simona'}
          ],
       myStudents: {
            names: ['Alice', 'Bob'],
       }
    };                  
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(){
      this.changeBreadcrumbs({
        page:"Nuevo Precio",
        title:"Agregar Nuevo Producto",
        subtitle:"primary",
        description:"Este formulario se agregará nuevo precio del produco seleccionado"
      });
    },
    submit: async function() {
      try{        
        this.showLoading({titulo:"Almacenando información",color:"warning"})
        const baseURL=`https://www.cloudbits.org.mx/fw/lumen/check/prospectos`;        
        const { branch_id,telegram_id,name,email,website,fuente,firstname,lastname,phone,mobile,office,rfc,colonia,zip,notes,tipo,address,num_ext,num_int,city_id  } = this;
        const config = {
          data: JSON.stringify({ branch_id,telegram_id,name,email,website,fuente,firstname,lastname,phone,mobile,office,rfc,colonia,zip,notes,tipo,address,num_ext,num_int,city_id }),
          method: 'post', //you can set what request you want to be          
          url:baseURL,
          timeout: 10000,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            "Content-Type": "application/json",
            "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
          }
        }        
        let datos=await axios(config)    
        //console.log(datos);                
        if(datos.data.length >0){
          this.rows=datos.data          
          this.desserts=datos.data          
        }else{
          this.rows="Sin resultados";
        }

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        this.$swal("Genial!", "Se agregó la información", "success");
      }      
    }
  },
  created(){
    this.getBreadcrumps()    
  }
}

</script>