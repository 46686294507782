<template>
<div id="EventForm">  
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  > 
    <v-row>  
        <v-col
                cols="12"
                sm="6"
                md="4"
        >                                                                                
        </v-col>
        <v-col
                cols="12"
                sm="6"
                md="4"
              >                                                            
        </v-col>
        <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-if="hasMeasurement"
                  v-model="eventInfo.siglas"
                  label="Siglas*"
                  :rules="[
                    required('Siglas')
                  ]"       
                />                
        </v-col>
        <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="eventInfo.name"
                  label="Descripción*"
                  :rules="[
                    required('descripción')
                  ]"       
                ></v-text-field>                
        </v-col>            
        <v-col cols="12">
                <v-autocomplete
                  v-if="hasEvent"
                  v-model="eventInfo.customer_id"
                  :items="customers"                  
                  item-text="razon_social"
                  item-value="id"
                  label="Selecciona un Cliente"  
                  outlined          
                ></v-autocomplete>
        </v-col>
              
        <v-col
                cols="12"
                sm="6"
        >
                <v-text-field
                  v-if="hasEvent"
                  v-model="eventInfo.fecha_inicio"
                  label="Fecha Inicio*"
                  :rules="[
                    required('Fecha Inicio')
                  ]"       
                />                
        </v-col>
        <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                    v-if="hasEvent"
                    v-model="eventInfo.fecha_fin"
                    label="Fecha Fin*"
                    :rules="[
                      required('Fecha Fin')
                    ]"       
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-if="hasEvent || hasBrand"
                  v-model="eventInfo.notes"
                  label="Notas"
                />
              </v-col>
  </v-row>        

  <v-btn
          color="primary"
          class="mr-4"
          @click="submitForm(eventInfo)" 
          :disabled="!valid"
  >
        <v-icon>save</v-icon> {{buttonText}}
  </v-btn>        
  <v-btn
          color="error"
          class="mr-4"
          @click="reset"
        >
          <v-icon>cleaning_services</v-icon> Reset Form
        </v-btn>        
  </v-form>
</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import validations from '@/utils/validation';

export default {
  name: 'EventForm',  
  components: {
  },  
  computed:{
    ...mapState(['customers'])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async getPrice2(){      
      try{       
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        // this.$store.dispatch('loadPricelists',"All"); 
        this.attribute.sword="All";
        this.$store.dispatch('loadCustomers',this.attribute);
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      }
    },
    reset () {
        this.$refs.form.reset()
    }
  },
  props:["submitForm","buttonText",'hasEvent','hasBrand','hasMeasurement',"hasSalesOrder","hasQuote","hasDescription"],
  data(){
     return{
        valid: false,
        ...validations,
        attribute: {sword: '',limit: 27},
        // pricelists:[],
        eventInfo:{ 
          customer_id: '',          
          name:"",
          siglas: '', 
          // stock_syscom:this.product ? this.product.stock_syscom : "0",
          notes:'',
          fecha_inicio:new Date().toISOString().substr(0,10)+" 00:00:00",
          fecha_fin:new Date().toISOString().substr(0,10)+" 00:00:00",
        }
     }
  },
  created(){
    this.getPrice2()    
  }  
}
</script>          
