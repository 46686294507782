
<template>
<div>
  <!-- Busqueda Return -->
  <v-row>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="cars.length > 0">
      <span class="right"><b>{{ cars.length }}</b> registro(s) encontrados <br></span>
      <br><br>
        <!-- Tabla Salida -->
        <template>
              <!-- height="700px" -->
              <v-simple-table
                fixed-header
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr><th class="text-left" v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row,index) of cars"
                      :key="row.id"
                    >
                    <td> 
                        <v-btn v-on:click="getProduct(row.pricelist_id)" icon color="blue">
                          <v-icon>visibility</v-icon>
                        </v-btn>

                        <v-btn v-on:click="editRow = row.id" icon>
                          <v-icon>edit</v-icon>
                        </v-btn>

                        <v-btn
                                    color="red darken-1"
                                    text
                                    v-on:click="deleteRow(row.id)"
                                    icon
                            >                
                              <v-icon>delete</v-icon>
                          </v-btn>
                      </td>    
                      <td>
                        {{index+1}}              
                        <br><br>
                        <div v-if="editRow === row.id">          
                          <v-autocomplete
                                  v-model="row.pricelist_id"
                                  :items="priceslist"
                                  item-text="description"
                                  item-value="id"
                                  label="Selecciona un producto*"
                                  outlined
                          ></v-autocomplete>
                          <v-text-field
                                      v-model="row.posicion"
                                      v-on:keyup.13="updateRow(row)"
                                      label="Posición"
                          />  
                          <v-text-field
                                      v-model="row.price"
                                      v-on:keyup.13="updateRow(row)"
                                      label="Precio"
                          />  
                          <v-text-field
                                      v-model="row.qty"
                                      v-on:keyup.13="updateRow(row)"
                                      label="Cantidad"
                          />          
                          <v-text-field
                                      v-model="row.notes"
                                      v-on:keyup.13="updateRow(row)"
                                      label="Notas"
                          /> 

                          <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
                          <v-btn v-on:click="editRow=false" class="deep-dark">cerrar</v-btn>
                        </div>                          
                      </td>     
                      <td>
                          <div v-if='row.pricelist.filename == ""'>
                                                          <v-img
                                                              lazy-src="https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png"
                                                              height="95"
                                                              src="https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png"
                                                            ></v-img>                                    
                          </div> 
                          <div v-else>
                                                            <v-img
                                                            v-bind:lazy-src="row.pricelist.filename"
                                                            max-height="95"
                                                            width="95"
                                                            v-bind:src="row.pricelist.filename"
                                                            ></v-img>
                          </div>   
                      </td>
                      <td>        
                        <small><b>Posicion:</b> {{row.posicion}}</small><br>        
                        <a :href="row.pricelist.url" target="_blank">{{row.pricelist.description}}</a> <br>  
                        <small><b>SKU:</b> {{row.pricelist_id}}</small><br>        
                      </td>
                      <td>{{row.price | currency }} </td>            
                      <td style="text-align:center; color:red;">- {{row.discount}}%</td>      
                      <td>{{(row.price-(row.price*(row.discount/100))) | currency }} </td>                 
                      <td style="text-align:center;">{{row.qty}}</td> 
                      <td><span style="font-weight:bold; font-size:1.3rem">{{ calculateLineTotal(row) | currency }}</span></td>               
                      <td>{{formatDate(row.created_at)}}</td>     
                      <td>{{formatDate(row.updated_at)}}</td>     
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
        </template>
        <!-- FIN table -->                                   
    </v-col>
    <v-col cols=12 sm=12 md=12 lg=12
      v-else
    >
      <v-alert
          dense
          outlined
          type="error"
      >
          No hay registros encontrados
      </v-alert>
    </v-col>
  </v-row>
  <!-- FIN Busqueda SalesOrder -->  

<!-- DIALOG NOTAS -->
  <div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
  </div>
<!-- FIN DIALOG NOTAS -->
<!-- VISTA PRODUCTO DIALOG-->
  <template>                 
                      <v-dialog
                        v-model="dialogViewProduct"
                        persistent
                        max-width="800px"                 
                      >
                          <v-card>
                            <v-card-title>
                              <span class="headline">Detalle Producto</span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialogViewProduct = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-container v-if='this.dialogViewProduct==true'>
                                <ProductView :productid="productid"  />
                            </v-container>
                            <!-- :pricelist="priceslist -->
                            <!-- buttonText="Guardar"   -->
                          <v-card-actions>
                              <v-btn 
                              
                              color="red" 
                              @click="dialogViewProduct = false"
                              >
                              cerrar
                            </v-btn>
                              <v-spacer></v-spacer>              
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
  </template>                 
<!-- FIN VISTA PRODUCTO  DIALOG-->

</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
import baseApi from '@/services/baseApi';
import ProductView from "@/components/ProductView";

// import router from '@/router';
import Dayjs from 'dayjs';
import es from "dayjs/locale/es"




export default {
  props:{
    cars:{
      type:Object,
      required:true
    }
  },
  computed:{
    //'products'
      ...mapState(['priceslist','currentUser'])
  },
  components: {    
    ProductView
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    formatDate(dateString) {
            Dayjs.locale(es)
            const date = Dayjs(dateString);
            return date.format('dddd MMMM D, YYYY')
    },
    calculateLineTotal(row) {     
            // console.log(row.price);
            // var total = parseFloat(row.price) * parseFloat(row.qty);
            var stotal = parseFloat(row.price) * parseFloat(row.qty);
            var total = parseFloat(stotal-(stotal*(row.discount/100)));                        
            
            if (!isNaN(total)) {
                //row.line_total = total.toFixed(2);
                //total.toFixed(2);                
                //return total.toFixed(2);
                return total;
                
            }
            //return  total;
            //this.calculateTotal(total);
    },  
    async getProduct(ProductID){
      console.log('Producto ID:'+ProductID);
      this.productid=ProductID;
      // alert(this.productid);
      this.dialogViewProduct=true;      
    }, 
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                
          baseApi().post(`/carrito/update/${row.id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              // this.$swal("Genial!","", "success");
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("COTIZACION: "+response.data.message);
          });                                                                                                     
          // console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },    
    deleteRow(id) {      
      // alert(id);
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"});
          this.$swal({
              title: "Desea borrar definitivamente el registro: "+ id+"?",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Borrar",
              denyButtonText: "Cancelar"
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                baseApi().get(`/carrito/delete/${id}`).then((response)=>{
                if(!response.data.error){                            
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
                console.log("COTIZACION: "+response.data.message);
                });
              } else{ //if (result.isDenied) {
                this.$swal("Los datos continuan igual", "", "info");
              }
            });
            
            // if(confirm("Desea borrar definitivamente el registro: "+ id+"?")){
              // alert("Estoy en OK")              
            // }else{
            // alert("NO acepto :(")
            // }          
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },     
    async getArticles(){   
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadPricelists',"All");
      }catch(error){
        //console.log(error);
        this.$swal("Error!", "Necesita ingresar sus credenciales", "error");
      } finally{
        this.hiddenLoading()
      } 
    },
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    },
    
   
    
  },
  destroyed() {
    console.log("Destruido CarItem");
  },
  mounted(){
    console.log("Montado CarItem");
    // if (this.productid>0) {
      this.getArticles()
    // }
  },
  data(){
    return{
      attribute: {sword: '',limit: 500},
      dialogNotes:false,
      dialogViewProduct:false,
      editRow:null,
      editRowProjectID:null,
      productid:0,
      employee_id:'',      
      notes:'',
      itemsStatus: [
          'Proceso',
          'Cancelada',
          'Autorizada',
      ],   
      theader: [
          { text: 'Accion', value: 'customername' },
          { text: '#', value: 'customername' },
          { text: 'ID', value: 'customername' },
          { text: 'Folio Proyecto', value: 'customername' },
          { text: 'Empleado', value: 'customername' },
          { text: 'Total', value: 'total_quote ' },       
          { text: 'Status', value: 'total_quote ' },       
          { text: 'Devolucion', value: 'iva_quote' },
          { text: 'Registro', value: 'iva_quote' },
          { text: 'Actualizacion', value: 'iva_quote' },
      ],
    }
  }
}
</script>