<template>
 <div class="ReportMonth">
    <h5>Buscar:</h5>  
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >     
      <v-row>
        <v-col cols=12 sm=12 xs=12 md=4 lg=4>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date_start"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_start"
                    label="Fecha de Inicio"
                    prepend-icon="mdi-calendar"                    
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                  <!-- readonly -->
                </template>
                <v-date-picker
                  v-model="date_start"
                  locale="es-mx"                  
                >
                <!-- scrollable -->
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date_start)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols=12 sm=12 xs=12 md=4 lg=4>
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="date_end"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_end"
                    label="Fecha de Fin"
                    prepend-icon="mdi-calendar"                    
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                  <!-- readonly -->
                </template>
                <v-date-picker
                  v-model="date_end"
                  locale="es-mx"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog2.save(date_end)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols=12 sm=12 xs=12 md=4 lg=4>
              <v-btn v-on:click="submit"  color="success"><v-icon>mdi-magnify</v-icon> Buscar</v-btn>            
            </v-col>
      </v-row>
    </v-form>
  <v-row>
    <v-col cols="12" sm="12"  md="6" lg="6" v-if="saldo.length > 0" >     
        <Saldo />                
    </v-col>
    <v-col cols="12" sm="12" md="6" lg="6" v-else >
          <v-alert
            dense
            outlined
            type="error"
          >
            No hay registros encontrados con esta busqueda
          </v-alert>
    </v-col>
    <v-col cols="12" sm="12" md="6" lg="6">    
      <Kpis />          
    </v-col>
  </v-row>
  <v-row>
              <v-btn
                  color="darken-1"
                  dark
                  @click="saldoStock('Stock')"
              >
                  Saldo en Stock
              </v-btn>                    
              <v-btn
                  color="secondary"
                  dark
                  @click="bestProduct('cantidad')"
              >
                  Por Cantidad
              </v-btn>                    
              <v-btn
                  color="primary darken-1"
                  dark
                  @click="bestProduct('total')"
              >
                  Por Venta Total
              </v-btn>              
              <v-btn
                  color="primary darken-2"
                  dark
                  @click="bestCustomer('total')"
              >
                  Mejor Cliente
              </v-btn>              
              <v-btn
                  color="primary darken-2"
                  dark
                  @click="$router.back()"
              >
                  Back
              </v-btn>              
  </v-row>
  <v-row><h6>Saldo a la Compra:<b>{{this.total | currency }}</b></h6></v-row>
  <v-row><h6>Saldo a la Venta:<b>{{this.mtotal | currency }}</b></h6></v-row>
    <v-row>
      <h5>Productos Más Vendidos | {{sttitle}}</h5>  
      <table>    
      <thead class="thead-dark">
        <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
      </thead>
      <tr v-for="(row,index) in brows" :key="index">
        <td></td>
        <td>
          <span v-if='row.name'>{{row.name}}<br></span>
          <span v-if='row.customerfullname'>
            <a v-bind:href="url(row.id)">
              {{row.customerfullname}}
            </a>
            <br>
          </span>
          <span v-if='row.sku'><b>{{row.sku}}</b></span><br>          
          <span v-if='row.price' style="font-size:1rem; ">Precio de Lista: {{row.price | currency }}<br></span>
          <span style="font-size:1.6rem; color:brown;">{{row.total | currency }}</span><br>
          <!--
          <span v-if='row.price'>Inventario: </span>
          <span v-else-if='row.cantidad'>Ventas: </span>
          --> 
                        
              <!-- Inventario o Ventas -->
              <v-chip
              v-if='row.cantidad > row.min_stock'
              class="ma-2"
              color="green"
              label
              text-color="white"
              >
                <v-icon left>production_quantity_limits</v-icon>
                {{row.cantidad }}
              </v-chip>
              <v-chip
              v-else-if='row.cantidad <= row.min_stock'
              class="ma-2"
              color="green"
              label
              text-color="white"
              >
                <v-icon left>production_quantity_limits</v-icon>
                {{row.cantidad }}
              </v-chip>
              <v-chip
              v-else
              class="ma-2"
              color="black"
              label
              text-color="white"
              >
                <v-icon left>publish</v-icon>
                {{row.cantidad }}
              </v-chip> 
              <!-- FIN Inventario o Ventas -->

              <v-chip
                v-if='row.min_stock'                
                class="ma-2"
                color="red"
                label
                text-color="white"
                >
                  <v-icon left>inventory</v-icon>
                  {{row.min_stock}}
              </v-chip>  
                   
              <!-- v-if='row.camino > 0' -->
              <v-chip       
                v-if='row.camino > 0'         
                class="ma-2"
                color="blue"
                label
                text-color="white"
                >
                  <v-icon left>local_shipping</v-icon>
                  {{row.camino }}
              </v-chip>                    
                   
        </td> 
      </tr>
      </table> 
    </v-row>
  </div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import baseApi from '@/services/baseApi';
import Saldo from "@/components/Saldo.vue";
import Kpis from "@/components/Kpis.vue";
//import ProductItem from "@/components/ProductItem";


export default{
  name: 'ReportMonth',
  computed:{
    ...mapState(['currentUser','saldo','kpis','salesorders','purchases','expensives'])
  },
  components: {    
    Saldo,
    Kpis
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    url: function (href){
        return 'detalleventascliente?customerid='+ href
    },    
    submit: async function() {
        const { date_start,date_end } = this;  
        this.attribute.date_start=date_start;            
        this.attribute.date_end=date_end;        
        try{
          this.showLoading({titulo:"Accediendo información",color:"warning"})                
          await this.$store.dispatch('loadSaldo',this.attribute);           
          await this.$store.dispatch('loadKpis',this.attribute);  
          this.attribute.sword="";          
          await this.$store.dispatch('loadSalesOrders2',this.attribute); 
          await this.$store.dispatch('loadPurchases2',this.attribute);
          await this.$store.dispatch('loadExpensives2',this.attribute);       

        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
        } 
    },        
    async getBreadcrumps(){
      //Error al cargar por primera vez cuando cambias de usuario
      //let branch_id=this.currentUser.branch_id;        
      //alert (branch_id); 
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        //console.log(sword)
        // await this.$store.dispatch('loadSaldo',this.attribute); 
        //?valor=2022-01-01|2022-12-31&branch_id=1
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    async bestProduct(stipo){
      try{        
         //console.log(this.sword2);       
         this.showLoading({titulo:"Recabando información",color:"warning"})
         let datos = await baseApi().get(`/reporte/BestProduct?tipo=${stipo}`);
         //console.log(datos.data[0].id>=0);
         if(datos.data[0].id>=0){
            this.rdesc="Se obtuvo la información";
            this.rtitle="success";
            this.brows=datos.data;
            this.sttitle=stipo;
         }else{
           this.rdesc="Sin resultados";
           this.rtitle="error";
         }
        }catch(error){
         //console.log(error);
        }finally{
         this.hiddenLoading()
         this.$swal("Genial!", this.rdesc, this.rtitle);
        }
    },    
    async bestCustomer(stipo){
      try{
         this.showLoading({titulo:"Recabando información",color:"warning"})
         //let branchID=this.currentUser.branch_id;         
         let rango ="2019-01-01|2024-01-01"
         let datos = await baseApi().get(`/reporte/BestCustomer?tipo=Customer&valor=${rango}&sort=${stipo}`); 
         //console.log(datos.data[0].id>=0);
         if(datos.data[0].id>=0){
            this.rdesc="Se obtuvo la información";
            this.rtitle="success";
            this.brows=datos.data;
            this.sttitle=" "+rango;
         }else{
           this.rdesc="Sin resultados";
           this.rtitle="error";
         }
        }catch(error){
         //console.log(error);
        }finally{
         this.hiddenLoading()
         this.$swal("Genial!", this.rdesc, this.rtitle);
        }
    },        
    async saldoStock(stipo){
      try{        
         //console.log(this.sword2);       
         this.showLoading({titulo:"Recabando información",color:"warning"})
         this.total=0;
         this.mtotal=0;
         let datos = await baseApi().get(`/stock/getall?tipo=${stipo}`);
         //console.log(datos.data[0].id>=0);
         if(datos.data[0].id>=0){                        
            this.rdesc="Se obtuvo la información";
            this.rtitle="success";
            this.brows=datos.data;
            this.sttitle=stipo;
            for (var i = 0; i < this.brows.length; i+=1) {
              this.total=(this.total + (this.brows[i].total*1));  //Se multiplica con uno para que lo convierta en numero
              this.mtotal=(this.mtotal + (this.brows[i].total_margen*1));            
            }            
         }else{
           this.rdesc="Sin resultados";
           this.rtitle="error";
         }
        }catch(error){
         //console.log(error);
        }finally{
         this.hiddenLoading()
         this.$swal("Genial!", this.rdesc, this.rtitle);
        }
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Reporte Mensual",
        title:"Reporte Mensual",
        subtitle:"primary",
        description:"En este reporte observará las compras, ventas y gastos mensuales"
       });
    this.getBreadcrumps(this.attribute.sword)
  }, 
  data(){
    return{
      attribute: {sword: 'All',limit: 500,date_start:'',date_end:''},
      valid: true,
      //...validations,
      dialog:false,      
      dialog2:false,      
      modal:'',
      modal2:'',
      stipo:"",
      sttitle:"",
      total:0,
      mtotal:0,      
      date_start:new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substr(0,10),
      date_end:new Date().toISOString().substr(0,10),
      theader: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descripción', value: 'customername' },  
      ],      
    }
  }
}
</script>
<style>
  .v-date-picker-table {    
    height: auto;
  }
</style>