<template>
  <div id="CustomerEdit">
    <!-- success msg component 
    <Success :msg="msg" v-show="success" />
    -->
    <!-- edit user which has user data as prop -->
    <CustomerForm :user="user" :submitForm="updateRow" hasCustomer=true buttonText="Guardar" />

  </div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import CustomerForm from "@/components/CustomerForm";
//import Success from "./Success";
import baseApi from '@/services/baseApi';

export default {
  name: "CustomerEdit",
  computed:{
    ...mapState(['currentUser']),    
  },
  components: {
    CustomerForm,
    //Success,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      success: false,
      msg: "",
    };
  },
  methods: {
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    async updateRow(customerInfo){
      //updateRow(user) {     
      //alert('hola: '+ customerInfo.id) ;
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                                             
          baseApi().post(`/clientes/update/${customerInfo.id}`,JSON.stringify(customerInfo)).then((response)=>{        
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
          });              
        }catch(error){
          console.log(error);
        }finally{
          this.hiddenLoading()
        }
    }
  },
};
</script>