<template>
  <div class="customers"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row >
            <v-col>
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      @keyup="searchCustomer()"
                      autofocus
              ></v-text-field>

              <v-radio-group
                v-model="article"
                row
              >
                <v-radio
                  label="Ninguno"
                  value="Ninguno"
                ></v-radio>
                <v-radio
                  label="Todos"
                  value="All"
                  v-on:click="getBreadcrumps(sword)"
                ></v-radio>      
              </v-radio-group>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

    <!-- FIN Dialog confirmación -->
<v-card
    flat
    class="py-12"
  >
    <v-card-text >
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
          v-model="toggle_exclusive"
          mandatory
        >                
        <template>              
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn 
                  @click="addMailchimp" 
                  class="yellow"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon >upload</v-icon>
                </v-btn>                                  
              </template>
              <span>Subir Clientes a Mailchimp</span>
            </v-tooltip>
        </template> 

        <template>              
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn 
                  @click="syncMailchimp" 
                  class="yellow"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon >download</v-icon>
                </v-btn>                                  
              </template>
              <span>Descargar Clientes de Mailchimp</span>
            </v-tooltip>
        </template> 

        <template>              
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn 
                  @click="syncCustomerMautic" 
                  class="purple"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon >upload</v-icon>
                </v-btn>                                  
              </template>
              <span>Subir Clientes a Mautic</span>
            </v-tooltip>
        </template> 

        <template>              
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn 
                  @click="syncMautic" 
                  class="purple"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon >download</v-icon>
                </v-btn>                                  
              </template>
              <span>Descargar Clientes de Mautic</span>
            </v-tooltip>
        </template> 

        <template>              
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn 
                  @click="syncWoocommerce" 
                  class="blue"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon >download</v-icon>
                </v-btn>                                  
              </template>
              <span>Descargar Clientes de Woocommerce</span>
            </v-tooltip>
        </template> 

        
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog2"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="darken-2"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>star</v-icon>
                      </v-btn>
                    </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Mejores Compradores | {{sttitle}}</span>
                          <v-btn
                                      class="headline"
                                      color="blue darken-1"
                                      text
                                      @click="dialog2 = false"
                                    >
                                      <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <div v-for="brow in brows" :key="brow" class="col xs4 sm4 md4 lg4">
                                  <br>
                                  <b>Cliente ID:</b> {{brow.id}}<br> 
                                  {{brow.customerfullname}} <br>
                                  <b>Total:</b> {{ brow.total | currency }}
                                  <br>
                                  <a v-bind:href="url(brow.id)" target="_blank">
                                    <v-icon>visibility</v-icon>
                                  </a> 
                                </div>
                                <hr>
                              </v-col>              
                            </v-row>
                          </v-container>
                          <small>*Bajo Condiciones</small>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="bestCustomer('total')"
                          >
                            Mostrar Clientes
                          </v-btn>                    

                          <v-btn
                            color="blue darken-1"
                            text
                            @click="starCustomer('total')"
                          >
                            Rank Clientes
                          </v-btn>                    
                        </v-card-actions>
                      </v-card>                    
                  </v-dialog>
                </v-btn>                
              </template>
              <span>Mejores Compradores</span>
            </v-tooltip>
        </template>   
        
        <!-- Dialog para agregar Cliente --> 
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>group_add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Cliente</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <CustomerCreate  />
                             <!--
                            <ProductForm :submitForm="AddPricelist" buttonText="Guardar" hasProduct=true hasDescription=true />
                             -->
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Nuevo Cliente</span>
            </v-tooltip>
        </template> 
        <!-- fin Dialog -->
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
        </template>
          
          
        </v-btn-toggle>
      </v-row>
    </v-card-text>
  </v-card>






<v-row>
  <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        v-if="customers.length > 0"
  >
    <v-row>
        <CustomerItem :customer="customer" v-for="customer in filteredCustomers" :key="customer.id" />
    </v-row>
  </v-col>
  <v-col 
        cols="12"
        sm="12"
        md="12"
        lg="12"
        v-else
  >
        <v-alert
          dense
          outlined
          type="error"
        >
          No hay registros encontrados con esta busqueda
        </v-alert>
  </v-col>
</v-row>
        
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->


  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import CustomerItem from '@/components/CustomerItem'
import CustomerCreate from "@/components/CustomerCreate";
import baseApi from '@/services/baseApi';

export default {
  name: 'customers',
  computed:{
    ...mapState(['customers','currentUser'])    
  },
  components: {
    CustomerItem,
    CustomerCreate 
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),        
    async getBreadcrumps(sword){
      this.attribute.sword=sword;      
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadCustomers',this.attribute);
        this.filteredCustomers=this.customers;
        
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    url: function (href){
        return 'detalleventascliente?customerid='+ href
    }, 
    searchCustomer() {
      //console.log(this.customers)
      this.filteredCustomers = this.customers.filter(
        (customer) =>
          customer.razon_social.toLowerCase().includes(this.sword.toLowerCase())
      );
    },
    async bestCustomer(stipo){
      try{
         this.showLoading({titulo:"Recabando información",color:"warning"})
        //  let branchID=this.currentUser.branch_id;         
        //  let rango ="2019-01-01|2024-01-01"
         let rango =this.attribute.date_start+"|"+this.attribute.date_end;
         let datos = await baseApi().get(`/reporte/BestCustomer?tipo=Customer&valor=${rango}&sort=${stipo}`); 
         //console.log(datos.data[0].id>=0);
         if(datos.data[0].id>=0){
            this.rdesc="Se obtuvo la información";
            this.rtitle="success";
            this.brows=datos.data;
            this.sttitle=" "+rango;
         }else{
           this.rdesc="Sin resultados";
           this.rtitle="error";
         }
        }catch(error){
         //console.log(error);
        }finally{
         this.hiddenLoading()
         this.$swal("Genial!", this.rdesc, this.rtitle);
        }
    },        
    async starCustomer(stipo){
      try{
         this.showLoading({titulo:"Recabando información",color:"warning"})
        //  let branchID=this.currentUser.branch_id;         
        //  let rango ="2019-01-01|2024-01-01"
         let rango =this.attribute.date_start+"|"+this.attribute.date_end;
         let datos = await baseApi().get(`/reporte/BestCustomer?valor=${rango}&sort=${stipo}`); 
         //console.log(datos.data[0].id>=0);
         if(datos.data[0].id>=0){
            this.rdesc="Se obtuvo la información";
            this.rtitle="success";
            this.brows=datos.data;
            this.sttitle=" "+rango;
         }else{
           this.rdesc="Sin resultados";
           this.rtitle="error";
         }
        }catch(error){
         //console.log(error);
        }finally{
         this.hiddenLoading()
         this.$swal("Genial!", this.rdesc, this.rtitle);
        }
    },        
    async addMailchimp(){
     try{        
       //console.log(this.sword2);       
       this.showLoading({titulo:"Subir Clientes a Mailchimp",color:"default"})
       let response = await baseApi().get(`/mailchimps/addmembers`);
        if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
        }else{
              this.$swal("Error!",response.data.message, "error");
              // this.$swal("Error!",JSON.stringify(response.data.message), "error");                           
        }     
       //console.log(datos.data[0].success);
       //console.log(datos.data.success.length);
      //  if(datos.data[0].success.length >0){
      //    this.rdesc=datos.data[0].success;
      //    this.rtitle="success";
      //  }else{
      //    this.rdesc="Sin resultados";
      //    this.rtitle="error";
      //  }
      }catch(error){
       //console.log(error);
      }finally{
       this.hiddenLoading()
       //this.$swal("Genial!", this.rdesc, this.rtitle);
      }
    },
    async syncMailchimp(){
      try{        
         //console.log(this.sword2);       
         this.showLoading({titulo:"Descargando Clientes de Mailchimp",color:"success"})
         let response = await baseApi().get(`/mailchimps/syncmembers`); 
         //console.log(datos.data.success.length);
         if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
          }else{
              this.$swal("Error!",response.data.message, "error");
          }  
        }catch(error){
         //console.log(error);
        }finally{
         this.hiddenLoading()
        }
    },
    async syncMautic(){
      try{        
         //console.log(this.sword2);       
         this.showLoading({titulo:"Descargando Clientes de Mautic",color:"success"})
        //  let response = await baseApi().get(`/mautic/getcontacts`); 
         let response = await baseApi().get(`/mautic/syncmembers`); 
         //console.log(datos.data.success.length);
          if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
          }else{
              this.$swal("Error!",response.data.message, "error");
          }  
        }catch(error){
         //console.log(error);
        }finally{
         this.hiddenLoading()
        }
    },

    async syncCustomerMautic(){
      try{        
         //console.log(this.sword2);       
         this.showLoading({titulo:"Subiendo Clientes a Mautic",color:"success"})
        //  let response = await baseApi().get(`/mautic/getcontacts`); 
         let response = await baseApi().get(`/mautic/synccustomers`); 
         //console.log(datos.data.success.length);
          if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
          }else{
              this.$swal("Error!",response.data.message, "error");
          }  
        }catch(error){
         //console.log(error);
        }finally{
         this.hiddenLoading()
        }
    },
    async syncWoocommerce(){
      try{
         this.showLoading({titulo:"Descargando Clientes de Woocommerce",color:"success"})
        //  const baseURL=`https://www.cloudbits.org.mx/fw/lumen/check/woo/customers/create`;
        //  const config = {
        //    method: 'get', //you can set what request you want to be
        //    url:baseURL,
        //    timeout: 90000,
        //    headers: {
        //      'Access-Control-Allow-Origin': '*',
        //      'Access-Control-Allow-Headers': '*',
        //      "Content-Type": "application/json",
        //      "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
        //    }
        //  }
        //  let datos=await axios(config) 
         let datos = await baseApi().get(`/woo/customers/create`);
         //console.log(datos.data[0].success);
         //console.log(datos.data.success.length);
         if(datos.data.success.length >0){
           this.rdesc=datos.data.success;
           this.rtitle="success";
         }else{
           this.rdesc="Sin resultados";
           this.rtitle="error";
         }
        }catch(error){
         //console.log(error);
        }finally{
         this.hiddenLoading()
         this.$swal("Genial!", this.rdesc, this.rtitle);
        }
    }
  },  
  created(){
    this.changeBreadcrumbs({
        page:"Clientes",
        title:"Lista de Clientes",
        subtitle:"primary",
        description:"En esta lista encontrará todos los clientes dados de alta en el sistema desde el principio de los tiempos"
       });
    this.getBreadcrumps(this.sword)    
  },
  data(){
     return{
      dialog: false,
      dialog2:false,
      attribute: {
        sword: '',
        date_start:'2019-01-01',
        date_end:new Date().toISOString().substr(0,10),
        // tipo:'product',
        // pricelistid:'',
        limit: 27
      },
      filteredCustomers: [],
      sword:"",
      stipo:"",
      article:'',
      toggle_exclusive:'',
      sttitle:"",
      brows:{},



    }
  }
}
</script>
