<template>
  <div id="CustomerCreate">
    <!-- success msg component 
    <Success :msg="msg" v-show="success" />
    <CustomerForm :user="user" @submit-form="saveUser" hasCustomer=true buttonText="Guardar" />
     -->
    <CustomerForm :user="user" :submitForm="addRow" hasCustomer=true buttonText="Guardar" />
  </div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import CustomerForm from "@/components/CustomerForm";
//import Success from "./Success";
import baseApi from '@/services/baseApi';
import router from '@/router';


export default {
  name: "CustomerCreate",
  computed:{
    ...mapState(['currentUser']),    
  },
  components: {
    CustomerForm,
    //Success,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      success: false,
      msg: "",
    };
  },
  methods: {
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    async addRow(customerInfo){
      try{        
        this.showLoading({titulo:"Almacenando información",color:"warning"})
        let branch_id=this.currentUser.branch_id;
        let woo_id=1;
        //let woo_id=customerInfo.woo_id;
        //let telegram_id=customerInfo.telegram_id;
        let telegram_id=1;
        let rfc=customerInfo.rfc;
        let title=customerInfo.title;
        alert(title);
        let razon_social=customerInfo.razon_social;
        let city_id=1;
        //let city_id=customerInfo.city_id;
        let address=customerInfo.address;
        let colonia=customerInfo.colonia;
        let num_ext=customerInfo.num_ext;
        let num_int='0';
        //let num_int=customerInfo.num_int;
        let zip=customerInfo.zip;
        let phone=customerInfo.phone;
        let office=customerInfo.office;
        let mobile=customerInfo.mobile;
        let email=customerInfo.email;
        let website='';
        //let website=customerInfo.website;
        let fuente=customerInfo.fuente;
        let tipo=customerInfo.tipo;
        let status=customerInfo.status;
        let notes=customerInfo.notes;
        //const { woo_id,telegram_id, rfc, title, razon_social, city_id, address, colonia,num_ext,num_int,zip,phone,office,mobile,email,website, fuente,tipo, notes,status } = this;        
        await baseApi().post(`/clientes`,JSON.stringify({ branch_id,woo_id,telegram_id, rfc, title, razon_social, city_id,address,colonia,num_ext,num_int,zip,phone,office,mobile, email, website,fuente,tipo, notes,status })).then((response)=>{
              if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
              }else{
                // this.$swal("Error!",response.data.message, "error");
                this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
              }                                     
              // console.log("VENTA: "+response.data.message);
        });    

      }catch(error){
        if (error.message=='Request failed with status code 500') {
          this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
        }else if(error.message=='Request failed with status code 401'){
          this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
          router.push('/login');
        }else{
          this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
        }
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      }      
    
      //this.success = true;
      //this.msg = "User added";
      //console.log("User info saved");
    },
  },
};
</script>