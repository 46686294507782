<template>
  <div class="priceadd" >
    <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="firstname"
              label="Nombre"              
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="lastname"
              label="Apellidos"
            ></v-text-field>
          </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="address"
          label="Domicilio"              
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="colonia"
          label="Colonia"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
        <v-col
        cols="12"
        sm="6"
        >
        <v-text-field
        v-model="phone"
        label="Telefono"
        ></v-text-field>
        </v-col>
        <v-col
        cols="12"
        sm="6"
        >
        <v-text-field
        v-model="mobile"
        label="Celular"
        ></v-text-field>
        </v-col>
      </v-row>

    <v-row>
      <input type="text" v-model="email" placeholder="Email"/>
    </v-row>      

      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-combobox
            v-model="tipo"
            :items="itemsType"
            label="Selecciona un Tipo"
          ></v-combobox>
          <!-- <ejs-combobox v-model="tipo" :dataSource='dataItem' :fields='dataFields' placeholder='Seleccione un tipo' popupWidth="250px"> </ejs-combobox>  -->
        </v-col>      
        <v-col
          cols="12"
          sm="6"
        >
          <v-combobox
            v-model="puesto"
            :items="itemsPuesto"
            label="Selecciona un Puesto"
          ></v-combobox>
          <!-- <ejs-combobox v-model="puesto" :dataSource='dataPuesto' :fields='dataFPuesto' placeholder='Seleccione un puesto' popupWidth="250px"> </ejs-combobox>  -->
        </v-col>
      </v-row>
      <div>
        <h5>Notas</h5>
        <input type="text" v-model="notes" />
      </div> 

      <v-btn v-on:click="submit">Guardar <v-icon>save</v-icon></v-btn>
  </div>
</template>


<script>
import validations from '@/utils/validation';
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import baseApi from '@/services/baseApi';

export default {
  name: "employeeadd",
  computed:{
    ...mapState(['currentUser'])    
  },     
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(){
      this.changeBreadcrumbs({
        page:"Nuevo Precio",
        title:"Agregar Nuevo Empleado o Aspirante",
        subtitle:"primary",
        description:"Este formulario se agregará nuevo empleado o aspirante"
      });
    },
    submit: async function() {
      try{        
        this.showLoading({titulo:"Almacenando información",color:"warning"})
        let branch_id=this.currentUser.branch_id;        
        const { rfc, firstname, lastname, city_id, address, colonia,num_ext,num_int,zip,phone,office,mobile,email,tipo,puesto, notes } = this;
        await baseApi().post(`/empleados`,JSON.stringify({ branch_id,rfc, firstname, lastname, city_id,address,colonia,num_ext,num_int,zip,phone,office,mobile, email, tipo,puesto, notes })).then((response)=>{
              //if (response.data.error==true){
              if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
              }else{
                console.log("VENTA: "+response.data.message);
                console.log("VENTA l: "+response.data.message.length);
                console.log("VENTA b: "+response.data.message.branch_id);
                console.log("VENTA b: "+response.data.message.firstname);

                if (response.data.message.firstname) {
                  //for (var i = 0; i < message.length; i+=1) {   }
                  this.$swal("Error!",""+response.data.message.firstname, "error");
                }else if(response.data.message.lastname) {
                  this.$swal("Error!",""+response.data.message.lastname, "error");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                
              }                                    
              
        });    

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      }              
    }
  },
  created(){
    this.getBreadcrumps()    
  },
  data: function() {
    return {
      ...validations,
       id:'',
       rfc:'',
       firstname: '',
       lastname: '',
       city_id:1,
       address:'',
       colonia:'',
       num_ext:0,
       num_int:0, 
       zip:"",        
       phone:'',
       office:'',
       mobile:'',
       email:'',
       website:'',
       tipo:"Bolsa de Trabajo",       
       puesto:"Vendedor",
       notes:'',
       itemsType: [
          'Bolsa de Trabajo',
          'Contratado',
       ],          
       itemsPuesto: [
            'TI',
            'Marketing Digital',
            'Electricista',
            'Vendedor',
            'Contador',
            'Mecatrónica',
            'Recursos Humanos',
       ],             
    };                  
  }
}

</script>