<template>
  <div class="plugin"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row :class="sm-6" >
            <v-col>
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

<v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <router-link to="/plugin-add">
                    <v-btn                  
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </router-link>
                  </template>
                  <span>Agregar Nuevo Plugin</span>
                </v-tooltip>
            </template>                               
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>
  

  
<v-row>
  <v-col cols="12"    sm="12"      md="12"      lg="12"    
    v-if="plugins.length > 0"
  >
    <v-row>
        <PluginItem :plugin="plugin" v-for="plugin in plugins" :key="plugin.id" />
    </v-row>
  </v-col>
  <v-col cols="12"        sm="12"        md="12"        lg="12"        v-else  >
        <v-alert
          dense
          outlined
          type="error"
        >
          No hay registros encontrados con esta busqueda
        </v-alert>
  </v-col>
</v-row>


  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import PluginItem from '@/components/PluginItem'
// import baseApi from '@/services/baseApi';


export default {
  name: 'plugin',
  components: {
    PluginItem  
  },
  computed:{
    ...mapState(['currentUser','plugins'])
  },  
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    async getBreadcrumps(sword){
      this.attribute.sword=sword;
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadPlugins',this.attribute);
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    }
  },
  created(){
    this.changeBreadcrumbs({
        page:"Plugins",
        title:"Lista de Plugins",
        subtitle:"primary",
        description:"En esta lista encontrará todos los plugins dados de alta en el sistema desde el principio de los tiempos"
    });
    this.getBreadcrumps(this.sword)    
  },
  data(){
     return{
      attribute: {sword: '',limit: 27},
      sword:"",            
      editRow:[],
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: '#', value: 'index' },
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Sucursal', value: 'branch_id' },
        { text: 'Type', value: 'notes' },
        { text: 'Title', value: 'title' },
        { text: 'Host', value: 'razon_social' },
        { text: 'Port', value: 'rfc' },
        { text: 'Protocol', value: 'address' },
        { text: 'Encryption', value: 'num_ext' },
        { text: 'Channel', value: 'num_int' },
        { text: 'Email', value: 'cp' },
        { text: 'Username', value: 'phone' },
        { text: 'Pass', value: 'mobile' },        
        { text: 'Code', value: 'mobile' },        
        { text: 'Key', value: 'email' },        
        { text: 'Key ID', value: 'notes' },        
        { text: 'Key Secret', value: 'notes' },        
        { text: 'Token 1', value: 'notes' },
        { text: 'Token 2', value: 'notes' },
        { text: 'Refresh Token', value: 'notes' },
      ],
    }
  },
}
</script>