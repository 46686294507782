<template>
  <div id="ProductPurchaseCreate">
    <!-- success msg component 
    <Success :msg="msg" v-show="success" />
    <ProductForm :user="user" @submit-form="saveUser" hasCustomer=true buttonText="Guardar" />
     -->
    <ProductForm :user="user" :submitForm="addRow" buttonText="Guardar" hasPurchase=true />

  </div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import ProductForm from "@/components/ProductForm";
//import Success from "./Success";
import baseApi from '@/services/baseApi';


export default {
  name: "ProductPurchaseCreate",
  computed:{
    ...mapState(['currentUser']),    
  },
  components: {
    ProductForm,
    //Success,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      success: false,
      msg: "",
    };
  },
  methods: {
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    async addRow(ProductInfo){
      // alert("Agregar Product");
      // alert (ProductInfo.quote_id);
      // alert (ProductInfo.pricelist_id);
      // alert (ProductInfo.cantidad);
      // alert (ProductInfo.price);
      try{ 
        this.showLoading({titulo:"Almacenando información",color:"warning"})        
        //let purchase_id=ProductInfo.purchase_id;
        let purchase_id=this.$route.query.purchaseid; //this.rows[0].quote_id
        let pricelist_id=ProductInfo.pricelist_id;        
        let price=ProductInfo.price;
        let cantidad=ProductInfo.cantidad;
        let posicion=ProductInfo.posicion;
        let notes=ProductInfo.notes;
        baseApi().post(`/detallecompra`,JSON.stringify({ purchase_id,pricelist_id,posicion,price,cantidad,notes })).then((response)=>{        
            if(!response.data.error){                            
              // this.$swal("Genial!","", "success");
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("VENTA: "+response.data.message);
        });
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se agregó la información", "success");
      } 
    },
  },
};
</script>