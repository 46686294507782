
<template>
  <div class="detailsinvoice"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <h5>Buscar:</h5>
              <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

  <v-row>
    <v-col cols=12 sm=12 md=6 lg=6>      
       <p style="text-align:left; font-size:1.1rem;"><b>Folio: </b> {{ folio }}</p>
       <p style="text-align:left; font-size:1.1rem;"><b>Cliente: </b> {{ customerfullname }}</p>
       <p style="text-align:left; font-size:1.1rem;"><b>Fecha: </b> {{ date_invoice }}</p>              
    </v-col>
    <v-col cols=12 sm=12 md=6 lg=6>      
      <h5>Notas:</h5>
      {{invoicenotes}}
    </v-col>    
  </v-row>

  <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog2"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon color="white">edit</v-icon> 
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Editar Cliente: {{ customerfullname }}</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog2 = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <CustomerEdit :user="user" />
                            <!--
                              <CustomerForm :submitForm="DetailCustomer" :user="user" hasCustomer=true buttonText="Guardar"  /> 
                            -->                      
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Editar Cliente</span>
            </v-tooltip>
          </template>  

<!-- Dialog Confirmación -->

<!-- FIN Dialog confirmación -->

<!-- Tools -->
<v-card
    flat
    class="py-12"
  >
    <v-card-text>
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
          v-model="toggle_exclusive"
          mandatory
        >
          <template>  
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">              
                  <v-btn
                    @click="downloadPDF"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>picture_as_pdf</v-icon>
                  </v-btn>
                </template>
                <span>Descargar Venta en PDF</span>
              </v-tooltip>
          </template>                         
          <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="orderByAsc"                  
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>format_list_numbered</v-icon>
                </v-btn>
              </template>
              <span>Ordenar la lista de productos</span>
            </v-tooltip>
          </template>    
          
          <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>add</v-icon> 
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Producto / Servicio</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <ProductForm :submitForm="DetailProduct" hasSalesOrder=true buttonText="Guardar"  />                       
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Producto</span>
            </v-tooltip>
          </template>  
          <!-- Dialog para agregar --> 
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
        </template>
          
        </v-btn-toggle>
      </v-row>
    </v-card-text>
  </v-card>


<!-- Lado Izquierdo -->
<v-row v-if="rows.length > 0">
  <v-col cols="12" xs="12" sm="12" md="8" lg="10" xl="10">    
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
      </thead>     
      <tr v-for="(row) of rows" :key="row">       
        <td>          
          <template>  
                      <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">              
                              <v-btn
                                color="red"
                                x-small
                                fab
                                @click="deleteRow(row.id)"
                                v-bind="attrs"
                                v-on="on"                                
                              >
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Borrar Producto</span>
                          </v-tooltip>
                      </template> 
                      
                      <template v-if="row.notes != null && row.notes != ''"> 
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">              
                              <v-btn
                                color="indigo"
                                x-small
                                fab
                                @click="alert('falta agregar nota')"                  
                                v-bind="attrs"
                                v-on="on"                                
                              >
                                <v-icon>note</v-icon>
                              </v-btn>
                            </template>
                            <span>Ver nota</span>
                          </v-tooltip>
                      </template>   
                      
                      <template>  
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">              
                              <v-btn
                                color="secondary"
                                x-small
                                fab
                                @click="editRow = row.id"
                                v-bind="attrs"
                                v-on="on"                                
                              >
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar Producto</span>
                          </v-tooltip>
                      </template>                              

                      <br><br>
            
          <v-img
            :src="row.productfilename"
            :lazy-src="row.productfilename"
            :alt="`${row.productname} avatar`"
            aspect-ratio="1"
            class="grey lighten-2"
            max-height="95"
            max-width="95"
          ></v-img>            
        </td> 
        <td>
            <a :href="row.url" target="_blank">{{row.productname}}</a> <br>                  
            <small><b>Marca:</b> {{row.brandname}}</small> | <small><b>Modelo:</b> {{row.productskufab}}</small> <br>                                  
            <small><b>SKU:</b> {{row.productsku}}</small> | <small><b>SKU_FAB:</b> {{row.productskufab}}</small><br>            
            <small><b>Clave SAT:</b> {{row.clave_sat}}</small> | <small><b>VENTA ID:</b> {{row.invoice_id}}</small> <br>                                            
            <small><b>ID:</b> {{row.id}}</small> | <small> <b>Producto ID:</b> {{row.productid}}</small> <br>
            Regular: <span style="font-size:.1.2rem; color:red;">{{row.totalprice_siniva | currency }} <small>  s/iva</small></span><br>
            Mayorista: <span style="font-size:.1.2rem; color:red;">{{row.price_mayorista_siniva | currency }} <small>  s/iva</small></span><br>
            Minorista: <span style="font-size:.1.2rem; color:red;">{{row.price_minorista_siniva | currency }} <small>  s/iva</small></span><br>
            Público: <span style="font-size:.1.2rem; color:red;">{{row.price_publico_siniva | currency }} <small>  s/iva</small></span><br>
            Fecha Creación: {{row.created_at}} <br> 
            Fecha Actualización: {{row.updated_at}} <br> <br>
            <div v-if="editRow === row.id">
            <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Cotización ID" v-model="row.quote_id" /> -->
            <v-autocomplete
              v-model="row.pricelist_id"
              :items="priceslist"
              item-text="description"
              item-value="id"
              label="Selecciona un producto*"
              outlined
            ></v-autocomplete>                                                
            <v-text-field
                        v-model="row.posicion"
                        v-on:keyup.13="updateRow(row)"
                        label="Posición"
            />  
            <v-text-field
                        v-model="row.price"
                        v-on:keyup.13="updateRow(row)"
                        label="Precio"
            />  
            <v-text-field
                        v-model="row.cantidad"
                        v-on:keyup.13="updateRow(row)"
                        label="Cantidad"
            /> 
            <v-text-field
                        v-model="row.discount"
                        v-on:keyup.13="updateRow(row)"
                        label="Descuento"
            /> 
            <v-row>
              <v-col cols=12>
                <v-textarea
                            v-model="row.notes"
                            filled
                            auto-grow
                            label="Escribe comentarios"
                            rows="4"
                            row-height="30"
                            shaped
                ></v-textarea>    
              </v-col>
            </v-row>        
            <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
          </div>
          <div v-else>  </div>  
        </td>
        <td>
          <b>Precio de Lista </b>  <br> {{row.price | currency }} <br>  
          <b>Descuento </b> <br> {{(row.price-(row.price*(row.discount/100))) | currency }}  <small>({{row.discount}}%)</small>
        </td>        
        <td>
          {{row.cantidad}}
        </td>
        <td>
          {{ calculateLineTotal(row) | currency }}        
        </td>           
        <td>
          <div v-if="row.notes != null && row.notes != ''">   
          <div class="text-center">
              <v-dialog
                v-model="dialog1"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red lighten-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    ver nota
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline grey lighten-2">
                    Notas
                  </v-card-title>
                  <v-card-text>
                    {{row.notes}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="dialog1 = false"
                    >
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            </div>
        </td>
        <td></td>        
        <td></td>      
      </tr>
    </table>     
    
    <v-row>
      <v-col cols=12 sm=12 md=12 lg=12>   
        <table>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Subtotal:</b> {{ invoice_subtotal | currency }}</td> 
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Desc:</b> {{ desc | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Subtotal c/Desc:</b> {{ invoice_subtotal2 | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>IVA:</b> {{ iva | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Total:</b> {{ invoice_total | currency }}</td> 
          </tr>
        </table>
      </v-col>
    </v-row>      
  </v-col>
  <!-- Lado Derecho -->
  <v-col cols="12" xs="12" sm="12" md="4" lg="2" xl="2">    
    <h5 >Ordenar Lista {{ draggingInfo }}</h5>
    <div class="form-check">
          <input
            id="disabled"
            type="checkbox"
            v-model="enabled"
            class="form-check-input"
          />
          <label class="form-check-label" for="disabled">Habilitar</label>
    </div>
    <br><br>
      <v-card
        class="mx-auto"
        max-width="100%"
      >
        <v-list>
          <v-list-item-group       
            mandatory     
            color="indigo"
          >
              <draggable
                :list="rows"
                :disabled="!enabled"
                class="list-group"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging=false"
                @end="order2()"
              >
                <v-list-item
                  v-for="(row,index) in rows"
                  :key="row"                  
                  v-slot="{ active, toggle }"
                >
                  <v-list-item-avatar>
                    <v-img
                        :src="row.productfilename"
                        :lazy-src="row.productfilename"
                        :alt="`${row.productname} avatar`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        max-height="95"
                        max-width="95"
                    ></v-img>                    
                  </v-list-item-avatar>
                  <v-list-item-content
                    :color="active ? 'primary' : ''"
                    @click="toggle"
                  >                    
                    <v-list-item-title>
                      <a :href="row.url" target="_blank">{{row.productname}}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <small><b>Marca:</b> {{row.brandname}}</small> | <small><b>Modelo:</b> {{row.productskufab}}</small> <br>                      
                      <small><b>SKU:</b> {{row.productsku}}</small><br>
                      <small><b>ID:</b> {{row.id}}</small> | <small> <b>Producto ID:</b> {{row.productid}}</small><br>
                      Index: {{index+1}} -  Posicion {{row.posicion}}
                    </v-list-item-subtitle>                    
                  </v-list-item-content>                   
                </v-list-item>
              </draggable>
          </v-list-item-group>
        </v-list>     
      </v-card>  
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols=12 sm=12 md=12 lg=12 >
    <v-alert
            dense
            outlined
            type="error"
          >
            No hay registros encontrados con esta busqueda
      </v-alert>
    </v-col>
  </v-row>
            
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->
  </div>
</template>

<script>
  // import axios from "axios";
  import draggable from "vuedraggable";
  import{ mapMutations,mapState } from "vuex";
  //import FieldSearch from '@/components/FieldSearch.vue'
  import jsPDF from 'jspdf' 
  import domtoimage from "dom-to-image";
  //import DetailQuoteAdd from "@/components/DetailQuoteAdd.vue";
  import baseApi from '@/services/baseApi';
  import ProductForm from "@/components/ProductForm";
  import CustomerEdit from "@/components/CustomerEdit";
  
  export default {
    name: 'detailsinvoice',
    computed:{
      ...mapState(['currentUser','priceslist']),
      draggingInfo() {
        return this.dragging ? "...arrastrando" : "";
      } 
    },  
    components: {
      //DetailQuoteAdd,
      //FieldSearch,
      draggable,
      ProductForm,
      CustomerEdit,
    },
    methods:{
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
      checkMove: function(e) {      
        window.console.log("Nombre: " + e.draggedContext.element.productname);
        window.console.log("Future index: " + e.draggedContext.futureIndex);
        window.console.log("Origen index: " + e.draggedContext.index);
        window.console.log("destino index: " + e.relatedContext.index);  
        this.index=e.draggedContext.index;
        this.order=e.relatedContext.index;      
        window.console.log("Index: " + this.index);
        window.console.log("Orden: " + this.order);      
      },
      orderByAsc(){
        let i=0;      
        let size=this.rows.length;      
        for (i; i < size; i++) { 
              this.rows[i].posicion = i+1;                       
              this.updateRow(this.rows[i]); //Funciona pero pasa todos los valores
              window.console.log("Posicion: " + this.rows[i].posicion);
        }         
        
      },
      order2(){
        this.dragging=false;
        let i=0;
        let id=this.index;      
        let order=this.order;
        let size=this.rows.length;

        // alert("Por fin lo solto");
        // console.log("ID: "+id)
        // console.log("Size: "+this.list.length)            

        if (order>id){        
            for (i = id; i < size; i++) { 
              this.rows[i].posicion = i+1;                       
            }          
        }else{
          for (i = order; i < size; i+=1) { 
            this.rows[i].posicion = i+1;                       
          }
        }
        
      },    
      calculateLineTotal(row) {            
              var stotal = parseFloat(row.price) * parseFloat(row.cantidad);
              var total = parseFloat(stotal-(stotal*(row.discount/100)));                        
              
              if (!isNaN(total)) {
                  //row.line_total = total.toFixed(2);
                  //total.toFixed(2);                
                  //return total.toFixed(2);
                  return total;
                  
              }
              //return  total;
              //this.calculateTotal(total);
      },    
      async getBreadcrumps(){      
        //let aFecha=dia.split(["-"])
        //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];
  
        try{            
          this.showLoading({titulo:"Accediendo información",color:"primary"})
          //let branch_id=this.currentUser.branch_id;
          let datos= await baseApi().get(`/detallefactura/`+this.$route.query.invoiceid);
          //console.log(datos);
          
          if(datos.data.length >0){
            this.rows=datos.data;  
            for (var i = 0; i < this.rows.length; i+=1) {   
              this.folio=this.rows[0].invoice_id;  
              this.customerfullname=this.rows[0].customerfullname; 
              this.date_invoice=this.rows[0].date_pay;            
              this.invoicenotes=this.rows[0].invoicenotes;            
              //this.subtotal=(this.subtotal + (this.rows[i].price*this.rows[i].cantidad));  //Se multiplica con uno para que lo convierta en numero
              console.log(this.invoice_subtotal);
              
              let total_line=this.calculateLineTotal(this.rows[i]);
              //this.quote_subtotal=(this.quote_subtotal + (total_line-(total_line*(this.rows[i].discount/100))));  //Se multiplica con uno para que lo convierta en numero                        
              this.invoice_subtotal=this.invoice_subtotal + total_line;
  
              //console.log("PRICE: "+this.rows[i].price);   
              //console.log(this.rows[0].invoicediscount); 
              //console.log('Descuento'+this.rows[0].invoicediscount);      
              if (this.rows[0].invoicediscount!=0) this.desc=(this.invoice_subtotal*(this.rows[0].invoicediscount/100)); 
              else this.desc=0;
  
              this.invoice_subtotal2=this.invoice_subtotal-this.desc;

              if (this.rows[0].iva!=0) this.iva=(this.invoice_subtotal2*0.16); 
              else this.iva=0;
              this.invoice_total=this.invoice_subtotal2 + this.iva;  //Se multiplica con uno para que lo convierta en numero
              this.invoice_id=this.rows[0].invoice_id;
            }           
  
            
            //this.desserts=datos.data          
          }else{
            this.rows="Sin resultados";
          }
  
        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
        } 
  
        try{        
          this.showLoading({titulo:"Accediendo información",color:"primary"})                        
          await this.$store.dispatch('loadPricelists',"All");
        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
        }

        try{        
            this.showLoading({titulo:"Accediendo información del Cliente",color:"warning"})
            baseApi().get(`clientes/${this.rows[0].customer_id}`).then((response)=>{
              if(!response.data.error){
                this.user=response.data;                             
                //this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              //console.log("VENTA: "+response.data.message);
            });
          }catch(error){
            console.log(error);
          } finally{
            this.hiddenLoading()
          } 

      },
      async DetailCustomer(CustomerInfo){
        let title=CustomerInfo.title;
        alert ('estoy en customer: '+title);
      },
      async DetailProduct(ProductInfo){
        // alert("Agregar Product");
        // alert (ProductInfo.invoice_id);
        // alert (ProductInfo.pricelist_id);
        // alert (ProductInfo.cantidad);
        // alert (ProductInfo.price);
        try{ 
          this.showLoading({titulo:"Almacenando información",color:"warning"})
          // let invoice_id=ProductInfo.invoice_id;
          let invoice_id=this.$route.query.invoiceid; //this.rows[0].quote_id
          let pricelist_id=ProductInfo.pricelist_id;
          let price=ProductInfo.price;
          let cantidad=ProductInfo.cantidad;
          let posicion=ProductInfo.posicion;
          // const { invoice_id,pricelist_id,price,cantidad } = this;        
          //let datos=await baseApi().post();        
          baseApi().post(`/detallefactura`,JSON.stringify({ invoice_id,pricelist_id,posicion,price,cantidad })).then((response)=>{
              if(!response.data.error){                            
                // this.$swal("Genial!","", "success");
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
          });
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        } 
      },
      deleteRow(id) {      
        // alert(id);
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"});
            
            if(confirm("Desea borrar definitivamente el registro: "+ id+"?")){
              // alert("Estoy en OK")
              baseApi().get(`/detallefactura/delete/${id}`).then((response)=>{
                if(!response.data.error){                            
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
                console.log("VENTA: "+response.data.message);
              });
            }else{
              // alert("NO acepto :(")
            }          
          }catch(error){
            console.log(error);
          }  finally{
            this.hiddenLoading()
            // this.$swal("Genial!", "Se actualizó la información", "success");
          } 
      },
      downloadPDF(){
          //const doc = new jsPDF('p', 'mm', [297, 210]); 
          const doc = new jsPDF('l', 'mm', [297, 210]);           
          var folio="SO"+this.rows[0].invoice_id;
          doc.page = 1; // use this as a counter.
          var totalPages = 0; // define total amount of pages
          var leyenda="";
          // HEADER        
          doc.setFontSize(16);//optional
          // doc.setTextColor(40);//optional
          doc.setFont(undefined, "bold");
          doc.text("VENTA NORMAL ",30, 18);// set your margins
          doc.setFontSize(10);
          doc.setFont(undefined, "normal");
          doc.text(this.rows[0].title,30, 23);// set your margins
          // FIN FOOTER
  
          //var imgData = 'data:image/jpeg;base64,verylongbase64;'
          //var imgData = 'https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png';
          //var imgData ='https://pbs.twimg.com/profile_images/996822127700406272/hNY5DZw-_normal.jpg';
          // var imgData ='https://lh3.googleusercontent.com/ogw/ADGmqu_nde3VWYvJC7lGnEWQ1s7UkYMpyVLGkcERiiiY=s64-c-mo';
          var imgData =this.currentUser.branch_filename;
          doc.addImage(imgData, 'JPEG', 9, 10, 15, 15);
  
  
          //console.log(imgData);         
          //doc.text(15, 15, reportTitle);
          //doc.addImage(outputURL, 'JPEG', 15, 40, 180, 100);
  
          
          //var miArray = [ 2, 4, 6, 8, 10 ];
          //console.log(this.rows.length);
          //console.log(this.rows);
          //console.log(this.rows[1].id);        
  
          doc.setFontSize(9);
          doc.text("Folio: "+folio,227.7,15);
          doc.text("Fecha: "+this.rows[0].created_at,226,21);
          doc.text("Cliente: "+this.rows[0].customerfullname,225,27);
  
                  
          var yh=45;
          doc.setFontSize(8);
          //doc.setFontType("bold"); No funciona
          // this.setFont("helvetica", "bold");
          doc.setFont(undefined, "bold");
          doc.text("CANT.",7,yh);
          doc.text("DESCRIPCIÓN",17,yh);        
          doc.text("PRECIO DE\rLISTA",205,yh,'center');
          doc.text("DESC.",225,yh,'center')        
          doc.text("PRECIO\rUNITARIO",240,yh,'center')        
          doc.text("IMPORTE",255,yh);
          doc.setFont(undefined, "normal");                  
  
          
          var y=55;   
          var total=0;    
          var stotal=0;   
          var desc=0;   
          var stotal2=0;   
          var iva=0;            
          var gtotal=0;
          var priceuni=0;
          var str = "";
          doc.setFontSize(7);
  
          for (var i = 0; i < this.rows.length; i+=1) {     
            priceuni=this.rows[i].price-(this.rows[i].price*(this.rows[i].discount/100));
            if (y>=191)    {
              yh=34;
              y=45;
              totalPages+=1;            
              // FOOTER     
              str = "Página " + doc.page  + " de " +  totalPages;               
              doc.setFontSize(7);// optional
              doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
              // doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function              
              doc.text(this.currentUser.branch_site+"  |  "+this.currentUser.branch_mobile+"  |  "+this.currentUser.branch_email, 215, doc.internal.pageSize.height - 10);
  
              doc.addPage();  
              doc.setFontSize(8);
              //doc.setFontType("bold"); No funciona
              // this.setFont("helvetica", "bold");
              doc.setFont(undefined, "bold");
              doc.text("CANT.",7,yh);
              doc.text("DESCRIPCIÓN",17,yh);        
              doc.text("PRECIO DE\rLISTA",205,yh,'center');
              doc.text("DESC.",225,yh,'center')        
              doc.text("PRECIO\rUNITARIO",240,yh,'center')        
              doc.text("IMPORTE",255,yh);
              doc.setFont(undefined, "normal");
              doc.setFontSize(7);                            
            }
            total=0;    
            //total=this.rows[i].cantidad*this.rows[i].price;
            total=this.calculateLineTotal(this.rows[i]);          
            stotal+=total;
            //console.log("En el índice '" + i + "' hay este valor: " + this.rows[i].id);
            //console.log("total: " + total);
            
            doc.text(this.rows[i].cantidad.toString(),10,y,'center'); 
            if ( this.rows[i].url ==  null)
              doc.text(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(),17,y);
            else
              doc.textWithLink(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(), 17, y, {url: this.rows[i].url.toString()});                   
            doc.text("$"+this.rows[i].price.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,'),205,y,'center');          
            doc.text("-"+this.rows[i].discount.toString()+"%",225,y,'center');
            doc.text("$"+priceuni.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),240,y,'center');
            doc.text("$"+total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),255,y);
            //doc.text(String(total | currency).toFixed(2),245,y);
            y = y + 12;          
          }   
          
          if (this.rows[0].invoicediscount!=0) desc=stotal*(this.rows[0].invoicediscount/100);
          else desc=0;
  
          stotal2=stotal-desc;
          
  
          if (this.rows[0].iva!=0) iva=stotal2*0.16;
          else iva=0;
          
          gtotal=stotal2+iva;        
  
          y = y+6;  
          doc.setFontSize(8);      
          doc.text("SUBTOTAL:",250,y,'right'); 
          doc.text("$"+stotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right'); 
          y = y+6;   
          if (desc>=0){
            doc.text("DESCUENTO:",250,y,'right'); 
            doc.text("$"+desc.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right'); 
            y = y+6;        
            doc.text("SUBTOTAL c/DESC:",250,y,'right'); 
            doc.text("$"+stotal2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');             
            y = y+6;        
          }                                
          doc.text("IVA:",250,y,'right'); 
          doc.text("$"+iva.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');           
          y = y+6;        
          // doc.setFontSize(9);
          doc.text("TOTAL:",250,y,'right'); 
          doc.text("$"+gtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');
  
          //Nota
          if (this.rows[0].invoicenotes!=""){
            y = y+30;                
            doc.setFontSize(8);        
            doc.setTextColor(0,0,0);          
            doc.text("NOTA:",10,y);   
            y = y+5;                   
            doc.setTextColor(40);          
            doc.text(this.rows[0].invoicenotes,10,y);
          }        
  
  
  
          // FOOTER   
          totalPages+=1;             
          str = "Página " + doc.page  + " de " +  totalPages;        
          doc.setFontSize(7);// optional          
          // doc.setFont("helvetica");
          // doc.setFontType("bold");
          doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
          doc.setTextColor(0,85,136);
          doc.text(leyenda, 43, doc.internal.pageSize.height - 10);//key is the interal pageSize function
          doc.setTextColor(0,0,0);
          // doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
          doc.text(this.currentUser.branch_site+"  |  "+this.currentUser.branch_mobile+"  |  "+this.currentUser.branch_email, 215, doc.internal.pageSize.height - 10);
          
          //doc.text(this.name,15,15);
          doc.save(folio+".pdf")
          // doc.output('dataurlnewwindow', {filename: folio+".pdf"});
      },
      downloadWithCSS() {
        /** WITH CSS */
        domtoimage
        .toPng(this.$refs.content)
        .then(function(dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          const doc = new jsPDF({
            orientation: "portrait",
            // unit: "pt",
            format: [900, 1400]
          });
          doc.addImage(img, "JPEG", 20, 20);
          const date = new Date();
          const filename =
            "timechart_" +
            date.getFullYear() +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            ("0" + date.getDate()).slice(-2) +
            ("0" + date.getHours()).slice(-2) +
            ("0" + date.getMinutes()).slice(-2) +
            ("0" + date.getSeconds()).slice(-2) +
            ".pdf";
          doc.save(filename);
        })
        .catch(function(error) {
          console.error("oops, something went wrong!", error);
        });
      },  
      updateRow(row) {      
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})
            baseApi().post(`/detallefactura/update/${row.id}`,JSON.stringify(row)).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("VENTA: "+response.data.message);
            });
          }catch(error){
            console.log(error);
          }  finally{
            this.hiddenLoading()
            // this.$swal("Genial!", "Se actualizó la información", "success");
          } 
      },      
    },
    data(){
      return{
        enabled: false,
        dialog: false,        
        dialog1: false,        
        dialog2: false,        
        dragging: false,
        sword:"",
        j:1,
        folio:"",
        customerfullname:"",
        date_invoice:"",
        invoicenotes:"",
        subtotal:0,
        desc:0,
        sfecha:new Date().toISOString().substr(0,10),
        user: [],
        /*
        user: {
          firstname: "Alex",
          address: "info building",
          email: "alex@xyz.com",
          mobile: "1122334456",
        },*/
        rows: [],
        editRow:null,
        invoice_id:'',
        price:"",
        invoice_subtotal: 0,
        invoice_subtotal2:0,
        invoice_subtotal_desc:0,
        invoice_total: 0,
        invoice_tax: 16,      
        invoice_iva: 0,     
        iva:0,
        theader: [
          { text: 'Acción', value: 'num' },        
          { text: 'Descripción', value: 'desc' },
          { text: 'Precio de Lista', value: 'precio_other' },
          { text: 'Cantidad', value: 'cantidad' },                
          { text: 'Total', value: 'total' },        
        ],
        acciones: [
          { title: 'Download PDF',icon:"save",metodo:"downloadPDF" }
        ],      
      }
    },
    created(){
      this.changeBreadcrumbs({
          page:"Detalle Factura "+this.sword,
          title:"Lista de detalle factura",
          subtitle:"primary",
          description:"En esta lista encontrará todos los detalles de la factura dadas de alta en el sistema desde el principio de los tiempos"
      });
      this.getBreadcrumps()    
    }
  }
  </script>